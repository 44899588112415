<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Settings.png" alt="Setting" style="padding-right: 5px;">
  Create / Update Settings
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">SAVE</button>
</kendo-buttongroup>

<kendo-tabstrip>
  <kendo-tabstrip-tab title="Basic Settings" [selected]="true">
    <ng-template kendoTabContent>
      <form [formGroup]="settingForm">
        <div id="basicSettings">

          <div class="row">
            <div class="col form-group">
              <kendo-formfield>
                <kendo-label for="CompanyID" text="Current Company ID"></kendo-label>
                <kendo-textbox class="form-control" formControlName="CompanyID" id="CompanyID" [readonly]="true"></kendo-textbox>
              </kendo-formfield>
            </div>

            <div class="col form-group">
              <kendo-formfield>
                <kendo-label for="DatabaseVersionNumber" text="RPM Database Version Number"></kendo-label>
                <kendo-textbox class="form-control" formControlName="DatabaseVersionNumber" id="DatabaseVersionNumber" [readonly]="true"></kendo-textbox>
              </kendo-formfield>
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <label for="ERPName">ERP Name</label>
              <div>
                <ng-select id="ERPName" [multiple]="false" [selectOnTab]="true" formControlName="ERPName"
                           [notFoundText]="'Please start typing to search'" appendTo="body" placeholder="Select an ERP" [searchable]="true">
                  <ng-option *ngFor="let item of erpNames" [value]="item.id">
                    {{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col form-group">
              <label for="LoggingLevel">Diagnostic Logging Level</label>
              <div>
                <ng-select id="LoggingLevel" [multiple]="false" [selectOnTab]="true" formControlName="LoggingLevel"
                           [notFoundText]="'Please start typing to search'" appendTo="body" placeholder="Select a Logging Level" [searchable]="true">
                  <ng-option *ngFor="let item of LoggingLevels" [value]="item.id">
                    {{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <kendo-formfield>
                <kendo-label for="DefaultCurrency" class="font-weight-bold" text="* Default Currency" *ngIf="useSingleCurrency"></kendo-label>
                <kendo-label for="DefaultCurrency" text="Default Currency" *ngIf="!useSingleCurrency"></kendo-label>
                <span class="k-textbox" style="width: 100%;">
                  <ng-select id="DefaultCurrency" class="noarrow" [multiple]="false" [selectOnTab]="true"
                             formControlName="DefaultCurrency" [ngClass]="{
                             'is-invalid': submitted && f.DefaultCurrency.errors,
                      'w-100': true
                    }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                             (search)="search($event, filters.DefaultCurrency)" (change)="
                      onSelectBoxChanged(
                        $event,
                        currencies
                      )"
                             placeholder="Select a Currency">
                    <ng-option *ngFor="let item of currencies" [value]="item.id">
                      {{ item.name }}
                    </ng-option>
                  </ng-select>
                  <kendo-textbox-separator></kendo-textbox-separator>
                  <button kendoButton look="clear" (focus)="onFocus($event)" (blur)="onBlur($event)" (click)="onLookup_defaultCurrency()" icon="search"></button>
                </span>
                <kendo-formerror>Default Currency is required</kendo-formerror>
              </kendo-formfield>
            </div>

            <div class="col form-group d-flex align-items-center">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="UseSingleCurrency"
                       id="UseSingleCurrency" (change)="useSingleCurrencyChanged()" />
                <kendo-label class="form-check-kendo-label" for="UseSingleCurrency" text="Use Single Currency">
                </kendo-label>
              </div>
            </div>
          </div>
          <div class="row">
          </div>

          <div class="row">
            <div class="col form-group">
              <kendo-label text="For Price Schedules with the Same Rank:"></kendo-label>
            </div>
          </div>

          <div class="row">
            <div class="col-2 form-group d-flex align-items-center">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="UseHighestPrice"
                       id="UseHighestPrice" kendoCheckBox (change)="UseHighestPriceChanged()" />
                <kendo-label class="form-check-kendo-label" for="UseHighestPrice" text="Use Highest Price">
                </kendo-label>
              </div>
            </div>
            <div class="col-2 form-group d-flex align-items-center">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="UseLowestPrice"
                       id="UseLowestPrice" kendoCheckBox (change)="UseLowestPriceChanged()" />
                <kendo-label class="form-check-kendo-label" for="UseLowestPrice" text="Use Lowest Price"></kendo-label>
              </div>
            </div>
          </div>

        </div>
      </form>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title=" Users">
    <ng-template kendoTabContent>
      <app-user-list></app-user-list>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
