<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="900" [height]="850">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Price_Sheet.png" alt="Price Sheets" style="padding-right: 5px;">
      Create / Update Price Sheet Detail
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="priceSheetDetailForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="price" class="font-weight-bold" text="* Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="price" [autoCorrect]="true"
                                [decimals]="5" [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.price.errors }"
                                placeholder="Enter an Amount" [min]="-99999999999999"  [max]="99999999999999">
          </kendo-numerictextbox>
          <kendo-formerror>Amount is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group d-flex align-items-center">
        <input type="checkbox" id="useFilter" formControlName="useFilter" #useFilter (change)="useFilterChanged()" kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="useFilter" text="Use Pricing Filter"></kendo-label>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="usePricingFilter">
        <kendo-formfield>
          <kendo-label for="pricingFilterID" class="font-weight-bold" text="* Pricing Filter"></kendo-label>
          <span class="k-textbox" title="This is a tooltip"  style="width: 100%;">
            <ng-select id="pricingFilterID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="pricingFilterID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.pricingFilterID.errors,
                    'w-100': true
                  }" placeholder="Select a Pricing Filter" [searchable]="true" (search)="search($event, filters.PricingFilter)">
              <ng-option *ngFor="let pricingFilter of pricingFilters" [value]="pricingFilter.id">
                {{ pricingFilter.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_PricingFilter()" icon="search"></button>
            <div kendoTooltip filter=".k-textbox" style="padding-top: 10px;"></div>
            
          </span>
          <kendo-formerror>Pricing Filter is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="!usePricingFilter">
        <kendo-formfield>
          <kendo-label for="itemID" text="Item"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="itemID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="itemID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.itemID.errors,
                    'w-100': true
                  }" placeholder="Select an Item" [searchable]="true" (search)="search($event, filters.Item)"
                       (change)="onSelectBoxChanged($event, 'item', items)">
              <ng-option *ngFor="let item of items" [value]="item.id">
                {{ item?.number }} - {{ item?.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Item()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="!usePricingFilter">
        <kendo-formfield>
          <kendo-label for="uofMID" text="Unit of Measure"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="uofMID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="uofMID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.uofMID.errors,
                    'w-100': true
                  }" placeholder="Select a Unit of Measure" [searchable]="true" (search)="search($event, filters.UofM)">
              <ng-option *ngFor="let item of uofMs" [value]="item.id">
                {{ item.name }} | {{ item.uofMSchedule.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_UofM()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="!usePricingFilter">
        <kendo-formfield>
          <kendo-label for="customerID" text="Customer"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="customerID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.customerID.errors,
                    'w-100': true
                  }" placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.Customer)">
              <ng-option *ngFor="let item of customers" [value]="item.id">
                {{ item?.number }} - {{ item?.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Customer()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="!usePricingFilter">
        <kendo-formfield>
          <kendo-label for="locationID" text="Location"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="locationID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="locationID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.locationID.errors,
                    'w-100': true
                  }" placeholder="Select a Location" [searchable]="true" (search)="search($event, filters.Location)">
              <ng-option *ngFor="let item of locations" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Location()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="quantityRulesOptionID" class="font-weight-bold" text="* Quantity Rules Option">
          </kendo-label>
          <div class="d-flex input-group-prepend">
            <ng-select #quantityRulesOptionID id="quantityRulesOptionID" [multiple]="false" [selectOnTab]="true"
                       formControlName="quantityRulesOptionID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.quantityRulesOptionID.errors,
                    'w-100': true
                  }" placeholder="Please select a Quantity Rules Option" [searchable]="true" (change)="
                  onSelectBoxChanged($event, 'quantityRulesOption', quantityRulesOptions)">
              <ng-option *ngFor="let item of quantityRulesOptions" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
          </div>
          <kendo-formerror>Quantity Rules Option is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <div *ngIf="useQuantityBreaks" class="card p-3" style="background-color:rgb(234, 234, 234)">
    <app-quantity-break-list [priceSheetDetailID]='idOfPriceSheetDetail'></app-quantity-break-list>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
    <button kendoButton (click)="onClick_SaveClose()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save & Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
