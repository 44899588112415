import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  model = 'RPMCopy';
  constructor(private httpClient: HttpClient) {}
  async performCopy(data, entityType, entityID): Promise<HttpResponse<string>> {
     const headerValues = new HttpHeaders({ 'Content-Type': 'application/*+json' });     
     headerValues.append('Content-Type', 'application/*+json');
      
    return this.httpClient
      .post(`${this.model}?EntityType=${entityType}&EntityID=${entityID}`, data, { headers: headerValues, responseType: 'text', observe: 'response' })
      .toPromise() as Promise<HttpResponse<string>>;
  }  
}
