import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiUrlInterceptor } from './services/api-url/api-url.interceptor';
import {
  NgbModule,
  NgbActiveModal,
  NgbModal,
  NgbAccordion,
} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog'
import { NotificationService, NotificationModule } from "@progress/kendo-angular-notification";
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule  } from '@progress/kendo-angular-inputs';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { DatePipe } from '@angular/common';
import { LayoutModule, DrawerModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { AppInitService } from '../app/services/app-init/app-init.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MenuModule, MenusModule } from "@progress/kendo-angular-menu";

@NgModule({
  declarations: [AppComponent], 
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,   
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MenusModule,
    MatDialogModule,
    GridModule,
    DialogsModule,
    DateInputsModule,
    DatePickerModule,
    ButtonModule,
    ButtonsModule,
    InputsModule,
    LayoutModule,
    LabelModule,
    FloatingLabelModule,
    TabStripModule,
    DropDownsModule,
    DrawerModule,
    NotificationModule
  ],
  exports: [
    AppRoutingModule,
    MatDialogModule,
    GridModule,
    //TabStripModule,
    DateInputsModule,
    DatePickerModule,   
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInit: AppInitService) => () => appInit.loadConfiguration(),
      deps: [AppInitService],
      multi: true
    },
    NgbActiveModal,
    NgbModal,
    NgbModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    NgbAccordion,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    DatePipe    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
