<kendo-dialog title="Please confirm"
              (close)="close('cancel')"
              [minWidth]="250"
              [width]="450">
  <p style="margin: 30px; text-align: center;">
    Are you sure you want to delete the selected record(s)?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('no')" class="btn btn-secondary"><span class="k-icon k-i-cancel"></span>No</button>
    <button kendoButton (click)="close('yes')" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Yes</button>
  </kendo-dialog-actions>
</kendo-dialog>

