<kendo-dialog [minWidth]="450" (close)="onClick_Close('Cancel')" [width]="900">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Price_Calculation.png" alt="Pricing Condition" style="padding-right: 5px;">
      Pricing Conditions Lookup
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <kendo-grid #grid="kendoGrid"
              [data]="gridView"
              [pageSize]="query.PageSize"
              [skip]="skip"
              [reorderable]="true"
              [loading]="loading"
              [pageable]="true"
              (pageChange)="pageChange($event)"
              [filter]="filter"
              [filterable]="filterable"
              (filterChange)="gridFilterChange($event)"
              [resizable]="true"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              [selectable]="{ mode: 'multiple' }"
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              (cellClick)="onCellClick($event)"
              (dblclick)="onDblClick()"
              [class]="gridClassName">
    <ng-template kendoGridToolbarTemplate>
      <kendo-buttongroup>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      </kendo-buttongroup>
      <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
    </ng-template>
    <kendo-grid-column field="name" title="Name" width="250"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="conditionalPricingFilter" title="Condition Filter" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngIf="dataItem.pricingFilter">
          {{ dataItem.pricingFilter.name }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="conditionOperand.name" title="Condition Operand" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="conditionQuantity" format="##############" title="Condition Quantity" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-lt-operator></kendo-filter-lt-operator>
          <kendo-filter-lte-operator></kendo-filter-lte-operator>
          <kendo-filter-gt-operator></kendo-filter-gt-operator>
          <kendo-filter-gte-operator></kendo-filter-gte-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="conditionAmount" format="##############.00000" title="Condition Amount" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-lt-operator></kendo-filter-lt-operator>
          <kendo-filter-lte-operator></kendo-filter-lte-operator>
          <kendo-filter-gt-operator></kendo-filter-gt-operator>
          <kendo-filter-gte-operator></kendo-filter-gte-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="maximumAffectedQuantityInBase" format="##############" title="Max Affect Qty" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-lt-operator></kendo-filter-lt-operator>
          <kendo-filter-lte-operator></kendo-filter-lte-operator>
          <kendo-filter-gt-operator></kendo-filter-gt-operator>
          <kendo-filter-gte-operator></kendo-filter-gte-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="historyDateOption.name" title="History Date Option" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="historyDateValue" title="History Date Value" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="historyDate" title="History Date" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="convertToUofM" filter="boolean" title="Convert to UofM" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
      </kendo-grid-messages>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.convertToUofM ? 'Yes' : 'No'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="convertToUofMName" title="Convert UofM Name" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    </kendo-grid-column>

  </kendo-grid>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Select</button>
  </kendo-dialog-actions>
</kendo-dialog>
