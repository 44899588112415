export interface Currency {
    id: number;
    erpid: string;
    name: string;
    alphaCode: string;
    numericCode: number;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
  }

export const emptyCurrency: Currency = {
    id: null,
    erpid: '',
    name: '',
    alphaCode: '',
    numericCode: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
  };
