import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofM, emptyUofM } from '../../../services/uofms/uofms.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UofMSchedulesService } from 'src/app/services/uofm-schedules/uofm-schedules.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { v4 as uuidv4 } from 'uuid';

enum Filters {
  UofMSchedule = 1,
}
@Component({
  selector: 'app-uofm-single',
  templateUrl: './uofm-single.component.html',
  styleUrls: ['./uofm-single.component.scss'],
})
export class UofMSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfUofM: number | null = null;
  @Input() idOfUofMSchedule: number | null = null;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  uofM: UofM = emptyUofM;
  uofMForm: FormGroup;
  submitted = false;

  isFormChanged = false;
  modalReference;
  public isAdmin: boolean;

  constructor(
    private uofMsService: UofMsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfUofM) {
      this.loadData();
    }
    if (this.idOfUofMSchedule) {
      this.uofM.uofMScheduleID = this.idOfUofMSchedule;
    }
    this.buildForm();
    this.updateForm();
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.uofMForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfUofM) {
        this.uofMForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }

  buildForm() {
    this.uofMForm = new FormGroup({
      name: new FormControl('', Validators.required),
      uofMScheduleID: new FormControl({ value: '' }, Validators.required),
      quantityInBase: new FormControl('', Validators.required),
      erpid: new FormControl({ value: '', disabled: true }, [
        Validators.required]),
      isBase: new FormControl(false),
    });
    this.uofMForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.uofMForm.controls;
  }
  updateForm() {
    this.uofMForm.patchValue({ ...this.uofM });
    this.isFormChanged = false;
  }

  async checkForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.uofMForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingUofMs: UofM[] = await (
      await this.uofMsService.getList(params)
    ).body;
    if (existingUofMs.length > 0) {
      if (this.uofM.id) {
        for (const key in existingUofMs) {
          const currentUofM: UofM = existingUofMs[key];
          if (currentUofM.id != this.uofM.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }

  async checkIfUofMExists() {
    var uofMExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_UofMScheduleID = this.uofMForm.controls['uofMScheduleID'].value;
    const Operand_UofMScheduleID = 'eq';
    const Filter_Name = this.uofMForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_UofMScheduleID, Operand_UofMScheduleID, Filter_Name, Operand_Name, PageSize };
    const existingUofMs: UofM[] = await (await this.uofMsService.getList(params)).body;
    if (existingUofMs.length > 0) {
      if (this.uofM.id) {
        for (const key in existingUofMs) {
          const currentUofM: UofM = existingUofMs[key];
          if ((currentUofM.name == this.uofM.name) && (currentUofM.id != this.uofM.id))
          {
            uofMExists = true;
          }
        }
      } else {
        uofMExists = true;
      }
    }
    return uofMExists;
  }

  async checkIfAnotherBaseExists() {
    var baseExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_UofMScheduleID = this.uofMForm.controls['uofMScheduleID'].value;
    const Filter_IsBase = this.uofMForm.controls['isBase'].value;
    const params = { Filter_UofMScheduleID, Filter_IsBase, PageSize };
    const existingUofMs: UofM[] = await (
      await this.uofMsService.getList(params)
    ).body;
    if (existingUofMs.length > 0) {
      if (this.uofM.id) {
        for (const key in existingUofMs) {
          const currentUofM: UofM = existingUofMs[key];
          if (currentUofM.id != this.uofM.id) {
            baseExists = true;
          }
        }
      } else {
        baseExists = true;
      }
    }
    return baseExists;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.uofMForm.markAllAsTouched();
    this.submitted = true;
    if (this.uofMForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.uofM,
      ...this.uofMForm.getRawValue(), // uofMForm.value does not include disabled controls.
    };

    // Check to see if the selected UofMSchedule already has a Base.
    if (this.f.isBase.value == true) {
      if ((await this.checkIfAnotherBaseExists()) === true) {
        this.notificationHelper.showStatusOnDialog('The Unit of Measure Schedule selected already has a Base UofM!', 'error', this.container);
        return;
      }
    }

    // Check if this name exists for the selected UofMSchedule
    if ((await this.checkIfUofMExists()) === true) {
      this.notificationHelper.showStatusOnDialog('This Unit of Measure already exists for this UofM Schedule!', 'error', this.container);
      return;
    }

    // Check to see if ERPID already exists.
    if ((await this.checkForExistingERPID()) === true) {
      this.notificationHelper.showStatusOnDialog('ERP ID already exists!', 'error', this.container);
      return;
    }

    if (this.uofM.id) {
      try {
        const response: any = await this.uofMsService.update(
          this.uofM.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        console.log('UofM', this.uofMForm)
        const response: any = await this.uofMsService.create(
          this.uofMForm.getRawValue(), // uofMForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }

  async getUofMById(id) {
    if (this.idOfUofM) {
      return await this.uofMsService.getById(this.idOfUofM);
    } else {
      return emptyUofM;
    }
  }

  async loadData() {
    try {      
      [this.uofM] = await Promise.all([this.getUofMById(this.idOfUofM)]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }
}
