import { Injectable, EventEmitter, Output } from '@angular/core';
import { Authentication } from './authentication.model';
import { StorageService } from '../../utils/StorageHelper';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor() {}
  @Output() authenticationChangedEvent = new EventEmitter<string>();

  AuthenticationChanged(authentication: Authentication) {    
    if (authentication.activeUser){
      StorageService.setUserName(authentication.activeUser.userName);
      StorageService.setUserID(authentication.activeUser.id);
      StorageService.setUserFirstName(authentication.activeUser.firstName);
      StorageService.setUserLastName(authentication.activeUser.lastName);
      StorageService.setIsAdmin(authentication.activeUser.isAdmin);    
      StorageService.setIsDisabled(authentication.activeUser.isDisabled);    
      StorageService.setPageSize(authentication.activeUser.pageSize);
      StorageService.setERPName(authentication.activeERP);
    }
    else{
      StorageService.clearUserName();
      StorageService.clearUserID();
      StorageService.clearUserFirstName();
      StorageService.clearUserLastName();
      StorageService.clearIsAdmin();      
      StorageService.clearIsDisabled();
      StorageService.clearPageSize();
      StorageService.clearERPName();
    }

    if (authentication.activeCompany){
      StorageService.setCompanyID(authentication.activeCompany.id);
      StorageService.setCompanyName(authentication.activeCompany.name);
    }
    else{
      StorageService.clearCompanyID();
      StorageService.clearCompanyName();
    }    
    this.authenticationChangedEvent.emit();
  }
}
