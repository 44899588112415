<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Rounding_Rule.png" alt="RoundingRule" style="padding-right: 5px;">
      Create / Update Rounding Rules
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="roundingRuleForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Rounding Rule Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Rounding Rule Name"
                         maxlength="100" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="roundingDirectionOption" class="font-weight-bold" text="* Rounding Direction Option">
          </kendo-label>
          <ng-select id="roundingDirectionOption" [multiple]="false" [selectOnTab]="true"
                     formControlName="roundingDirectionOptionID" [notFoundText]="'Please start typing to search'" [ngClass]="{
            'is-invalid': submitted && f.roundingDirectionOptionID.errors,
            'w-100': true
          }" placeholder="Select a Rounding Direction Option" [searchable]="true" (change)="
          onSelectBoxChanged($event, 'roundingDirectionOption', roundingDirectionOptions)
        ">
            <ng-option *ngFor="let item of roundingDirectionOptions" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-formerror>Rounding Direction Option is required</kendo-formerror>
        </kendo-formfield>
      </div>
      </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="roundingToOptionID" class="font-weight-bold" text="* Rounding To Option"></kendo-label>
          <ng-select id="roundingToOption" [multiple]="false" [selectOnTab]="true" formControlName="roundingToOptionID"
                     [notFoundText]="'Please start typing to search'" [ngClass]="{
            'is-invalid': submitted && f.roundingToOptionID.errors,
            'w-100': true
          }" placeholder="Select a Rounding To Option" [searchable]="true" (change)="
          onSelectBoxChanged($event, 'roundingToOption', roundingToOptions)
        ">
            <ng-option *ngFor="let item of roundingToOptions" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-formerror>Rounding To Option is required</kendo-formerror>
        </kendo-formfield>
      </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label for="roundingAmount" class="font-weight-bold" text="* Rounding Amount"></kendo-label>
            <kendo-numerictextbox class="form-control" formControlName="roundingAmount" [min]="0" [autoCorrect]="true"
                                  [decimals]="5" [format]="'n5'" placeholder="Enter a Rounding Amount" [max]="99999999999999">
            </kendo-numerictextbox>
            <kendo-formerror>Rounding Amount is required</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
</form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
