import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../utils/StorageHelper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userID = StorageService.UserID();
    const activeCompanyID = StorageService.CompanyID();
    if (activeCompanyID) {
      environment.companyId = activeCompanyID;
    }
    if (userID) return true;
    this.router.navigate(['/auth/'], {
      queryParams: {
        next: state.url,
      },
    });
    return false;
  }
}
