// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: '',
  companyId: '',
  expireCacheBefore: ''
  //apiUrl: 'https://rpmdevapi.rocktonsoftware.com/api',
  //companyId: '5de4f281-2778-457e-a984-adbe5dc75503'
  //apiUrl: 'http://54.177.64.172/api',
  
};

// 5de4f281-2778-457e-a984-adbe5dc75503 - 54.67.112.167
// d1cb78a5-3a7f-403a-b717-3ff9f08ac8c4 - 54.153.97.140
// 61c6e08d-d70a-4ff4-a827-d129b676c327 - 54.177.64.172
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
