import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lookup } from './lookups.model';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  model = 'RPMLookupValues';
  constructor(private httpClient: HttpClient) {}
  getList(params = {}): Promise<Lookup[]> {
    //return this.httpClient.get(this.model, { params }).toPromise() as Promise< Lookup[] >;
    return this.httpClient.get(this.model, { params }).toPromise()
      .then((data: Lookup[]) => {
        return data.sort((a, b) => a.sortOrder - b.sortOrder);
      }) as Promise<Lookup[]>;
  }
  getById(id: number): Promise<Lookup> {
    return this.httpClient.get(`${this.model}/${id}`).toPromise() as Promise<
      Lookup
    >;
  }
}
