import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-export-confirmation',
  templateUrl: './export-confirmation.component.html',
  styleUrls: ['./export-confirmation.component.scss'],
})
export class ExportConfirmationComponent implements OnInit {
  @Input() gridRecordCount: number = 0;
  @Input() siblingRecordCount: number = 249000; //0 means siblings are not allowed
  @Input() hasDescColumns: boolean = false;

  @Output() confirmExport: EventEmitter<{
    optTechnicalStyle: boolean,
    optDescColumns: boolean,
    optRowID: boolean,
    optSiblingRecords: boolean
  }> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  selectedExportOption: string = 'technicalStyle'; // Default selection
  optDescColumns: boolean = true;
  optRowID: boolean = false;
  optSiblingRecords: boolean = false;
  constructor() {}

  ngOnInit(): void { }

  onExportOptionChange() {
    if (this.selectedExportOption === 'reportStyle') {
      this.optDescColumns = false;
      this.optRowID = false;
      this.optSiblingRecords = false;
    }
    if (this.selectedExportOption === 'technicalStyle') {
      this.optDescColumns = true;
    }
  }

  close(status: string): void {
    if (status === 'export') {
      this.confirmExport.emit({
        optTechnicalStyle: (this.selectedExportOption === 'technicalStyle'),
        optDescColumns: this.optDescColumns,
        optRowID: this.optRowID,
        optSiblingRecords: this.optSiblingRecords
      });
    } else {
      this.closeModal.emit();
    }
  }
}
