<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="800">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Historical_Sales.png" alt="Historical Sales" style="padding-right: 5px;">
      Create / Update Historical Sale
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="historicalSaleForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="docReference" class="font-weight-bold" text="* Document Reference"></kendo-label>
          <kendo-textbox class="form-control" formControlName="docReference" placeholder="Enter Document Reference" maxlength="100" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="docLineReference" class="font-weight-bold" text="* Document Line Reference"></kendo-label>
          <kendo-textbox class="form-control" formControlName="docLineReference" placeholder="Enter Document Line Reference" maxlength="50" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col-4 form-group">
        <kendo-formfield>
          <kendo-label for="dateSold" class="font-weight-bold" text="* Date Sold"></kendo-label>
          <kendo-datepicker id="dateSold" class="form-control" formControlName="dateSold"
                            [(value)]="dateSold" [ngClass]="{ 'is-invalid': submitted && f.dateSold.errors }" [min]="min">
          </kendo-datepicker>
          <kendo-formerror *ngIf="f.dateSold.errors && f.dateSold.errors.required">Date Sold is required</kendo-formerror>
          <kendo-formerror *ngIf="f.dateSold.errors && f.dateSold.errors.minError">Date Must be Greater than Jan 1st, 2000</kendo-formerror>
        </kendo-formfield>
      </div>     
      <div class="col-4 form-group">
        <kendo-formfield>
          <kendo-label for="customerID" class="font-weight-bold" text="* Customer"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="customerID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.customerID.errors,'w-100': true}"
                       placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.Customer)">
              <ng-option *ngFor="let item of customers" [value]="item?.id">{{ item?.number }} - {{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Customer()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>

      <div class="col-4 form-group">
        <kendo-formfield>
          <kendo-label for="itemID" class="font-weight-bold" text="* Item"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="itemID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="itemID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.itemID.errors,'w-100': true}"
                       placeholder="Select an Item" [searchable]="true" (search)="search($event, filters.Item)">
              <ng-option *ngFor="let item of items" [value]="item?.id">{{ item?.number }} - {{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Item()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="quantityInBase" class="font-weight-bold" text="* Quantity In Base"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="quantityInBase" [autoCorrect]="true"
                                [decimals]="5" [format]="'n5'" [min]="0" [max]="99999999999999"
                                [ngClass]="{ 'is-invalid': submitted && f.quantityInBase.errors }" placeholder="Enter a Quantity">
          </kendo-numerictextbox>
          <kendo-formerror>Quantity is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="extendedPriceAmount" class="font-weight-bold" text="* Extended Price Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="extendedPriceAmount" [autoCorrect]="true"
                                [decimals]="5" [format]="'n5'" [min]="-99999999999999" [max]="99999999999999"
                                [ngClass]="{ 'is-invalid': submitted && f.extendedPriceAmount.errors }" placeholder="Enter an Amount">
          </kendo-numerictextbox>
          <kendo-formerror>Amount is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="erpid" class="font-weight-bold" text="* ERP ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="erpid" placeholder="Enter ERP ID" maxlength="100"
                         [ngClass]="{ 'is-invalid': submitted && f.erpid.errors }" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>ERP ID is required</kendo-formerror>
        </kendo-formfield>
      </div>

    </div>
  </form>

  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
