export interface Location {
    id: number;
    erpid: string;
    name: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyLocation: Location = {
    id: null,
    erpid: null,
    name: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
