import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Item } from '../items/items.model';

@Injectable({
  providedIn: 'root',
})
export class ItemsInFilterService {
  model = 'RPMItemsInFilter';
  constructor(private httpClient: HttpClient) {}
  getList(params = {}): Promise<HttpResponse<Item[]>> {
    
    // @ts-ignore
    // observe: 'response'
    return this.httpClient
      .get(this.model, { params, observe: 'response' })
      .toPromise() as Promise<HttpResponse<Item[]>>;
  } 
}
