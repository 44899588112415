import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingConditionsService } from '../../../services/pricing-conditions/pricing-conditions.service';
import { PricingCondition } from '../../../services/pricing-conditions/pricing-conditions.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';

import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PriceFilter } from 'src/app/services/price-filter/price-filter.model';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-pricing-condition-list',
  templateUrl: './pricing-condition-list.component.html',
  styleUrls: ['./pricing-condition-list.component.scss'],
})
export class PricingConditionListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private pricingConditionsService: PricingConditionsService,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input()
  loading: boolean = true;
  @Input()
  pricingConditions: PricingCondition[] = [];
  @Input()
  query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input()
  pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;

  pagination_pricingConditions: any = { pricingConditions: mockedData };
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceFilter[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public pricingConditionConditionTypeOptions: Lookup[];
  public pricingConditionHistoryDateOptions: Lookup[];
  public pricingConditionEntity: Lookup[] = [];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [  
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Condition Type',
      field: 'conditionType.name',
      width: 250,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Condition Filter',
      field: 'pricingConditionFilter.name',
      width: 200,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Condition Operand',
      field: 'conditionOperand.name',
      width: 175,
      orderIndex: 3,
      hidden: false,
    },
    {
      title: 'Condition Quantity',
      field: 'conditionQuantity',
      width: 100,
      orderIndex: 4,
      hidden: false,
    },
    {
      title: 'Condition Amount',
      field: 'conditionAmount',
      width: 100,
      orderIndex: 5,
      hidden: false,
    },
    {
      title: 'Max Affect Qty',
      field: 'maximumAffectedQuantity',
      width: 100,
      orderIndex: 6,
      hidden: false,
    },
    {
      title: 'History Date Option',
      field: 'historyDateOption',
      width: 150,
      orderIndex: 7,
      hidden: true,
    },
    {
      title: 'History Date Value',
      field: 'historyDateValue',
      width: 150,
      orderIndex: 8,
      hidden: true,
    },
    {
      title: 'History Date',
      field: 'historyDate',
      width: 150,
      orderIndex: 9,
      hidden: true,
    },
    {
      title: 'Convert To UofM',
      field: 'convertToUofM',
      width: 150,
      orderIndex: 10,
      hidden: true,
    },
    {
      title: 'Convert To UofM Name',
      field: 'convertToUofMName',
      width: 150,
      orderIndex: 11,
      hidden: true,
    }
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.pricingConditionsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMPricingCondition", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully! Please refresh the grid to see results.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async getPricingConditionConditionTypeOptions() {
    const Filter_OptionSet = 'ConditionType';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.pricingConditionConditionTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "ConditionType";
    defaultLookup.name = "(All)";
    this.pricingConditionConditionTypeOptions.reverse();
    this.pricingConditionConditionTypeOptions.push(defaultLookup);
    this.pricingConditionConditionTypeOptions.reverse();
    return this.pricingConditionConditionTypeOptions;
  }

  async getPricingConditionHistoryDateOptions() {
    const Filter_OptionSet = 'HistoryDate';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.pricingConditionHistoryDateOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "HistoryDate";
    defaultLookup.name = "(All)";
    this.pricingConditionHistoryDateOptions.reverse();
    this.pricingConditionHistoryDateOptions.push(defaultLookup);
    this.pricingConditionHistoryDateOptions.reverse();
    return this.pricingConditionHistoryDateOptions;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PricingConditionList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PricingConditionList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PricingConditionList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PricingConditionList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();
    this.getPricingConditionConditionTypeOptions();    
    this.getPricingConditionHistoryDateOptions();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(
        inputXML,
        'RPMPricingCondition',
      );
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/pricing-condition/' + this.clickedRowItem.id]);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'convertToUofM') {
          const Filter_ConvertToUofM = myFilter.value;
          const Operand_ConvertToUofM = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConvertToUofM,
            Operand_ConvertToUofM,
          };
          this.query = params;
        }
        if (myFilter.field == 'perUofM.name') {
          const Filter_PerUofM = myFilter.value == null ? '' : myFilter.value;
          const Operand_PerUofM = myFilter.operator;
          const params = { ...this.query, Filter_PerUofM, Operand_PerUofM };
          this.query = params;
        }
        if (myFilter.field == 'historyDateOption.name') {
          if (myFilter.value != '(All)') {
            const Filter_HistoryDateOption = myFilter.value;
            const Operand_HistoryDateOption = myFilter.operator;
            const params = {
              ...this.query,
              Filter_HistoryDateOption,
              Operand_HistoryDateOption,
            };
            this.query = params;
          }
        }
        if (myFilter.field == 'historyDateValue') {
          const Filter_HistoryDateValue =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_HistoryDateValue = myFilter.operator;
          const params = {
            ...this.query,
            Filter_HistoryDateValue,
            Operand_HistoryDateValue,
          };
          this.query = params;
        }
        if (myFilter.field == 'historyDate') {
          const Filter_HistoryDate = this.datePipe.transform(myFilter.value, "MM/dd/yyyy");
          const Operand_HistoryDate = myFilter.operator;
          const params = {
            ...this.query,
            Filter_HistoryDate,
            Operand_HistoryDate
          };
          this.query = params;
        }
        if (myFilter.field == 'conditionalPricingFilter.name') {
          const Filter_ConditionalPricingFilter =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ConditionalPricingFilter = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConditionalPricingFilter,
            Operand_ConditionalPricingFilter,
          };
          this.query = params;
        }
        if (myFilter.field == 'conditionQuantity') {
          const Filter_ConditionQuantity =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ConditionQuantity = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConditionQuantity,
            Operand_ConditionQuantity,
          };
          this.query = params;
        }
        if (myFilter.field == 'conditionAmount') {
          const Filter_ConditionAmount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ConditionAmount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConditionAmount,
            Operand_ConditionAmount,
          };
          this.query = params;
        }
        if (myFilter.field == 'maximumAffectedQuantity') {
          const Filter_MaximumAffectedQuantity =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_MaximumAffectedQuantity = myFilter.operator;
          const params = {
            ...this.query,
            Filter_MaximumAffectedQuantity,
            Operand_MaximumAffectedQuantity,
          };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.pricingConditionsService.getExportList(this.query);
  };
  edit(id: number) {
    this.router.navigate([`/price/pricing-condition/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.pricingConditionsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.pricingConditionsService.getList(this.query);
      this.pricingConditions = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.pricingConditions,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
