<div class="sort-arrows">
  <div
    class="row m-0 p-0 justify-content-between align-items-center flex-nowrap"
  >
    <div class="col m-0 p-0">{{ title }}</div>
    <div class="m-0 p-0">
      <div class="arrows">
        <div
          (click)="setOrdering(desc)"
          [className]="ordering === desc ? 'sort-arrow active' : 'sort-arrow'"
        >
          <i class="fas fa-caret-up"></i>
        </div>
        <div
          (click)="setOrdering(asc)"
          [className]="ordering === asc ? 'sort-arrow active' : 'sort-arrow'"
        >
          <i class="fas fa-caret-down"></i>
        </div>
      </div>
    </div>
  </div>
</div>
