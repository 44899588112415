<kendo-dialog title="Create / Update Quantity Break"
              [minWidth]="250"
              (close)="onClose('Cancel')"
              [width]="450">

  <div style="margin-top:15px;" #container id="container"></div>

  <!--<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalCenteredkendo-label">Create / Update Quantity Break</h3>
        <button type="button" (click)="onClose('Cancel')" class="close" data-dismiss="modal" aria-kendo-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">-->
  <form [formGroup]="quantityBreakForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="quantityFromCount" class="font-weight-bold" text="* Quantity From"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="quantityFromCount" [min]="1" [decimals]="0" [format]="'n'"
                                [autoCorrect]="true" placeholder="Enter Quantity From" [max]="99999999999999">
          </kendo-numerictextbox>
          <kendo-formerror>
            Quantity From is required
          </kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="priceAmount" class="font-weight-bold" text="* Price Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="priceAmount" placeholder="Enter Price"
                                [decimals]="5" [format]="'n5'" [autoCorrect]="true" [min]="-99999999999999" [max]="99999999999999">
          </kendo-numerictextbox>

          <kendo-formerror>Price Amount is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClose('Cancel')">Cancel</button>
    <button kendoButton (click)="submit()" primary="true" class="btn btn-primary">Save</button>
  </kendo-dialog-actions>
  <!--</div>
  <div class="modal-footer">
    <button type="button" (click)="onClose('Cancel')" class="btn btn-secondary">Cancel</button>
    <button type="button" (click)="submit()" class="btn btn-primary">Save</button>
  </div>
  </div>
  </div>-->
