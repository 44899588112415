export interface RoundingRule {
    id: number;
    name: string;
    roundingDirectionOptionID: number;
    roundingDirectionOption: {
        name: string,
        id: number
    };
    roundingToOptionID: number;
    roundingToOption: {
        name: string,
        id: number
    };
    roundingAmount: number;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyRoundingRule: RoundingRule = {
    id: null,
    name: '',
    roundingDirectionOptionID: null,
    roundingDirectionOption: {
        name: '',
        id: null
    },
    roundingToOptionID: null,
    roundingToOption: {
        name: '',
        id: null
    },
    roundingAmount: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
