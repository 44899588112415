import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { Currency, emptyCurrency } from '../../../services/currencies/currencies.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-currency-single',
  templateUrl: './currency-single.component.html',
  styleUrls: ['./currency-single.component.scss'],
})
export class CurrencySingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfCurrency: number | null = null;
  @Input() currency: Currency = emptyCurrency;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  currencyForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;

  constructor(
    private currenciesService: CurrenciesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfCurrency) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.currencyForm.disable();
    }
    else {
      // if new record, default erpid field to new GUID
      if (!this.idOfCurrency) {
        this.currencyForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  buildForm() {
    let numericRegex = /^[0-9]\d*$/;
    this.currencyForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      alphaCode: new FormControl('', [Validators.maxLength(3)]),
      numericCode: new FormControl('', [
        Validators.pattern(numericRegex),
        Validators.max(999),
      ]),
      erpid: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
    this.currencyForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  get f() {
    return this.currencyForm.controls;
  }

  updateForm() {
    this.currencyForm.patchValue({ ...this.currency });
    this.isFormChanged = false;
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.currencyForm.markAllAsTouched();
    this.submitted = true;
    if (this.currencyForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.currency,
      ...this.currencyForm.getRawValue(),  // currencyForm.value does not include disabled controls.
    };

    // Check to see if Alpha Code already exists.
    if ((await this.validation_CheckForExistingAlphaCode()) === true) {
      this.notificationHelper.showStatusOnDialog('Alpha Code already exists!', 'error', this.container);
      return;
    }

    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.notificationHelper.showStatusOnDialog('ERP ID already exists!', 'error', this.container);
      return;
    }

    if (this.currency.id) {      
      try {
        const response: any = await this.currenciesService.update(this.currency.id, data);
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.currenciesService.create(this.currencyForm.getRawValue()); // currencyForm.value does not include disabled controls.
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        if (e.message.length > 30) {
          alert(e.error); // TODO: this is the best I can come up with to display long error messages. GLP 2023-06-12
        } else {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
        return;
      }
    }
    this.onFormSaved.emit(false);
  }

  async validation_CheckForExistingERPID() {
    var erpIDExists: boolean = false;
    const Filter_ERP = this.currencyForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingCurrencies: Currency[] = await (
      await this.currenciesService.getList(params)
    ).body;
    if (existingCurrencies.length > 0) {
      if (this.currency.id) {
        for (const key in existingCurrencies) {
          const currentCurrency: Currency = existingCurrencies[key];
          if (currentCurrency.id != this.currency.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }

  async validation_CheckForExistingAlphaCode() {
    var alphaCodeExists: boolean = false;
    const Filter_AlphaCode = this.currencyForm.controls['alphaCode'].value;
    const Operand_AlphaCode = 'eq';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_AlphaCode, Operand_AlphaCode, PageSize };
    if (Filter_AlphaCode != '') {
      const existingCurrencies: Currency[] = await (
        await this.currenciesService.getList(params)
      ).body;
      if (existingCurrencies.length > 0) {
        if (this.currency.id) {
          for (const key in existingCurrencies) {
            const currentCurrency: Currency = existingCurrencies[key];
            if (currentCurrency.id != this.currency.id) {
              alphaCodeExists = true;
            }
          }
        } else {
          alphaCodeExists = true;
        }
      }
    }
    return alphaCodeExists;
  }

  async getCurrencyById() {
    if (this.idOfCurrency) {
      return await this.currenciesService.getById(this.idOfCurrency);
    } else {
      return emptyCurrency;
    }
  }

  async loadData() {
    try {
      this.currency = await this.getCurrencyById();
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

}
