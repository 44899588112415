<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/UofM.png" alt="Unit of Measure" style="padding-right: 5px;">
      Create / Update Unit of Measure
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <form [formGroup]="uofMForm">
    <input hidden="true" class="form-control" formControlName="uofMScheduleID"
           [ngClass]="{ 'is-invalid': submitted && f.uofMScheduleID.errors }" />
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Unit of Measure Name" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col-8 form-group">
        <kendo-formfield>
          <kendo-label for="quantityInBase" class="font-weight-bold" text="* Quantity in Base"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="quantityInBase"
                                placeholder="Enter a Quantity in Base" [min]="0" [max]="99999999999999" [autoCorrect]="true" [decimals]="5" [format]="'n5'">
          </kendo-numerictextbox>
          <kendo-formerror>Quantity in Base is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <div class="form-check">
          <kendo-formfield>
            <input formControlName="isBase" type="checkbox" class="form-check-input" id="isBase" />
            <kendo-label class="form-check-kendo-label" for="isBase" text="Is Base"></kendo-label>
          </kendo-formfield>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="erpid" text="ERP ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="erpid" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>ERP ID is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>

