<ng-template #expectedToReturned let-modal>
    <div class="modal-header">Alert</div>
    <div class="modal-body">
      <p>This will set the Expected Unit Price to the Last Returned Unit Price, and the Expected Price Schedule to the Last Returned Price Schedule. Do you wish to Continue?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="setExpectedToReturnedPrice(modal)">
            Yes
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
            No
        </button>
    </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
    <img src="../../../../assets/images/Get_Price.png" alt="Pricing Scenarios" style="padding-right: 5px;">
    Pricing Test Scenarios
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip"
            [sort]="query.SortTerm" [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)"
            [filter]="filter" [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true"
            [sort]="sort" (sortChange)="sortChange($event)" kendoGridSelectBy="id" [selectable]="{ enabled: true, mode: 'multiple' }"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()" [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate *ngIf="mode == 'view'">
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Add.png" [routerLink]="['/price/pricing-scenario/new']"
              title="Add New">
        Add New
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()"
              title="Edit Selected">
        Edit
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
              (click)="onClick_Delete()" title="Delete Selected">
        Delete
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Export.png"
              (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">
        Export
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()"
              title="Import from Excel">
        Import
      </button>
      <button kendoButton type="splitbutton" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Execute_Multiple.png" (click)="execute()">
        Execute
      </button>
      <button kendoButton type="splitbutton" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Expected_Returned.png" (click)="showRemovePopUp(expectedToReturned)">
        SET EXPECTED TO RETURNED
      </button>
    </kendo-buttongroup>

    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
    <kendo-menu [items]="actionMenuItems" (select)="actionsMenuItemSelect($event)"> </kendo-menu>
  </ng-template>
  <kendo-grid-checkbox-column [showSelectAll]="true" width="40">Select</kendo-grid-checkbox-column>
  <kendo-grid-column field="name" title="Name" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'" [routerLink]="['/price/pricing-scenario/', dataItem.id]">
        {{ dataItem.name }}
      </a>
      <span role="button" *ngIf="mode == 'lookup'">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="customer.name" title="Customer Name" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.customer">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/customer/', dataItem.customer.id]">
          {{ dataItem.customer.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'">
          {{ dataItem.customer.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="customer.number" title="Customer Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.customer">
        {{ dataItem.customer.number }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.name" title="Item Name" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.item">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/item/', dataItem.item.id]">
          {{ dataItem.item.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'">
          {{ dataItem.item.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.number" title="Item Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.item">
        {{ dataItem.item.number }}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="quantity" title="Quantity" width="150" format="##############.00000" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="effectiveDate" title="Effective Date" width="175" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.effectiveDate | date: 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="useToday" title="Use Today" filter="boolean" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.useToday ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceSchedule.name" title="Price Schedule" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.priceSchedule">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/priceschedule/', dataItem.priceSchedule.id]">
          {{ dataItem.priceSchedule.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'">
          {{ dataItem.priceSchedule.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="lastReturnedUnitPrice" title="Last Returned Unit Price" format="##############.00000" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div>
        <span class="text-danger" *ngIf="dataItem.mismatch == 'Price' || dataItem.mismatch == 'Both'">
          {{ dataItem.lastReturnedUnitPrice }}
        </span>
        <span class="text-success" *ngIf="dataItem.mismatch != 'Price' && dataItem.mismatch != 'Both'">
          {{ dataItem.lastReturnedUnitPrice }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="lastReturnedPriceSchedule.name" title="Last Returned Price Schedule" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div>
        <span class="text-danger" *ngIf="dataItem.mismatch == 'Schedule' || dataItem.mismatch == 'Both'">
          {{ dataItem.lastReturnedPriceSchedule?.name }}
        </span>
        <span class="text-success" *ngIf="dataItem.mismatch != 'Schedule' && dataItem.mismatch != 'Both'">
          {{ dataItem.lastReturnedPriceSchedule?.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="expectedUnitPriceAmount" title="Expected Unit Price Amount" format="##############.00000" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div>
        <span class="text-danger" *ngIf="dataItem.mismatch == 'Price' || dataItem.mismatch == 'Both'">
          {{ dataItem.expectedUnitPriceAmount }}
        </span>
        <span class="text-success" *ngIf="dataItem.mismatch != 'Price' && dataItem.mismatch != 'Both'">
          {{ dataItem.expectedUnitPriceAmount }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="expectedPriceSchedule.name" title="Expected Price Schedule" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div>
        <span class="text-danger" *ngIf="dataItem.mismatch == 'Schedule' || dataItem.mismatch == 'Both'">
          {{ dataItem.expectedPriceSchedule?.name }}
        </span>
        <span class="text-success" *ngIf="dataItem.mismatch != 'Schedule' && dataItem.mismatch != 'Both'">
          {{ dataItem.expectedPriceSchedule?.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="mismatch" title="Mismatch" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="text-danger">{{ dataItem.mismatch }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="processStatus" title="Status" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div>
        <span class="text-danger" *ngIf="dataItem.processStatus == 0">
          Unable to find Price
        </span>
        <span class="text-success" *ngIf="dataItem.processStatus == 1">
          Price Found Successfully
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="lastExecutionDateTime" title="Last Execution Date Time" width="175" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.lastExecutionDateTime | date: 'MMM d, y, h:mm:ss a' }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="lastExecutionDiagnosticID" title="Last Execution Diagnostic ID" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="currency.name" title="Currency" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.currency">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/currency/', dataItem.currency.id]">
          {{ dataItem.currency.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'">
          {{ dataItem.currency.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="uofM.name" title="U of M" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.uofM">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/uofm/', dataItem.uofM.id]">
          {{ dataItem.uofM.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'">
          {{ dataItem.uofM.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="location.name" title="Location" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.location">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/location/', dataItem.location.id]">
          {{ dataItem.location.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'">
          {{ dataItem.location.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="valueFromERP" title="Value From ERP" format="##############.00000" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="multiLineTag" title="MultiLine Tag" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="scenarioGroup" title="Scenario Group" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="documentAttributes" title="Document Attributes" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="PricingScenarios.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceSchedule.name" title="PriceScheduleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="effectiveDate" title="EffectiveDate" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="useToday" title="UseToday" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="quantity" title="Quantity" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="valueFromERP" title="ValueFromERP" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="item.name" title="ItemName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="item.number" title="ItemNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="uofM.name" title="UofMName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="currency.name" title="CurrencyName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="currency.alphaCode" title="CurrencyAlphaCode" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.name" title="CustomerName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.number" title="CustomerNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="location.name" title="LocationName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastReturnedUnitPrice" title="LastReturnedUnitPrice" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastReturnedPriceSchedule.id" title="LastReturnedPriceScheduleID" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastReturnedPriceSchedule.name" title="LastReturnedPriceScheduleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="mismatch" title="Mismatch" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="multiLineTag" title="MultiLineTag" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="scenarioGroup" title="ScenarioGroup" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="processStatus" title="ProcessStatus" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="expectedUnitPriceAmount" title="ExpectedUnitPriceAmount" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="expectedPriceSchedule.name" title="ExpectedPriceScheduleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastExecutionDateTime" title="LastExecutionDateTime" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastExecutionDiagnosticID" title="LastExecutionDiagnosticID" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceAdjustmentsXML" title="PriceAdjustmentsXML" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="documentAttributes" title="DocumentAttributes" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
