import { Company } from "../companies/companies.model";
import { User } from "../users/users.model";

export interface Authentication {
  activeUser: User;
  //assignedCompanies: Company[];
  activeCompany: Company;
  activeERP: string;
}

export const emptyAuthentication: Authentication = {
  activeUser: null,
  //assignedCompanies: null,
  activeCompany: null,
  activeERP: "None"
};
