export interface PriceSchedule {
  id: number;
  erpid: string;
  name: string;
  calculateAfterPriceScheduleID: number;
  calculateAfterPriceSchedule: {
    name: string;
    id: number;
    erpid: string;
  };
  useInDynamicPricing: boolean;
  dynamicPricingRank: number;
  isActive: boolean;
  priceSourceOptionID: number;
  priceSourceOption: {
    name: string;
    id: number;
  };
  priceBookID: number;
  priceBook: {
    name: string;
    id: number;
  };
  roundingRuleID: number;
  roundingRule: {
    name: string;
    id: number;
  };
  pricingFilterID: number;
  pricingFilter: {
    name: string;
    id: number;
  };
  isHighestPrice: boolean;
  isLowestPrice: boolean;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
}

export const emptyPriceSchedule: PriceSchedule = {
  id: null,
  erpid: null,
  name: '',
  calculateAfterPriceScheduleID: null,
  calculateAfterPriceSchedule: {
    name: '',
    id: null,
    erpid: '',
  },
  useInDynamicPricing: false,
  dynamicPricingRank: null,
  isActive: false,
  priceSourceOptionID: null,
  priceSourceOption: {
    name: '',
    id: null,
  },
  priceBookID: null,
  priceBook: {
    name: '',
    id: null,
  },
  roundingRuleID: null,
  roundingRule: {
    name: '',
    id: null,
  },
  pricingFilterID: null,
  pricingFilter: {
    name: '',
    id: null,
  },
  isHighestPrice: false,
  isLowestPrice: false,
};
