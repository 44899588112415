<div class="d-flex bg-dark rounded-lg align-items-center">
<img src="../../../assets/images/RPM_Banner.jpg" style="width: 100%; height: 100%;">
</div>
<div style="padding-left: 25px;">
  <iframe src="https://www.rocktonsoftware.com/rpm-whats-new/" style="width: 100%; height: 550px; border-style:none;"></iframe>
  <!--<b>New Features!</b><br><br> 
    <li style="list-style-type: square;">Export to Excel</li>
    <li style="list-style-type: square;">Import from Excel</li>
    <li style="list-style-type: square;">Reorder columns</li>
    <li style="list-style-type: square;">Show/Hide Columns</li>
    <li style="list-style-type: square;">User Management - Ability to Create\Edit Users and assign them to companies</li>
  <br/>
  <b>Enhanced from Desktop!</b><br><br>
  <li style="list-style-type: square;">Advanced filtering - contains only</li>
  <li style="list-style-type: square;">Configurable Page Sizing</li>
  <li style="list-style-type: square;">Multi-Company - Ability to have multiple companies and easily switch between them</li>
  <br>-->
</div>
