export interface Lookup {
  id: number;
  optionSet: string;
  name: string;
  sortOrder: number;
  createdOn?: any;
  createdBy?: any;
  modifiedOn?: any;
  modifiedBy?: any;
}
export const emptyLookup: Lookup = {
  id: null,
  optionSet: '',
  name: '',  
  sortOrder:null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};