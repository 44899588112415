<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/User.png" alt="User" style="padding-right: 5px;">
      Create / Update User
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="userForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="userName" class="font-weight-bold" text="* UserName"></kendo-label>
          <kendo-textbox class="form-control" formControlName="userName" placeholder="Enter UserName" autocomplete="new-username">
          </kendo-textbox>
          <kendo-formerror>User Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="password" class="font-weight-bold" text="* Password"></kendo-label>
          <input class="form-control" formControlName="password" placeholder="Enter Password"
                 type="password" autocomplete="new-password"/>
          <kendo-formerror>Password is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="firstName" class="font-weight-bold" text="* First Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="firstName" placeholder="Enter First Name">
          </kendo-textbox>
          <kendo-formerror>First Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="lastName" class="font-weight-bold" text="* Last Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="lastName" placeholder="Enter Last Name">
          </kendo-textbox>
          <kendo-formerror>Last Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">       
          <input type="checkbox" formControlName="isAdmin" id="isAdmin" />
          <kendo-label class="k-checkbox-label" for="isAdmin" text="Administrator Privileges"></kendo-label>
      </div>
      <div class="col form-group"> 
            <input type="checkbox" formControlName="isDisabled" id="isDisabled" />
            <kendo-label class="k-checkbox-label" for="isDisabled" text="User is Disabled"></kendo-label>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="exampleInputEmail1" class="font-weight-bold" text="Preferred Page Size"></kendo-label>
          <select class="" placeholder="search" formControlName="pageSize">
            <option *ngFor="let limit of limits" [value]="limit">
              {{ limit }}
            </option>
          </select>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <!-- <kendo-formfield> -->
        <kendo-label for="companyName" class="font-weight-bold" text="Assigned Companies"></kendo-label>
        <div *ngFor="let data of companies; let i=index">
          <label>
            <input type="checkbox" [value]="data.value" [disabled]="this.disableCompanies" [checked]="data.checked"
                   (change)="onCheckboxChange($event)" />
            {{data.name}}
          </label>
        </div>
        <!-- </kendo-formfield> -->
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
