<div class="d-flex flex-row">
  <div class="flex-col flex-shrink-1 align-content-start">
    <mat-icon>{{ getIcon }}</mat-icon>
  </div>
  <div class="flex-col flex-grow-1 pl-4">
    {{ data.message }}
  </div>
  <div class="flex-col flex-shrink-1 align-content-end" *ngIf="data.showClose">
    <mat-icon (click)="closeSnackbar()">close</mat-icon>
  </div>
</div>
