export interface PricingScenarioDocumentValue {
  id: number;
  pricingScenarioID: number;
  pricingScenario: {
    name: string;
    id: number;    
  };
  documentAttributeID: number;
  documentAttribute: {
    name: string;
    id: number;    
  };
  value: string
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPricingScenarioDocumentValue: PricingScenarioDocumentValue = {
  id: null,
  pricingScenarioID: null,
  pricingScenario: {
    name: '',
    id: null,
  },
  documentAttributeID: null,
  documentAttribute: {
    name: '',
    id: null,
  },
  value: '',
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
