<!-- <div style="float: right;"> -->
  <img *ngIf="!expanded" src="../../../assets/images/Menu_Expand.png" style="border-style: none; cursor: pointer;" (click)="toggleMenu($event)">
  <img *ngIf="expanded" src="../../../assets/images/Menu_Collapse.png" style="border-style: none; cursor: pointer;" (click)="toggleMenu($event)">
<!-- </div> -->
<div class="bg-light border-right panel-collapse" id="sidebar-wrapper">
  <div class="panelbar-wrapper">
    <kendo-panelbar style="width: 250px;" class="foo">
      <!--Favorites Menu-->
      <kendo-panelbar-item [title]="''" class="foo">
        <ng-template kendoPanelBarItemTitle>
          <img src="../../../assets/images/Favorites.png" alt="Favorites" style="padding-right: 5px;">
          FAVORITES
        </ng-template>
        <!-- <kendo-panelbar-item [routerLink]="['/price/get-price']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/get-price'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Get_Price_Disabled.png" alt="Get Price"
              style="padding-left: 15px; padding-right: 5px;">
              Get Price
          </ng-template>
          <ng-template *ngIf="router.url === '/price/get-price'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Get_Price.png" alt="Get Price"
              style="padding-left: 15px; padding-right: 5px;">
              Get Price
          </ng-template>
        </kendo-panelbar-item> -->
        <kendo-panelbar-item [routerLink]="['/price/pricing-scenarios']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/pricing-scenario'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Get_Price_Disabled.png" alt="Pricing Scenarios"
              style="padding-left: 15px; padding-right: 5px;">
              Pricing Test Scenarios
          </ng-template>
          <ng-template *ngIf="router.url === '/price/pricing-scenario'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Get_Price.png" alt="Pricing Scenarios"
              style="padding-left: 15px; padding-right: 5px;">
              Pricing Test Scenarios
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar-item> 

      <!--Pricing-->
      <kendo-panelbar-item [title]="''">
        <ng-template kendoPanelBarItemTitle>
          <img src="../../../assets/images/Pricing.png" alt="Pricing" style="padding-right: 5px;">
          PRICING
        </ng-template>
        <kendo-panelbar-item [routerLink]="['/price/price-schedules']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/price-schedules'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Schedule_Disabled.png" alt="Price Schedules"
                 style="padding-left: 15px; padding-right: 5px;">
            Price Schedules
          </ng-template>
          <ng-template *ngIf="router.url === '/price/price-schedules'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Schedule.png" alt="Price Schedules"
                 style="padding-left: 15px; padding-right: 5px;">
            Price Schedules
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/price-calculations']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/price-calculations'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Calculation_Disabled.png" alt="Price Calculations"
                 style="padding-left: 30px; padding-right: 5px;">
            Price Calculations
          </ng-template>
          <ng-template *ngIf="router.url === '/price/price-calculations'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Calculation.png" alt="Price Calculations"
                 style="padding-left: 30px; padding-right: 5px;">
            Price Calculations
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/pricing-conditions']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/pricing-conditions'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Pricing_Condition_Disabled.png" alt="Pricing Conditions"
                 style="padding-left: 30px; padding-right: 5px;">
            Pricing Conditions
          </ng-template>
          <ng-template *ngIf="router.url === '/price/pricing-conditions'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Pricing_Condition.png" alt="Pricing Conditions"
                 style="padding-left: 30px; padding-right: 5px;">
            Pricing Conditions
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/attribute-maps']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/attribute-maps'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Attribute_Map_Disabled.png" alt="Attribute Maps"
                 style="padding-left: 30px; padding-right: 5px;">
            Attribute Maps
          </ng-template>
          <ng-template *ngIf="router.url === '/price/attribute-maps'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Attribute_Map.png" alt="Attribute Maps"
                 style="padding-left: 30px; padding-right: 5px;">
            Attribute Maps
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/pricing-filters']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/pricing-filters'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Filter_Disabled.png" alt="Pricing Filters"
                 style="padding-left: 15px; padding-right: 5px;">
            Pricing Filters
          </ng-template>
          <ng-template *ngIf="router.url === '/price/pricing-filters'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Filter.png" alt="Pricing Filters"
                 style="padding-left: 15px; padding-right: 5px;">
            Pricing Filters
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/price-books']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/price-books'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Book_Disabled.png" alt="Price Books"
                 style="padding-left: 15px; padding-right: 5px;">
            Price Books
          </ng-template>
          <ng-template *ngIf="router.url === '/price/price-books'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Book.png" alt="Price Books"
                 style="padding-left: 15px; padding-right: 5px;">
            Price Books
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item [routerLink]="['/price/price-sheets']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/price-sheets'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Sheet_Disabled.png" alt="Price Sheets"
                 style="padding-left: 30px; padding-right: 5px;">
            Price Sheets
          </ng-template>
          <ng-template *ngIf="router.url === '/price/price-sheets'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Price_Sheet.png" alt="Price Sheets"
                 style="padding-left: 30px; padding-right: 5px;">
            Price Sheets
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/catalogs']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/catalogs'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Catalog_Disabled.png" alt="Catalogs"
                 style="padding-left: 15px; padding-right: 5px;">
            Catalogs
          </ng-template>
          <ng-template *ngIf="router.url === '/price/catalogs'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Catalog.png" alt="Catalogs"
                 style="padding-left: 15px; padding-right: 5px;">
            Catalogs
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item [routerLink]="['/price/catalog-sheets']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/catalog-sheets'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Catalog_Sheet_Disabled.png" alt="Catalog Sheets"
                 style="padding-left: 30px; padding-right: 5px;">
            Catalog Sheets
          </ng-template>
          <ng-template *ngIf="router.url === '/price/catalog-sheets'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Catalog_Sheet.png" alt="Catalog Sheets"
                 style="padding-left: 30px; padding-right: 5px;">
            Catalog Sheets
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/rounding-rules']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/rounding-rules'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Rounding_Rule_Disabled.png" alt="Rounding Rules"
                 style="padding-left: 15px; padding-right: 5px;">
            Rounding Rules
          </ng-template>
          <ng-template *ngIf="router.url === '/price/rounding-rules'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Rounding_Rule.png" alt="Rounding Rules"
                 style="padding-left: 15px; padding-right: 5px;">
            Rounding Rules
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar-item>

      <!--ERP Data Menu-->
      <kendo-panelbar-item [title]="''" class="foo">
        <ng-template kendoPanelBarItemTitle>
          <img src="../../../assets/images/ERP_Data.png" alt="ERP Data" style="padding-right: 5px;">
          <div *ngIf="activeERP==='None'">ERP DATA</div>
          <div *ngIf="activeERP==='Acumatica'">ACUMATICA DATA</div>
          <div *ngIf="activeERP==='Dynamics GP'">DYNAMICS GP DATA</div>
        </ng-template>

        <kendo-panelbar-item [routerLink]="['/price/customers']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/customers'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Customer_Disabled.png" alt="Customers"
                 style="padding-left: 15px; padding-right: 5px;">
            Customers
          </ng-template>
          <ng-template *ngIf="router.url === '/price/customers'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Customer.png" alt="Customers"
                 style="padding-left: 15px; padding-right: 5px;">
            Customers
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/customer-attributes']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/customer-attributes'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Customer_Attributes_Disabled.png" alt="Customer Attributes"
                 style="padding-left: 30px; padding-right: 5px;">
            Customer Attributes
          </ng-template>
          <ng-template *ngIf="router.url === '/price/customer-attributes'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Customer_Attributes.png" alt="Customer Attributes"
                 style="padding-left: 30px; padding-right: 5px;">
            Customer Attributes
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/customer-attribute-values']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/customer-attribute-values'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Customer_Attribute_Value_Disabled.png" alt="Attribute Values"
                 style="padding-left: 30px; padding-right: 5px;">
            Attribute Values
          </ng-template>
          <ng-template *ngIf="router.url === '/price/customer-attribute-values'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Customer_Attribute_Value.png" alt="Attribute Values"
                 style="padding-left: 30px; padding-right: 5px;">
            Attribute Values
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/items']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/items'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Item_Disabled.png" alt="Items"
                 style="padding-left: 15px; padding-right: 5px;">
            Items
          </ng-template>
          <ng-template *ngIf="router.url === '/price/items'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Item.png" alt="Items" style="padding-left: 15px; padding-right: 5px;">
            Items
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/item-attributes']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/item-attributes'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Item_Attributes_Disabled.png" alt="Item Attributes"
                 style="padding-left: 30px; padding-right: 5px;">
            Item Attributes
          </ng-template>
          <ng-template *ngIf="router.url === '/price/item-attributes'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Item_Attributes.png" alt="Item Attributes"
                 style="padding-left: 30px; padding-right: 5px;">
            Item Attributes
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/item-attribute-values']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/item-attribute-values'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Item_Attribute_Value_Disabled.png" alt="Attribute Values"
                 style="padding-left: 30px; padding-right: 5px;">
            Attribute Values
          </ng-template>
          <ng-template *ngIf="router.url === '/price/item-attribute-values'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Item_Attribute_Value.png" alt="Attribute Values"
                 style="padding-left: 30px; padding-right: 5px;">
            Attribute Values
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/uofm-schedules']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/uofm-schedules'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/UofM_Schedule_Disabled.png" alt="Unit of Measure Schedules"
                 style="padding-left: 15px; padding-right: 5px;">
            Unit of Measure Schedules
          </ng-template>
          <ng-template *ngIf="router.url === '/price/uofm-schedules'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/UofM_Schedule.png" alt="Unit of Measure Schedules"
                 style="padding-left: 15px; padding-right: 5px;">
            Unit of Measure Schedules
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/currencies']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/currencies'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Currency_Disabled.png" alt="Currencies"
                 style="padding-left: 15px; padding-right: 5px;">
            Currencies
          </ng-template>
          <ng-template *ngIf="router.url === '/price/currencies'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Currency.png" alt="Currencies"
                 style="padding-left: 15px; padding-right: 5px;">
            Currencies
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/document-attributes']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/document-attributes'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Document_Attributes_Disabled.png" alt="Document Attributes"
                 style="padding-left: 15px; padding-right: 5px;">
            Document Attributes
          </ng-template>
          <ng-template *ngIf="router.url === '/price/document-attributes'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Document_Attributes.png" alt="Document Attributes"
                 style="padding-left: 15px; padding-right: 5px;">
            Document Attributes
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/gl-accounts']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/gl-accounts'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/GL_Accounts_Disabled.png" alt="GL Accounts"
                 style="padding-left: 15px; padding-right: 5px;">
            GL Accounts
          </ng-template>
          <ng-template *ngIf="router.url === '/price/gl-accounts'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/GL_Accounts.png" alt="GL Accounts"
                 style="padding-left: 15px; padding-right: 5px;">
            GL Accounts
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/locations']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/locations'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Location_Disabled.png" alt="Locations"
                 style="padding-left: 15px; padding-right: 5px;">
            Locations
          </ng-template>
          <ng-template *ngIf="router.url === '/price/locations'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Location.png" alt="Locations"
                 style="padding-left: 15px; padding-right: 5px;">
            Locations
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/historical-sales']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/historical-sales'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Historical_Sales_Disabled.png" alt="Historical Sales"
                 style="padding-left: 15px; padding-right: 5px;">
            Historical Sales
          </ng-template>
          <ng-template *ngIf="router.url === '/price/historical-sales'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Historical_Sales.png" alt="Historical Sales"
                 style="padding-left: 15px; padding-right: 5px;">
            Historical Sales
          </ng-template>
        </kendo-panelbar-item>

      </kendo-panelbar-item>

      <!--Tools Menu-->
      <kendo-panelbar-item [title]="''">
        <ng-template kendoPanelBarItemTitle>
          <img src="../../../assets/images/Tools.png" alt="Tools" style="padding-right: 5px;">
          TOOLS
        </ng-template>
        <kendo-panelbar-item [routerLink]="['/price/pricing-scenarios']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/pricing-scenario'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Get_Price_Disabled.png" alt="Pricing Scenarios"
                 style="padding-left: 15px; padding-right: 5px;">
            Pricing Test Scenarios
          </ng-template>
          <ng-template *ngIf="router.url === '/price/pricing-scenario'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Get_Price.png" alt="Pricing Scenarios"
                 style="padding-left: 15px; padding-right: 5px;">
            Pricing Test Scenarios
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/pricing-diagnostics']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/pricing-diagnostics'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/View_Diagnostics_Disabled.png" alt="View Diagnostics"
                 style="padding-left: 15px; padding-right: 5px;">
            View Diagnostics
          </ng-template>
          <ng-template *ngIf="router.url === '/price/pricing-diagnostics'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/View_Diagnostics.png" alt="View Diagnostics"
                 style="padding-left: 15px; padding-right: 5px;">
            View Diagnostics
          </ng-template>
        </kendo-panelbar-item>

        <kendo-panelbar-item [routerLink]="['/price/related-records']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/related-records'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Related_Record_Disabled.png" alt="Related Records"
                 style="padding-left: 15px; padding-right: 5px;">
            Related Records
          </ng-template>
          <ng-template *ngIf="router.url === '/price/related-records'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Related_Record.png" alt="Related Records"
                 style="padding-left: 15px; padding-right: 5px;">
            Related Records
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item *ngIf="isAdmin" [routerLink]="['/price/setting']" [title]="''">
          <ng-template *ngIf="router.url !== '/price/setting'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Settings_Disabled.png" alt="Settings"
                 style="padding-left: 15px; padding-right: 5px;">
            Settings
          </ng-template>
          <ng-template *ngIf="router.url === '/price/setting'" kendoPanelBarItemTitle>
            <img src="../../../assets/images/Settings.png" alt="Settings"
                 style="padding-left: 15px; padding-right: 5px;">
            Settings
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </div>
</div>
