import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from "../../../../app/utils/column-settings.interface";
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service'
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { RoundingRuleSingleComponent } from '../../rounding-rules/rounding-rule-single/rounding-rule-single.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-price-schedule-list',
  templateUrl: './price-schedule-list.component.html',
  styleUrls: ['./price-schedule-list.component.scss'],
})
export class PriceScheduleListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private priceScheduleService: PriceSchedulesService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService,
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input()
  loading: boolean = true;
  @Input()
  priceSchedules: PriceSchedule[] = [];
  @Input()
  query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input()
  pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input()
  mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public isAdmin: boolean;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceSchedule[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public priceSchedulePriceSourceOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 150,
      orderIndex: 0,
      hidden: false
    },
    {
      title: 'Is Active',
      field: 'isActive',
      width: 100,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Price Source Option',
      field: 'priceSourceOption.name',
      width: 250,
      orderIndex: 2,
      hidden: false
    },
    {
      title: 'Pricing Filter',
      field: 'pricingFilter.name',
      width: 150,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Rounding Rule',
      field: 'roundingRule.name',
      width: 150,
      orderIndex: 4,
      hidden: false
    },
    {
      title: 'Price Book',
      field: 'priceBook.name',
      width: 150,
      orderIndex: 5,
      hidden: true
    },
    {
      title: 'Use In Dynamic Pricing',
      field: 'useInDynamicPricing',
      width: 175,
      orderIndex: 6,
      hidden: true
    },
    {
      title: 'Dynamic Pricing Rank',
      field: 'dynamicPricingRank',
      width: 175,
      orderIndex: 7,
      hidden: true
    },
    {
      title: 'ERP ID',
      field: 'erpid',
      width: 100,
      orderIndex: 8,
      hidden: true
    }
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.priceScheduleService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData; //mhr unclear what this does
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_Exportold(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  async getPriceSchedulePriceSourceOptions() {
    const Filter_OptionSet = 'PriceSource';
    const params = { Filter_OptionSet };
    this.priceSchedulePriceSourceOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "PriceSource";
    defaultLookup.name = "(All)";
    this.priceSchedulePriceSourceOptions.reverse();
    this.priceSchedulePriceSourceOptions.push(defaultLookup);
    this.priceSchedulePriceSourceOptions.reverse();
    return this.priceSchedulePriceSourceOptions;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceScheduleList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceScheduleList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PriceScheduleList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceScheduleList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.isAdmin = StorageService.IsAdmin();
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();

    this.getPriceSchedulePriceSourceOptions();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  show_RoundingRule(roundingRule) {
    const modalRef = this.modalService.open(RoundingRuleSingleComponent, { });
    modalRef.componentInstance.roundingRule = roundingRule;
    modalRef.componentInstance.idOfRoundingRule = roundingRule.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMPriceSchedule", Number(value));
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!  Please refresh the grid to see results.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(inputXML, 'RPMPriceSchedule');
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/price-schedule/' + this.clickedRowItem.id]);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'useInDynamicPricing') {
          const Filter_UseInDynamicPricing = myFilter.value;
          const Operand_UseInDynamicPricing = myFilter.operator;
          const params = { ...this.query, Filter_UseInDynamicPricing, Operand_UseInDynamicPricing };
          this.query = params;
        }
        if (myFilter.field == 'dynamicPricingRank') {
          const Filter_DynamicPricingRank = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_DynamicPricingRank = myFilter.operator;
          const params = { ...this.query, Filter_DynamicPricingRank, Operand_DynamicPricingRank };
          this.query = params;
        }
        if (myFilter.field == 'isHighestPrice') {
          const Filter_MaximumPrice = myFilter.value;
          const Operand_MaximumPrice = myFilter.operator;
          const params = { ...this.query, Filter_MaximumPrice, Operand_MaximumPrice };
          this.query = params;
        }
        if (myFilter.field == 'isLowestPrice') {
          const Filter_MinimumPrice = myFilter.value;
          const Operand_MinimumPrice = myFilter.operator;
          const params = { ...this.query, Filter_MinimumPrice, Operand_MinimumPrice };
          this.query = params;
        }
        if (myFilter.field == 'priceSourceOption.name') {
          if (myFilter.value != '(All)') {
            const Filter_PriceSource = myFilter.value;
            const Operand_PriceSource = myFilter.operator;
            const params = { ...this.query, Filter_PriceSource, Operand_PriceSource };
            this.query = params;
          }
        }
        if (myFilter.field == 'pricingFilter.name') {
          const Filter_PricingFilter = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_PricingFilter = myFilter.operator;
          const params = { ...this.query, Filter_PricingFilter, Operand_PricingFilter };
          this.query = params;
        }
        if (myFilter.field == 'priceBook.name') {
          const Filter_PriceBook = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_PriceBook = myFilter.operator;
          const params = { ...this.query, Filter_PriceBook, Operand_PriceBook };
          this.query = params;
        }
        if (myFilter.field == 'isActive') {
          const Filter_IsActive = myFilter.value;
          const Operand_IsActive = myFilter.operator;
          const params = { ...this.query, Filter_IsActive, Operand_IsActive };
          this.query = params;
        }
        if (myFilter.field == 'roundingRule.name') {
          const Filter_RoundingRule = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_RoundingRule = myFilter.operator;
          const params = { ...this.query, Filter_RoundingRule, Operand_RoundingRule };
          this.query = params;
        }
        if (myFilter.field == 'erpid') {
          const Filter_ERP = myFilter.value;
          const Operand_ERP = myFilter.operator;
          const params = { ...this.query, Filter_ERP, Operand_ERP };
          this.query = params;
        }
        if (myFilter.field == 'calculateAfterPriceSchedule.name') {
          const Filter_CalculateAfterPriceSchedule = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_CalculateAfterPriceSchedule = myFilter.operator;
          const params = { ...this.query, Filter_CalculateAfterPriceSchedule, Operand_CalculateAfterPriceSchedule };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.priceScheduleService.getExportList(this.query);
  }
  edit(id: number) {
    this.router.navigate([`/price/price-schedule/${id}`]);
  }
  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.priceScheduleService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceScheduleService.getList(this.query);
      this.priceSchedules = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.priceSchedules,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
