import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceSheetDetailsService } from '../../../services/price-sheet-details/price-sheet-details.service';
import { PriceSheetDetail, emptyPriceSheetDetail } from '../../../services/price-sheet-details/price-sheet-details.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { Customer } from 'src/app/services/customers/customers.model';
import { CustomersService } from '../../../services/customers/customers.service';
import { Item } from 'src/app/services/items/items.model';
import { ItemsService } from '../../../services/items/items.service';
import { UofM } from 'src/app/services/uofms/uofms.model';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { Location } from 'src/app/services/locations/locations.model';
import { LocationsService } from '../../../services/locations/locations.service';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { QuantityBreaksService } from '../../../services/quantity-breaks/quantity-breaks.service';
import { QuantityBreak } from 'src/app/services/quantity-breaks/quantity-breaks.model';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { emptyPricingFilter, PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { UofMLookupComponent } from '../../uofms/uofm-lookup/uofm-lookup.component';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  Customer = 1,
  Item,
  UofM,
  Location,
  PricingFilter
}
@Component({
  selector: 'app-price-sheet-detail-single',
  templateUrl: './price-sheet-detail-single.component.html',
  styleUrls: ['./price-sheet-detail-single.component.scss'],
})
export class PriceSheetDetailSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfPriceSheetDetail: number | null = null;
  @Input() idOfPriceSheet: number | null = null;
  priceSheetDetail: PriceSheetDetail = emptyPriceSheetDetail;
  loading: boolean = true;
  priceSheetDetailForm: FormGroup;
  submitted = false;
  usePricingFilter: boolean = false;
  customers: Customer[] = [];
  items: Item[] = [];
  uofMs: UofM[] = [];
  locations: Location[] = [];
  pricingFilters: PricingFilter[] = [];
  quantityRulesOptions: Lookup[] = [];
  quantityBreaks: QuantityBreak[] = [];
  pagination_customers: any = { customers: mockedData };
  pagination_items: any = { items: mockedData };
  pagination_uofMs: any = { uofMs: mockedData };
  pagination_locations: any = { locations: mockedData };
  pagination_quantityBreaks: any = { quantityBreaks: mockedData };
  pagination_pricingFilters: any = { pricingFilters: mockedData };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  useQuantityBreaks: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private priceSheetDetailsService: PriceSheetDetailsService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private uofMsService: UofMsService,
    private locationsService: LocationsService,
    private quantityBreaksService: QuantityBreaksService,
    private pricingFiltersService: PricingFiltersService,
    private router: Router,
    private notificationHelper: NotificationHelper
  ) {     
  }

  ngOnInit(): void {   

    if (this.idOfPriceSheetDetail) {
      this.loadData();
    }
    else {
      this.priceSheetDetail = emptyPriceSheetDetail;
      this.priceSheetDetail.pricingFilter = emptyPricingFilter;
      this.priceSheetDetail.priceSheetID = this.idOfPriceSheet;
      this.idOfPriceSheetDetail = null;
      this.submitted = false;      
    }
    //if (this.idOfPriceSheet) {
    //  this.priceSheetDetail.priceSheetID = this.idOfPriceSheet;
    //}
    this.buildForm();
    this.updateForm();
  }
  async buildForm() {
    this.priceSheetDetailForm = new FormGroup({
      price: new FormControl(0, Validators.required),
      priceSheetID: new FormControl(),
      useFilter: new FormControl(false),
      quantityRulesOptionID: new FormControl(0, Validators.required),
      customerID: new FormControl(),
      itemID: new FormControl(),
      uofMID: new FormControl(),
      locationID: new FormControl(),
      pricingFilterID: new FormControl(),
    });

    // Wait for the QuantityRulesOptions to be populated and then set the default to 'None''.
    this.quantityRulesOptions = await Promise.resolve(this.getQuantityRulesOptions());

    if (!this.idOfPriceSheetDetail) {
      this.priceSheetDetail.quantityRulesOptionID = this.quantityRulesOptions[0].id;
      this.priceSheetDetailForm.patchValue({ ['quantityRulesOptionID']: this.quantityRulesOptions[0].id });
      this.onSelectBoxChanged(this.quantityRulesOptions[0].id, "quantityRulesOption", this.quantityRulesOptions);
    }

    this.priceSheetDetailForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.priceSheetDetailForm.controls;
  }
  updateForm() {
    this.priceSheetDetailForm.patchValue({ ...this.priceSheetDetail });
    this.onSelectBoxChanged(this.priceSheetDetail.quantityRulesOptionID, "quantityRulesOption", this.quantityRulesOptions);
    this.isFormChanged = false;
  }    
  //pricingFilterRequiredValidator(formControl: AbstractControl) {
  //  if (!formControl.parent) {
  //    return null;
  //  }
  //  if (formControl.parent.get('useFilter').value == true) {
  //    return Validators.required(formControl);
  //  }
  //  return null;
  //}

  
  onQuantityRulesOption_Changed(value:any){
    this.priceSheetDetail.quantityRulesOptionID = value.id;
  }

  onSelectBoxChanged(id: number, key: keyof PriceSheetDetail, arr) {
    let item = null;
    console.log('key: ' + key);
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.priceSheetDetail[key] = item;
    if (key == 'item') {
      this.uofMs = [];
      this.priceSheetDetailForm.patchValue({ ['uofMID']: null });
      this.setDefaultUofM();
    }
    if (key == 'quantityRulesOption') {
      if (id == null || id === 0 || id === 41) {
        this.useQuantityBreaks = false;
      }
      else {
        this.useQuantityBreaks = true;
      }
    }
  }

  clearPrefill() {
    setTimeout(() => {
      this.quantityRulesOptions = [];
    });
  }

  // TODO: Complete this
  /* private bool IsPriceSheetDetailUnique()
  {
      bool isRecordUnique = true;

      string sqlConnectionString = Utilities.GetDirectSQLConnectionString();
      SqlConnection connection = new SqlConnection(sqlConnectionString);
      SqlCommand command = new SqlCommand("[usp_RPMCheckPSDExists]", connection)
      {
          CommandType = CommandType.StoredProcedure
      };

      SqlParameter priceSheetIDParam = command.Parameters.Add("@PriceSheetID", SqlDbType.Int);
      priceSheetIDParam.IsNullable = false;
      priceSheetIDParam.Value = SelectedPriceSheet.ID;

      SqlParameter customerIDParam = command.Parameters.Add("@CustomerID", SqlDbType.Int);
      customerIDParam.IsNullable = true;
      customerIDParam.Value = _selectedCustomer?.ID;

      SqlParameter customerFilterIDParam = command.Parameters.Add("@CustomerFilterID", SqlDbType.Int);
      customerFilterIDParam.IsNullable = true;
      customerFilterIDParam.Value = _selectedCustomerFilter?.ID;

      SqlParameter itemIDParam = command.Parameters.Add("@ItemID", SqlDbType.Int);
      itemIDParam.IsNullable = true;
      itemIDParam.Value = _selectedItem?.ID;

      SqlParameter itemFilterIDParam = command.Parameters.Add("@ItemFilterID", SqlDbType.Int);
      itemFilterIDParam.IsNullable = true;
      itemFilterIDParam.Value = _selectedItemFilter?.ID;

      SqlParameter uofMIDParam = command.Parameters.Add("@UofMID", SqlDbType.Int);
      uofMIDParam.IsNullable = true;
      uofMIDParam.Value = _selectedUofM?.ID;

      SqlParameter locationIDParam = command.Parameters.Add("@LocationID", SqlDbType.Int);
      locationIDParam.IsNullable = true;
      locationIDParam.Value = _selectedLocation?.ID;

      SqlParameter documentFilterIDParam = command.Parameters.Add("@DocumentFilterID", SqlDbType.Int);
      documentFilterIDParam.IsNullable = true;
      documentFilterIDParam.Value = _selectedDocumentFilter?.ID;

      SqlParameter priceSheetDetailToCopyIDParam = command.Parameters.Add("@CurrentPriceSheetDetailID", SqlDbType.Int);
      priceSheetDetailToCopyIDParam.IsNullable = true;
      priceSheetDetailToCopyIDParam.Value = _currentRecord?.ID;

      SqlParameter numberOfRecordsParam = command.Parameters.Add("@NumberOfRecords", SqlDbType.Int);
      numberOfRecordsParam.Direction = ParameterDirection.Output;

      connection.Open();
      command.ExecuteNonQuery();

      int returnedNumberOfRecordsParam = (int)numberOfRecordsParam.Value;
      connection.Close();

      if (returnedNumberOfRecordsParam > 0)
      {
          isRecordUnique = false;
      }

      return isRecordUnique;
  }
*/

  async submit(): Promise<boolean> {
    this.priceSheetDetailForm.markAllAsTouched();
    this.submitted = true;
    if (this.priceSheetDetailForm.invalid) {

      // Log names of invalid controls to the console.
      Object.keys(this.priceSheetDetailForm.controls).forEach(key => {
        const control = this.priceSheetDetailForm.controls[key];
        if (control.invalid) {
          console.log(`Control with name ${key} is invalid.`);
        }
      });

      window.scrollTo(0, 0);
      return false;
    }
    const data = {
      ...this.priceSheetDetail,
      ...this.priceSheetDetailForm.value,
    };

    // TODO: Call stored proc above to check that PSD is unique.

    let isSuccess = false;

    if (this.priceSheetDetail.id) {
      try {
        const response: any = await this.priceSheetDetailsService.update(
          this.priceSheetDetail.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatusOnDialog('Record updated successfully!', "success", this.container);
          isSuccess = true;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.priceSheetDetailsService.create(
          this.priceSheetDetailForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.priceSheetDetail = response.body;
          this.idOfPriceSheetDetail = this.priceSheetDetail.id;
          this.notificationHelper.showStatusOnDialog('Record saved successfully!', "success", this.container);
          isSuccess = true;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
    return isSuccess;
  }

  onClick_Save() {
    this.submit();
  }

  async onClick_SaveClose() {
    const success = await this.submit();
    if (success == true) {
      this.onClick_Close("");
    }
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }
  async getPriceSheetDetailById() {
    if (this.idOfPriceSheetDetail) {
      return await this.priceSheetDetailsService.getById(
        this.idOfPriceSheetDetail,
      );
    } else {
      return emptyPriceSheetDetail;
    }
  }

  async getQuantityRulesOptions() {
    const Filter_OptionSet = 'QuantityRules';
    const params = { Filter_OptionSet };
    this.quantityRulesOptions = await this.lookupService.getList(params);    
    return this.quantityRulesOptions;
  }
   filterChange(event, arr) {
    this.getQuantityBreaks(event);
  }
  async getQuantityBreaks(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.quantityBreaksService.getList({
        Filter_PriceSheetDetailID: this.idOfPriceSheetDetail,
        ...params, PageSize
      });
      // @ts-ignore
      this.quantityBreaks = resp.body;
      this.pagination_quantityBreaks.quantityBreaks = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.quantityBreaks = [];
    }

    return this.quantityBreaks;
  }

  async getCustomerById(id) {
    return await this.customersService.getById(id);
  }  
  async getItemById(id) {
    return await this.itemsService.getById(id);
  }  
  async getUofMById(id) {
    return await this.uofMsService.getById(id);
  }
  async getLocationById(id) {
    return await this.locationsService.getById(id);
  }

  async getPricingFilterById(id) {
    return await this.pricingFiltersService.getById(id);
  }

  useFilterChanged() {
    if (this?.f?.useFilter) {
      if (this.f.useFilter.value == true) {
        this.priceSheetDetailForm.patchValue({ ['itemID']: null });
        this.priceSheetDetailForm.patchValue({ ['customerID']: null });
        this.priceSheetDetailForm.patchValue({ ['uofMID']: null });
        this.priceSheetDetailForm.patchValue({ ['locationID']: null });
        this.f.pricingFilterID.setValidators(Validators.required);
      }
      else {
        this.priceSheetDetailForm.patchValue({ ['pricingFilterID']: null });
        this.f.pricingFilterID.clearValidators();
      }
      this.f.pricingFilterID.updateValueAndValidity();
      this.usePricingFilter = this.f.useFilter.value
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [
        this.priceSheetDetail,
        this.quantityBreaks,
      ] = await Promise.all([
        this.getPriceSheetDetailById(),
        this.idOfPriceSheetDetail ? this.getQuantityBreaks(null) : [],
      ]);

      // Customers     
      if (this.priceSheetDetail.customerID) {        
        const currentCustomer: Customer = await Promise.resolve(this.getCustomerById(this.priceSheetDetail.customerID));
        this.customers.push(currentCustomer);
      }

      // Items     
      if (this.priceSheetDetail.itemID) {
        const currentItem: Item = await Promise.resolve(this.getItemById(this.priceSheetDetail.itemID));
        this.items.push(currentItem);
      }

      // UofMs     
      if (this.priceSheetDetail.uofMID) {
        const currentUofM: UofM = await Promise.resolve(this.getUofMById(this.priceSheetDetail.uofMID));
        this.uofMs.push(currentUofM);
      }

      // Location      
      if (this.priceSheetDetail.locationID) {
        const currentLocation: Location = await Promise.resolve(this.getLocationById(this.priceSheetDetail.locationID));
        this.locations.push(currentLocation);
      }

      // Pricing Filter      
      if (this.priceSheetDetail.pricingFilterID) {
        const currentPricingFilter: PricingFilter = await Promise.resolve(this.getPricingFilterById(this.priceSheetDetail.pricingFilterID));
        this.pricingFilters.push(currentPricingFilter);
      }

      if (this.priceSheetDetail.useFilter) {
        this.usePricingFilter = this.priceSheetDetail.useFilter;
      }

      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }

    this.onFormChanged.emit(false);
  }
  // prepareData() {
  //   [
  //     // @ts-ignore
  //     this.quantityRulesOptions,
  //     this.appliesToCustomerOptions,
  //     this.appliesToItemOptions,
  //     this.appliesToUofMOptions,
  //     this.appliesToLocationOptions,
  //     this.appliesToDocumentOptions,
  //   ] = prepareData(
  //     this.priceSheetDetail,
  //     this.quantityRulesOptions,
  //     this.appliesToCustomerOptions,
  //     this.appliesToItemOptions,
  //     this.appliesToUofMOptions,
  //     this.appliesToLocationOptions,
  //     this.appliesToDocumentOptions,
  //   );
  //   this.updateForm();
  //   this.clearPrefill();
  // }

  async getCustomers(params) {
    try {
      const resp = await this.customersService.getList({
        ...params,
      });
      // @ts-ignore
      this.customers = resp.body;
      this.pagination_customers.customers = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.customers = [];
    }
    return this.customers;
  }

  async onLookup_Customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.priceSheetDetailForm.patchValue({['customerID']: receivedEntry.id });
      this.priceSheetDetail.customer = receivedEntry;
      modalRef.close('test');
    });
  }

  async getItems(params) {
    try {
      const resp = await this.itemsService.getList({
        ...params,
      });
      // @ts-ignore
      this.items = resp.body;
      this.pagination_items.items = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.items = [];
    }
    return this.items;
  }

  async onLookup_Item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.priceSheetDetailForm.patchValue({ ['itemID']: receivedEntry.id });
      this.uofMs = [];
      this.priceSheetDetailForm.patchValue({ ['uofMID']: null });
      this.priceSheetDetail.item = receivedEntry;

      this.setDefaultUofM();

      modalRef.close('test');
    });
  }

  async setDefaultUofM() {
    const Filter_UofMScheduleID = this.priceSheetDetail.item.uofMScheduleID;
    if (Filter_UofMScheduleID) {
      await this.getUofMs({ Filter_UofMScheduleID });
      if (!this.priceSheetDetailForm.get('uofMID').value) {
        const baseUofM = this.uofMs.find(uofM => uofM.isBase);
        if (baseUofM) {
          this.priceSheetDetailForm.patchValue({ 'uofMID': baseUofM.id });
        }
      }
    }
  }

  async getUofMs(params) {
    try {
      const resp = await this.uofMsService.getList({
        ...params,
      });
      // @ts-ignore
      this.uofMs = resp.body;
      this.pagination_uofMs.uofMs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.uofMs = [];
    }
    return this.uofMs;
  }

  async onLookup_UofM() {
    const modalRef = this.modalService.open(UofMLookupComponent, {});
    modalRef.componentInstance.uofMScheduleID = this.priceSheetDetail.item.uofMScheduleID;
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofM = receivedEntry;
      this.uofMs = [];
      this.uofMs.push(recordSelected);
      this.priceSheetDetailForm.patchValue({['uofMID']: receivedEntry.id});
      this.priceSheetDetail.uofM = receivedEntry;
      modalRef.close('test');
    });
  }

  async getLocations(params) {
    try {
      const resp = await this.locationsService.getList({
        ...params,
      });
      // @ts-ignore
      this.locations = resp.body;
      this.pagination_locations.locations = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.locations = [];
    }
    return this.locations;
  }

  async onLookup_Location() {
    const modalRef = this.modalService.open(LocationLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.priceSheetDetailForm.patchValue({['locationID']: receivedEntry.id});
      this.priceSheetDetail.location = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PricingFilter() {
    const modalRef = this.modalService.open(PricingFilterLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingFilter = receivedEntry;
      this.pricingFilters = [];
      this.pricingFilters.push(recordSelected);
      this.priceSheetDetailForm.patchValue({ ['pricingFilterID']: receivedEntry.id });
      this.priceSheetDetail.pricingFilter = receivedEntry;
      modalRef.close('test');
    });
  }

  async getPricingFilters(params) {
    try {
      const resp = await this.pricingFiltersService.getList({
        ...params,
      });
      // @ts-ignore
      this.pricingFilters = resp.body;
      this.pagination_pricingFilters.pricingFilter = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.pricingFilters = [];
    }
    return this.pricingFilters;
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_UofMScheduleID = this.priceSheetDetail.item.uofMScheduleID;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.Customer:
        // @ts-ignore
        this.customers = this.getCustomers(params);
        break;     
      case Filters.Item:
        // @ts-ignore
        this.items = this.getItems(params);
        break;
      case Filters.UofM:
        // @ts-ignore
        this.uofMs = this.getUofMs({ Filter_UofMScheduleID, ...params });
        break;
      case Filters.Location:
        // @ts-ignore
        this.locations = this.getLocations(params);
        break;
      case Filters.PricingFilter:
        // @ts-ignore
        this.pricingFilters = this.getPricingFilters(params);
        break;      
      default:
        break;
    }
  }
  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
