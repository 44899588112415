import { Injectable, EventEmitter, Output } from '@angular/core';
//import { Authentication } from './authentication.model';
import { StorageService } from '../../utils/StorageHelper';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  constructor() {}
  @Output() sideNavChangedEvent = new EventEmitter<boolean>();

  SideNavChanged(expanded: boolean) {    
    StorageService.set('SideNavExpanded_Config', expanded);
    this.sideNavChangedEvent.emit();
  }
}
