import { ColumnSettings } from '../utils/column-settings.interface';

export class StorageService {
  static getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  static setColumnSettings<T>(token: string, colConfig: ColumnSettings[]): void {
    // Make the token specific to the User.
    const user_token = token + '_' + this.UserID();
    
    localStorage.setItem(
      user_token,
      JSON.stringify(colConfig, this.getCircularReplacer()),
    );
  }
  static getColumnSettings<T>(token: string): T {
    // Make the token specific to the User.
    const user_token = token + '_' + this.UserID();
    const settings = localStorage.getItem(user_token);
    return settings ? JSON.parse(settings) : settings;
  }
  static removeColumnSettings<T>(token: string) {
    // Make the token specific to the User.
    const user_token = token + '_' + this.UserID();
    const settings = localStorage.removeItem(user_token);
  }
  
  static set(field: string, value: any, sessionOnly = false) {
    sessionOnly
      ? sessionStorage.setItem(field, JSON.stringify(value))
      : localStorage.setItem(field, JSON.stringify(value));
  }

  static get(field: string, sessionOnly = false) {
    try {
      return sessionOnly
        ? JSON.parse(sessionStorage.getItem(field))
        : JSON.parse(localStorage.getItem(field));
    } catch (error) {
      return null;
    }
  }

  static remove(field: string) {
    localStorage.removeItem(field);
    sessionStorage.removeItem(field);
  }

  static UserID() {
    return (
    sessionStorage.getItem('userID') || 
    localStorage.getItem('userID'));
  }

  static setUserID(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('userID', token)
      : localStorage.setItem('userID', token);
  }

  static clearUserID() {
    StorageService.remove('userID');
  }

  static LastCacheRefresh() {
    return (
      sessionStorage.getItem('lastCacheRefresh') ||
      localStorage.getItem('lastCacheRefresh'));
  }

  static setLastCacheRefresh(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('lastCacheRefresh', token)
      : localStorage.setItem('lastCacheRefresh', token);
  }

  static clearLastCacheRefresh() {
    StorageService.remove('lastCacheRefresh');
  }

  static UserName() {
    return (
      sessionStorage.getItem('userName') || 
      localStorage.getItem('userName'));
  }

  static setUserName(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('userName', token)
      : localStorage.setItem('userName', token);
  }

  static clearUserName() {
    StorageService.remove('userName');
  }

  static UserFirstName() {
    return (
      sessionStorage.getItem('userFirstName') ||
      localStorage.getItem('userFirstName'));
  }

  static setUserFirstName(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('userFirstName', token)
      : localStorage.setItem('userFirstName', token);
  }

  static clearUserFirstName() {
    StorageService.remove('userFirstName');
  }

  static UserLastName() {
    return (
      sessionStorage.getItem('userLastName') ||
      localStorage.getItem('userLastName'));
  }

  static setUserLastName(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('userLastName', token)
      : localStorage.setItem('userLastName', token);
  }

  static clearUserLastName() {
    StorageService.remove('userLastName');
  }

  static IsAdmin() {
    return (
      JSON.parse(sessionStorage.getItem('isAdmin')) ||
      JSON.parse(localStorage.getItem('isAdmin')));
  }

  static setIsAdmin(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('isAdmin', token)
      : localStorage.setItem('isAdmin', token);
  }

  static clearIsAdmin() {
    StorageService.remove('isAdmin');
  }

  static ERPName() {
    return (
      sessionStorage.getItem('activeERP') ||
      localStorage.getItem('activeERP'));
  }

  static LoggingLevelName() { 
    return (
      sessionStorage.getItem('activeLoggingLevel') ||
      localStorage.getItem('activeLoggingLevel'));
  }

  static setERPName(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('activeERP', token)
      : localStorage.setItem('activeERP', token);
  }

  static setLoggingLevelName(token, onlySession = false) { 
    onlySession
      ? sessionStorage.setItem('activeLoggingLevel', token)
      : localStorage.setItem('activeLoggingLevel', token);
  }

  static clearERPName() {
    StorageService.remove('activeERP');
  }

  static clearLoggingLevelName() { 
    StorageService.remove('activeLoggingLevel');
  }

  static IsDisabled() {
    return (
      JSON.parse(sessionStorage.getItem('isDisabled')) ||
      JSON.parse(localStorage.getItem('isDisabled')));
  }

  static setIsDisabled(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('isDisabled', token)
      : localStorage.setItem('isDisabled', token);
  }

  static clearIsDisabled() {
    StorageService.remove('isDisabled');
  }

  static PageSize() {
    return (
      JSON.parse(sessionStorage.getItem('pageSize')) ||
      JSON.parse(localStorage.getItem('pageSize')));
  }

  static setPageSize(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('pageSize', token)
      : localStorage.setItem('pageSize', token);
  }

  static clearPageSize() {
    StorageService.remove('pageSize');
  }

  static CompanyID() {
    return (
      sessionStorage.getItem('companyID') ||
      localStorage.getItem('companyID'));
  }

  static setCompanyID(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('companyID', token)
      : localStorage.setItem('companyID', token);
  }

  static clearCompanyID() {
    StorageService.remove('companyID');
  }

  static CompanyName() {
    return (
      sessionStorage.getItem('companyName') ||
      localStorage.getItem('companyName'));
  }

  static setCompanyName(token, onlySession = false) {
    onlySession
      ? sessionStorage.setItem('companyName', token)
      : localStorage.setItem('companyName', token);
  }

  static clearCompanyName() {
    StorageService.remove('companyName');
  }

  static clearAll() {
    sessionStorage.clear();
    localStorage.clear();
  }
}
