import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingScenariosService } from '../../../services/pricing-scenarios/pricing-scenarios.service';
import { emptyPricingScenario, PricingScenario } from '../../../services/pricing-scenarios/pricing-scenarios.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';

import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
  SelectableSettings,
  SelectableMode
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PriceFilter } from 'src/app/services/price-filter/price-filter.model';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { ToolsService } from 'src/app/services/tools/tools.service';
import { emptyPriceAdjustment } from 'src/app/services/price-adjustments/price-adjustments.model';
import { PriceSchedule } from 'src/app/services/price-schedules/price-schedules.model';
import { GLAccount } from 'src/app/services/glaccounts/glaccounts.model';
import { PriceSchedulesService } from 'src/app/services/price-schedules/price-schedules.service';
import { GLAccountsService } from 'src/app/services/glaccounts/glaccounts.service';
import { PricingDiagnostic } from 'src/app/services/pricing-diagnostics/pricing-diagnostics.model';
import { PricingDiagnosticsService } from 'src/app/services/pricing-diagnostics/pricing-diagnostics.service';
import { PricingScenarioDocumentValuesService } from 'src/app/services/pricing-scenario-document-values/pricing-scenario-document-values.service';
import { PricingScenarioDocumentValue } from 'src/app/services/pricing-scenario-document-values/pricing-scenario-document-values.model';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';

@Component({
  selector: 'app-pricing-scenario-list',
  templateUrl: './pricing-scenario-list.component.html',
  styleUrls: ['./pricing-scenario-list.component.scss'],
})
export class PricingScenarioListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private pricingScenariosService: PricingScenariosService,
    private priceSchedulesService: PriceSchedulesService,
    private pricingScenarioDocumentValuesService: PricingScenarioDocumentValuesService,
    private glAccountsService: GLAccountsService,
    private toolsService: ToolsService,
    private pricingDiagnosticsService: PricingDiagnosticsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService,
    private utilitiesService: UtilitiesService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input()
  loading: boolean = true;
  @Input()
  pricingScenarios: PricingScenario[] = [];
  @Input()
  query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input()
  pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  pricingScenarioDocumentValues: PricingScenarioDocumentValue[] = [];

  pagination_pricingScenarios: any = { pricingScenarios: mockedData };
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceFilter[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;

  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Select',
      field: '',
      width: 40,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Customer Name',
      field: 'customer.name',
      width: 150,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Customer Number',
      field: 'customer.number',
      width: 150,
      orderIndex: 3,
      hidden: true,
    },
    {
      title: 'Item Name',
      field: 'item.name',
      width: 150,
      orderIndex: 4,
      hidden: false,
    },
    {
      title: 'Item Number',
      field: 'item.number',
      width: 150,
      orderIndex: 5,
      hidden: true,
    },
    {
      title: 'Quantity',
      field: 'quantity',
      width: 200,
      orderIndex: 6,
      hidden: false,
    },
    {
      title: 'Effective Date',
      field: 'effectiveDate',
      width: 150,
      orderIndex: 7,
      hidden: false,
    },
    {
      title: 'Use Today',
      field: 'useToday',
      width: 100,
      orderIndex: 8,
      hidden: false,
    },
    {
      title: 'Price Schedule',
      field: 'priceSchedule.name',
      width: 150,
      orderIndex: 9,
      hidden: false,
    },
    {
      title: 'Last Returned Unit Price',
      field: 'lastReturnedUnitPrice',
      width: 150,
      orderIndex: 10,
      hidden: false,
    },
    {
      title: 'Last Returned Price Schedule',
      field: 'lastReturnedPriceSchedule.name',
      width: 150,
      orderIndex: 11,
      hidden: false,
    },
    {
      title: 'Expected Unit Price Amount',
      field: 'expectedUnitPriceAmount',
      width: 100,
      orderIndex: 12,
      hidden: false,
    },
    {
      title: 'Expected Price Schedule',
      field: 'expectedPriceSchedule.name',
      width: 100,
      orderIndex: 13,
      hidden: false,
    },
    {
      title: 'Mismatch',
      field: 'mismatch',
      width: 100,
      orderIndex: 14,
      hidden: false,
    },
    {
      title: 'Scenario Group',
      field: 'scenarioGroup',
      width: 150,
      orderIndex: 15,
      hidden: false,
    },
    {
      title: 'MultiLine Tag',
      field: 'multiLineTag',
      width: 150,
      orderIndex: 16,
      hidden: true,
    },
    {
      title: 'Status',
      field: 'processStatus',
      width: 150,
      orderIndex: 17,
      hidden: false,
    },
    {
      title: 'Last Execution Date Time',
      field: 'lastExecutionDateTime',
      width: 100,
      orderIndex: 18,
      hidden: true,
    },
    {
      title: 'Last Execution Diagnostic ID',
      field: 'lastExecutionDiagnosticID',
      width: 100,
      orderIndex: 19,
      hidden: true,
    },
    {
      title: 'Currency',
      field: 'currency.name',
      width: 150,
      orderIndex: 20,
      hidden: true,
    },
    {
      title: 'U of M',
      field: 'uofM.name',
      width: 150,
      orderIndex: 21,
      hidden: true,
    },
    {
      title: 'Location',
      field: 'location.name',
      width: 200,
      orderIndex: 22,
      hidden: true,
    },
    {
      title: 'Value From ERP',
      field: 'valueFromERP',
      width: 200,
      orderIndex: 23,
      hidden: true,
    },
    {
      title: 'Document Attributes',
      field: 'documentAttributes',
      width: 200,
      orderIndex: 24,
      hidden: true,
    },
  ];
  columnsConfig: ColumnSettings[];
  public actionMenuItems: any[] = [];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.buildActionsMenu();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMPricingScenario", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
    this.loadListData();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.pricingScenariosService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.hasDescColumns = true;
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PricingScenarioList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PricingScenarioList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PricingScenarioList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PricingScenarioList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value

        //old code
        //var col = z.substring(0, 1);
        //var row = parseInt(z.substring(1));
        //var value = ws[z].v;

        //new code from ChatGPT, allows for columns beyond Z, such as AA, AB, AC etc.
        const match = z.match(/([A-Z]+)(\d+)/);
        if (!match) continue;
        const [, col, rowString] = match;
        const row = parseInt(rowString);
        const value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(
        inputXML,
        'RPMPricingScenario',
      );
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onRefresh() {
    this.loadListData();
    this.buildActionsMenu();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/pricing-scenario/' + this.clickedRowItem.id]);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'priceSchedule.name') {
          const Filter_PriceSchedule =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PriceSchedule = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PriceSchedule,
            Operand_PriceSchedule,
          };
          this.query = params;
        }
        if (myFilter.field == 'effectiveDate') {
          const Filter_EffectiveDate =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_EffectiveDate = myFilter.operator;
          const params = {
            ...this.query,
            Filter_EffectiveDate,
            Operand_EffectiveDate,
          };
          this.query = params;
        }
        if (myFilter.field == 'quantity') {
          const Filter_Quantity = myFilter.value;
          const Operand_Quantity = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Quantity,
            Operand_Quantity,
          };
          this.query = params;
        }
        if (myFilter.field == 'valueFromERP') {
          const Filter_ValueFromERP = myFilter.value == null ? '' : myFilter.value;
          const Operand_ValueFromERP = myFilter.operator;
          const params = { ...this.query, Filter_ValueFromERP, Operand_ValueFromERP };
          this.query = params;
        }
        if (myFilter.field == 'item.name') {
          const Filter_Item = myFilter.value;
          const Operand_Item = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Item,
            Operand_Item,
          };
          this.query = params;
        }
        if (myFilter.field == 'uofM.name') {
          const Filter_UofM = myFilter.value;
          const Operand_UofM = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UofM,
            Operand_UofM,
          };
          this.query = params;
        }
        if (myFilter.field == 'currency.name') {
          const Filter_Currency = myFilter.value;
          const Operand_Currency = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Currency,
            Operand_Currency,
          };
          this.query = params;
        }
        if (myFilter.field == 'customer.name') {
          const Filter_Customer = myFilter.value;
          const Operand_Customer = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Customer,
            Operand_Customer,
          };
          this.query = params;
        }
        if (myFilter.field == 'location.name') {
          const Filter_Location = myFilter.value;
          const Operand_Location = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Location,
            Operand_Location,
          };
          this.query = params;
        }
        if (myFilter.field == 'lastReturnedUnitPrice') {
          const Filter_LastReturnedUnitPrice =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_LastReturnedUnitPrice = myFilter.operator;
          const params = {
            ...this.query,
            Filter_LastReturnedUnitPrice,
            Operand_LastReturnedUnitPrice,
          };
          this.query = params;
        }
        if (myFilter.field == 'lastReturnedPriceSchedule.name') {
          const Filter_LastReturnedPriceScheduleName =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_LastReturnedPriceScheduleName = myFilter.operator;
          const params = {
            ...this.query,
            Filter_LastReturnedPriceScheduleName,
            Operand_LastReturnedPriceScheduleName,
          };
          this.query = params;
        }
        if (myFilter.field == 'expectedPriceSchedule.name') {
          const Filter_ExpectedPriceScheduleName =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ExpectedPriceScheduleName = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ExpectedPriceScheduleName,
            Operand_ExpectedPriceScheduleName,
          };
          this.query = params;
        }
        if (myFilter.field == 'processStatus.name') {
          const Filter_ProcessStatus =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ProcessStatus = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ProcessStatus,
            Operand_ProcessStatus,
          };
          this.query = params;
        }
        if (myFilter.field == 'expectedUnitPrice') {
          const Filter_ExpectedUnitPrice = myFilter.value;
          const Operand_ExpectedUnitPrice = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ExpectedUnitPrice,
            Operand_ExpectedUnitPrice,
          };
          this.query = params;
        }
        if (myFilter.field == 'mismatch') {
          const Filter_Mismatch = myFilter.value;
          const Operand_Mismatch = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Mismatch,
            Operand_Mismatch,
          };
          this.query = params;
        } 
        if (myFilter.field == 'lastExecutionDateTime') {
          const Filter_LastExecutionDateTime = myFilter.value;
          const Operand_LastExecutionDateTime = myFilter.operator;
          const params = {
            ...this.query,
            Filter_LastExecutionDateTime,
            Operand_LastExecutionDateTime,
          };
          this.query = params;
        }
        if (myFilter.field == 'scenarioGroup') {
          const Filter_ScenarioGroup = myFilter.value;
          const Operand_ScenarioGroup = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ScenarioGroup,
            Operand_ScenarioGroup,
          };
          this.query = params;
        }
        if (myFilter.field == 'multiLineTag') {
          const Filter_MultiLineTag = myFilter.value;
          const Operand_MultiLineTag = myFilter.operator;
          const params = {
            ...this.query,
            Filter_MultiLineTag,
            Operand_MultiLineTag,
          };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.pricingScenariosService.getExportList(this.query);
  };
  edit(id: number) {
    this.router.navigate([`/price/pricing-scenario/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.pricingScenariosService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.pricingScenariosService.getList(this.query);
      this.pricingScenarios = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.pricingScenarios,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async execute() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    for (let i = 0; i < ids.length; i++) {
      const pricingScenarioID = ids[i];
      var inputDoc: XMLDocument = (await Promise.resolve(this.createInputXMLDocument(pricingScenarioID)));
      // TODO: Determine why we have to add these single quotes.
      const inputXML: string = "'" + new XMLSerializer().serializeToString(inputDoc.documentElement) + "'";

      // Only show the success message after the last scenario is exeucted and not for each one.
      if (i == ids.length - 1) {
        this.executeGetPrice(inputXML, true);
      }
      else {
        this.executeGetPrice(inputXML, false);
      }

    }
  }
  //          const debitGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(debitGLAccountERPID)));

  async createInputXMLDocument(pricingScenarioID: number): Promise<XMLDocument> {
    var inputDoc: XMLDocument = new Document();

    var documentNode = inputDoc.createElement('Document'); //TODO this is where we add stuff for multi doc lines
    inputDoc.appendChild(documentNode);

    var currentPricingScenario: PricingScenario = await (await Promise.resolve(this.pricingScenariosService.getById(pricingScenarioID)));
    if (currentPricingScenario) {
      var rootNode = inputDoc.createElement('DocumentLine');
      documentNode.appendChild(rootNode);
      var docLineIDNode = inputDoc.createElement('DocumentLineID');
      docLineIDNode.innerHTML = currentPricingScenario.id.toString();
      rootNode.appendChild(docLineIDNode);
      var priceScheduleIDNode = inputDoc.createElement('PriceScheduleID');
      priceScheduleIDNode.innerHTML = currentPricingScenario.priceSchedule?.erpid ?? '';
      rootNode.appendChild(priceScheduleIDNode);
      var customerIDNode = inputDoc.createElement('CustomerID');
      customerIDNode.innerHTML = currentPricingScenario.customer?.erpid ?? '';
      rootNode.appendChild(customerIDNode);
      var itemIDNode = inputDoc.createElement('ItemID');
      itemIDNode.innerHTML = currentPricingScenario.item?.erpid ?? '';
      rootNode.appendChild(itemIDNode);
      var locationIDNode = inputDoc.createElement('LocationID');
      locationIDNode.innerHTML = currentPricingScenario.location?.erpid ?? '';
      rootNode.appendChild(locationIDNode);
      var uofMIDNode = inputDoc.createElement('UofMID');
      uofMIDNode.innerHTML = currentPricingScenario.uofM?.erpid ?? '';
      rootNode.appendChild(uofMIDNode);
      var quantityNode = inputDoc.createElement('Quantity');
      quantityNode.innerHTML = currentPricingScenario.quantity?.toString() ?? '';
      rootNode.appendChild(quantityNode);
      var currencyIDNode = inputDoc.createElement('CurrencyID');
      currencyIDNode.innerHTML = currentPricingScenario.currency?.erpid ?? '';
      rootNode.appendChild(currencyIDNode);
      var dateNode = inputDoc.createElement('Date');
      var useDateString = currentPricingScenario.effectiveDate?.toString() ?? '';
      if (currentPricingScenario.useToday == true) {
        useDateString = new Date().toISOString().slice(0, 10);
      }
      //dateNode.innerHTML = currentPricingScenario.effectiveDate?.toString() ?? '';
      dateNode.innerHTML = useDateString;
      rootNode.appendChild(dateNode);
      var originatingUnitPriceNode = inputDoc.createElement('OriginatingUnitPrice');
      originatingUnitPriceNode.innerHTML = currentPricingScenario.valueFromERP?.toString() ?? '';
      rootNode.appendChild(originatingUnitPriceNode);

      const response: any = await this.pricingScenarioDocumentValuesService.getList({ Filter_PricingScenarioID: currentPricingScenario.id });
      this.pricingScenarioDocumentValues = response.body;
      if (this.pricingScenarioDocumentValues && this.pricingScenarioDocumentValues.length > 0) {
        var documentAttributesNode = inputDoc.createElement('DocumentAttributes');
        rootNode.appendChild(documentAttributesNode);
        for (let i = 0; i < this.pricingScenarioDocumentValues.length; i++) {
          const singleAttribute = this.pricingScenarioDocumentValues[i];
          var singleAttributeNode = inputDoc.createElement('DocumentAttribute');
          singleAttributeNode.setAttribute('name', singleAttribute.documentAttribute.name);
          singleAttributeNode.innerHTML = singleAttribute.value ?? '';
          documentAttributesNode.appendChild(singleAttributeNode);
        }
      }
    }
    return inputDoc;
  }

  async executeGetPrice(inputXML, isLastRecord) {
    try {
      const response: any = await this.toolsService.getPrice(inputXML);
      const status: any = response.status;
      if (status === 200) {
        const parser = new DOMParser();
        const outputDoc = parser.parseFromString(response.body, 'application/xml');
        var documentLineNodes = outputDoc.getElementsByTagName('DocumentLine');
        var documentLines = Array.prototype.slice.call(documentLineNodes);
        for (const currentDocumentLine of documentLines) {
          let currentPricingScenario: PricingScenario;

          //GetPricingScenarioID
          var pricingScenarioID = currentDocumentLine.querySelector('DocumentLineID');
          if (pricingScenarioID.hasChildNodes) {
            var childNode = pricingScenarioID.childNodes[0];
            if (childNode?.nodeValue) {

              currentPricingScenario = await Promise.resolve(this.pricingScenariosService.getById(parseInt(childNode.nodeValue)));
            }
          }

          // Get Price Schedule Name
          var priceScheduleIDNode = currentDocumentLine.querySelector('PriceScheduleID');
          if (priceScheduleIDNode.hasChildNodes) {
            var childNode = priceScheduleIDNode.childNodes[0];
            if (childNode != undefined) {
              var priceScheduleERPID = childNode.nodeValue;
              const priceSchedule: PriceSchedule = await this.getPriceScheduleByERPId(priceScheduleERPID);
              //this.f.returnedPriceScheduleName.patchValue(priceSchedule.name);
              currentPricingScenario.lastReturnedPriceSchedule = priceSchedule;
              currentPricingScenario.lastReturnedPriceScheduleID = priceSchedule?.id;
            }
          }

          // Get Modified Unit Price
          var modifiedUnitPriceNode = currentDocumentLine.querySelector('ModifiedUnitPrice');
          if (modifiedUnitPriceNode.hasChildNodes) {
            var childNode = modifiedUnitPriceNode.childNodes[0];
            if (childNode != undefined) {
              var modifiedUnitPrice = childNode.nodeValue;
              //this.f.returnedUnitPrice.patchValue(modifiedUnitPrice);
              currentPricingScenario.lastReturnedUnitPrice = parseFloat(modifiedUnitPrice ?? '0');
            }
          }

          // Get the PricingDiagnostics Details
          var pricingDiagnosticRunIDNode = currentDocumentLine.querySelector('PricingDiagnosticRunID');
          if (pricingDiagnosticRunIDNode.hasChildNodes) {
            var childNode = pricingDiagnosticRunIDNode.childNodes[0];
            if (childNode != undefined) {
              let pricingDiagnosticRunID = childNode.nodeValue;
              const pricingDiagnostic: PricingDiagnostic = await this.getDiagnosticById(pricingDiagnosticRunID);
              // const runDateTime = this.datePipe.transform(pricingDiagnostic.runDateTime, 'MM/dd/yyyy HH:mm:ss');
              currentPricingScenario.lastExecutionDateTime = pricingDiagnostic.runDateTime;
              currentPricingScenario.lastExecutionDiagnosticID = pricingDiagnosticRunID;
            }
          }

          // Get the Price Was Found
          var priceWasFoundNode = currentDocumentLine.querySelector('PriceWasFound');
          if (priceWasFoundNode.hasChildNodes) {
            var childNode = priceWasFoundNode.childNodes[0];
            if (childNode != undefined) {
              // let priceWasFound = parseInt(childNode.nodeValue);
              currentPricingScenario.processStatus = childNode.nodeValue;
            }
          }

          // Fill in Price Adjustments.
          var priceAdjustmentNodes = currentDocumentLine.querySelector('PriceAdjustments');
          if (priceAdjustmentNodes) {
            // let priceWasFound = parseInt(childNode.nodeValue);
            currentPricingScenario.priceAdjustmentsXML = priceAdjustmentNodes.innerHTML;
            var inputList = Array.prototype.slice.call(priceAdjustmentNodes.innerHTML);
          }

          // var inputList = Array.prototype.slice.call(priceAdjustmentNodes);
          // inputList.forEach(async (value) => {
          //   var currentPriceAdjustment: PriceAdjustment = emptyPriceAdjustment;

          //   const priceadjustmentname = value.childNodes[0].innerHTML;
          //   currentPriceAdjustment.PriceAdjustmentName = priceadjustmentname;

          //   const priceadjustmentamount = value.childNodes[1].innerHTML;
          //   currentPriceAdjustment.PriceAdjustmentAmount = priceadjustmentamount;

          //   const debitGLAccountERPID = value.childNodes[2].innerHTML;
          //   const debitGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(debitGLAccountERPID)));
          //   currentPriceAdjustment.DebitGLAccountName = debitGLAccount.name;
          //   currentPriceAdjustment.DebitGLAccountNumber = debitGLAccount.number;

          //   const creditGLAccountERPID = value.childNodes[3].innerHTML;
          //   const creditGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(creditGLAccountERPID)));
          //   currentPriceAdjustment.CreditGLAccountName = creditGLAccount.name;
          //   currentPriceAdjustment.CreditGLAccountNumber = creditGLAccount.number;

          //   const usercanedit = value.childNodes[4].innerHTML;
          //   if (usercanedit == 1) {
          //     currentPriceAdjustment.UserCanEdit = "Yes";
          //   }
          //   else {
          //     currentPriceAdjustment.UserCanEdit = "No";
          //   }

          //   const adjustmentaffectsmargins = value.childNodes[5].innerHTML;
          //   if (adjustmentaffectsmargins == 1) {
          //     currentPriceAdjustment.AdjustmentAffectsMargins = "Yes";
          //   }
          //   else {
          //     currentPriceAdjustment.AdjustmentAffectsMargins = "No";
          //   }

          //   const adjustmentmodifiesunitprice = value.childNodes[6].innerHTML;
          //   if (adjustmentmodifiesunitprice == 1) {
          //     currentPriceAdjustment.AdjustmentModifiesUnitPrice = "Yes";
          //   }
          //   else {
          //     currentPriceAdjustment.AdjustmentModifiesUnitPrice = "No";
          //   }

          //   this.priceAdjustments.push(currentPriceAdjustment);
          // });
          // this.gridView = {
          //   data: this.priceAdjustments,
          //   total: this.priceAdjustments.length,
          // };
          await this.updatePricingScenario(currentPricingScenario, isLastRecord);

        }
      }

    } catch (e) {
      this.snack.openSnackBar(e.message, '', true, 'Error', 'alert-danger');
    }

    this.loadListData();
  }

  async getPriceScheduleByERPId(erpid) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = erpid;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingPriceSchedules: PriceSchedule[] = await (await this.priceSchedulesService.getList(params)).body;
    if (existingPriceSchedules.length == 1) {
      return existingPriceSchedules[0];
    }
  }

  async getGLAccountByERPId(erpid) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = erpid;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingGLAccounts: GLAccount[] = await (await this.glAccountsService.getList(params)).body;
    if (existingGLAccounts.length == 1) {
      return existingGLAccounts[0];
    }
  }

  async getDiagnosticById(id) {
    return await this.pricingDiagnosticsService.getById(id);
  }

  async updatePricingScenario(pricingScenario: PricingScenario, isLastRecord) {
    if (pricingScenario.id) {
      try {
        const response: any = await this.pricingScenariosService.update(pricingScenario.id, pricingScenario);
        const status: any = response.status;
        if (status === 200) {
          if (isLastRecord == true) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
  }

  async setExpectedToReturnedPrice(modal) {
    const ids = [];
    modal.close();

    for (let i = 0; i < this.mySelection.length; i++) {
      const currentID = this.mySelection[i];
      var currentPricingScenario: PricingScenario = await (await Promise.resolve(this.pricingScenariosService.getById(parseInt(currentID))));
      if (currentPricingScenario) {
        currentPricingScenario.expectedUnitPriceAmount = currentPricingScenario.lastReturnedUnitPrice;
        currentPricingScenario.expectedPriceScheduleID = currentPricingScenario.lastReturnedPriceScheduleID;
        const response: any = await this.pricingScenariosService.update(currentPricingScenario.id, currentPricingScenario)
      }
    }
    this.loadListData();

  }

  async buildActionsMenu(): Promise<void> {
    this.actionMenuItems = [];
    try {
      const response = await this.pricingScenariosService.getList();
      const pricingScenarios = response.body;
      const uniqueScenarioGroups = new Set(
        pricingScenarios
          .filter(scenario => scenario.scenarioGroup)
          .map(scenario => scenario.scenarioGroup)
      );
      const executeGroupMenuItems = Array.from(uniqueScenarioGroups).map(group => ({ text: group, processType: 'group' }));
      const assignGroupMenuItems = Array.from(uniqueScenarioGroups).map(group => ({ text: group, processType: 'assign' }));
      const uniqueMultiLineTags = new Set(
        pricingScenarios
          .filter(scenario => scenario.multiLineTag)
          .map(scenario => scenario.multiLineTag.split('-')[0])  // Extracting the portion before the hyphen
      );
      const multilineMenuItems = Array.from(uniqueMultiLineTags).map(tag => ({ text: tag, processType: 'multiline' }));

      this.actionMenuItems = [
        {
          text: "Actions",
          items: [
            {
              text: "Execute All Test Scenarios",
              processType: 'all',
            },
            {
              text: "Execute Test Scenarios for Group",
              items: executeGroupMenuItems,
            },
            {
              text: "Execute Test Scenarios for MultiLines",
              items: multilineMenuItems,
            },
            {
              text: "Assign Selected Test Scenarios to Group",
              items: assignGroupMenuItems,
            },
          ],
        },
      ];
    } catch (error) {
      console.error("Error fetching or processing PricingScenarios:", error);
    }
    this.loading = false;
  }

  async actionsMenuItemSelect(event: any): Promise<void> {
    const selectedItemProcessType = event.item.processType;
    //console.log('Selected menu item processtype:', selectedItemProcessType);
    if (selectedItemProcessType != null) { //eliminates header action items
      const selectedIdentifier = event.item.text;
      //console.log('Selected menu item selectedIdentifier:', selectedIdentifier);
      if (selectedItemProcessType == 'all') {
        try {
          const params = [
            {
              Name: "@PricingScenarioID",
              Value: 0  //0 means all when calling single
            }
          ];
          const paramList = JSON.stringify(params);
          const response: any = await this.utilitiesService.callStoredProc('usp_RPMProcessScenarioSingle', paramList);
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Test scenario processing complete.' + response.body,
              '',
              false,
              'Success',
              'alert-success',
            );
            this.loadListData();
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      if (selectedItemProcessType == 'group') {
        try {
          const params = [
            {
              Name: "@PricingScenarioGroupName",
              Value: selectedIdentifier
            }
          ];
          const paramList = JSON.stringify(params);
          const response: any = await this.utilitiesService.callStoredProc('usp_RPMProcessScenarioGroup', paramList);
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Test scenario processing complete.' + response.body,
              '',
              false,
              'Success',
              'alert-success',
            );
            this.loadListData();
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      if (selectedItemProcessType == 'multiline') {
        try {
          const params = [
            {
              Name: "@PricingScenarioMultiLineTag",
              Value: selectedIdentifier
            }
          ];
          const paramList = JSON.stringify(params);
          const response: any = await this.utilitiesService.callStoredProc('usp_RPMProcessScenarioMultiLine', paramList);
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Test scenario processing complete.' + response.body,
              '',
              false,
              'Success',
              'alert-success',
            );
            this.loadListData();
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      if (selectedItemProcessType == 'assign') {
        const ids = [];
        this.mySelection.forEach((value) => {
          ids.push(Number(value));
        });
        for (const id of ids) {
          const scenario = await this.pricingScenariosService.getById(id);
          scenario.scenarioGroup = selectedIdentifier;
          await this.pricingScenariosService.update(id, scenario);
        }
      }
      this.onRefresh();
    }
  }
}

