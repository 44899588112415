export interface AttributeMap {
  id: number;
  name: string;
  description: string;
  attributeCount: number;
  entityType1ID: number;
  entityType1: {
    name: string;
    id: number;
  };
  customerAttribute1ID: number;
  customerAttribute1: {
    name: string;
    id: number;
  };
  itemAttribute1ID: number;
  itemAttribute1: {
    name: string;
    id: number;
  };
  documentAttribute1ID: number;
  documentAttribute1: {
    name: string;
    id: number;
  };
  entityType2ID: number;
  entityType2: {
    name: string;
    id: number;
  };
  customerAttribute2ID: number;
  customerAttribute2: {
    name: string;
    id: number;
  };
  itemAttribute2ID: number;
  itemAttribute2: {
    name: string;
    id: number;
  };
  documentAttribute2ID: number;
  documentAttribute2: {
    name: string;
    id: number;
  };
  entityType3ID: number;
  entityType3: {
    name: string;
    id: number;
  };
  customerAttribute3ID: number;
  customerAttribute3: {
    name: string;
    id: number;
  };
  itemAttribute3ID: number;
  itemAttribute3: {
    name: string;
    id: number;
  };
  documentAttribute3ID: number;
  documentAttribute3: {
    name: string;
    id: number;
  };
  defaultMapAmount: number;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyAttributeMap: AttributeMap = {
  id: null,
  name: '',
  description: null,
  attributeCount: null,
  entityType1ID: null,
  entityType1: {
    name: '',
    id: null
  },
  customerAttribute1ID: null,
  customerAttribute1: {
    name: '',
    id: null
  },
  itemAttribute1ID: null,
  itemAttribute1: {
    name: '',
    id: null
  },
  documentAttribute1ID: null,
  documentAttribute1: {
    name: '',
    id: null
  },
  entityType2ID: null,
  entityType2: {
    name: '',
    id: null
  },
  customerAttribute2ID: null,
  customerAttribute2: {
    name: '',
    id: null
  },
  itemAttribute2ID: null,
  itemAttribute2: {
    name: '',
    id: null
  },
  documentAttribute2ID: null,
  documentAttribute2: {
    name: '',
    id: null
  },
  entityType3ID: null,
  entityType3: {
    name: '',
    id: null
  },
  customerAttribute3ID: null,
  customerAttribute3: {
    name: '',
    id: null
  },
  itemAttribute3ID: null,
  itemAttribute3: {
    name: '',
    id: null
  },
  documentAttribute3ID: null,
  documentAttribute3: {
    name: '',
    id: null
  },
  defaultMapAmount: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};


