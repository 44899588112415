import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofM } from '../../../services/uofms/uofms.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UofMSingleComponent } from '../uofm-single/uofm-single.component';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UofMSchedule } from 'src/app/services/uofm-schedules/uofm-schedules.model';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { PricingFilterValueCollectionsService } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.service';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilterValueCollection } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.model';
import { PricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';

@Component({
  selector: 'app-uofm-sublist',
  templateUrl: './uofm-sublist.component.html',
  styleUrls: ['./uofm-sublist.component.scss'],
})
export class UofMSubListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private uofMsService: UofMsService,
    private pricingFilterValuesService: PricingFilterValuesService,
    private pricingFilterValueCollectionsService: PricingFilterValueCollectionsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() uofMs: UofM[] = [];
  @Input() uofMScheduleID: number;
  @Input() itemID: number;
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Input() defaultUofMSchedule: UofMSchedule;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  public isAdmin: boolean;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: UofM[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public globalRecordCount: number = 1;
  public clickedRowItem;
  public gridClassName: string = "UofMSubgridClass";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];  
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Quantity in Base',
      field: 'quantityInBase',
      width: 200,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Is Base',
      field: 'isBase',
      width: 100,
      orderIndex: 2,
      hidden: false,
    }
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    if (await this.isUsedOnPricingFilter(ids, 'UofM')) {
      this.snack.openSnackBar('A UofM or UofMs selected is in use on a Pricing Filter Value.', '', true, 'Error', 'alert-danger');
    }
    else {
      await this.uofMsService
        .removeByIds(ids)
        .then((showSuccess: VoidFunction) => {
          this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
          this.mySelection = [];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.showStatus(err.error, "error");
        });
    }
    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = this.globalRecordCount;
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        if (options.optSiblingRecords) {
          excelComponent.fetchData = this.allGlobalSiblingData;
        }
        else {
          excelComponent.fetchData = this.allData;
        }
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  public allGlobalSiblingData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.uofMsService.getExportList({
      ...this.query
    });
  }

  async getGlobalSiblingDataCount() {
    this.loading = true;
    try {
      const response: any = await this.uofMsService.getList({ PageSize: 1, PageNumber: 1 });
      this.loading = false;
      const pagination = getPaginationHeader(response.headers);
      this.globalRecordCount = pagination.TotalCount;
    } catch (error) {
      this.loading = false;
      console.error("Error fetching total count: ", error);
    }
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('UofMSubList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('UofMSubList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('UofMSubList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save columns configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('UofMSubList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.isAdmin = StorageService.IsAdmin();
    this.loading = true;
    this.ConfigureGrid();   
    this.loading = false;

    if (this.mode == 'view' || this.mode == 'lookup') {
      this.loadListData();
    }
    else if (this.mode == 'subgrid') {
      this.loadSubGridData();
    }
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(
        inputXML,
        'RPMUofM',
      );
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  async onClick_AddNew() {
    this.onCreate();
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.onEdit(this.clickedRowItem);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'uofMSchedule.name') {
          const Filter_UofMSchedule = myFilter.value;
          const Operand_UofMSchedule = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UofMSchedule,
            Operand_UofMSchedule,
          };
          this.query = params;
        }
        if (myFilter.field == 'isBase') {
          const Filter_IsBase = myFilter.value;
          const Operand_IsBase = myFilter.operator;
          const params = { ...this.query, Filter_IsBase, Operand_IsBase };
          this.query = params;
        }
        if (myFilter.field == 'quantityInBase') {
          const Filter_QuantityInBase = myFilter.value;
          const Operand_QuantityInBase = myFilter.operator;
          const params = {
            ...this.query,
            Filter_QuantityInBase,
            Operand_QuantityInBase,
          };
          this.query = params;
        }

        if (myFilter.field == 'erpid') {
          const Filter_ERP = myFilter.value;
          const Operand_ERP = myFilter.operator;
          const params = { ...this.query, Filter_ERP, Operand_ERP };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.uofMScheduleID && !changes.uofMScheduleID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAdd').setAttribute('title', 'Add New');
    }
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.uofMScheduleID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.uofMsService.getList({
          Filter_UofMScheduleID: this.uofMScheduleID,
          ...this.query
        });
        this.uofMs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.uofMs,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount();
  }

  async loadSubGridData() {
    const params = { PageSize: StorageService.PageSize() ?? 50 };
    this.loading = true;
    try {
      if (this.uofMScheduleID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.uofMsService.getList({
          Filter_UofMScheduleID: this.uofMScheduleID,
          ...params,
        });
        this.uofMs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.uofMs,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount();
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.uofMsService.getExportList(this.query);
  };

  //TODO: Is this Obsolete?
  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    if (await this.isUsedOnPricingFilter(ids, 'UofM')) {
      this.snack.openSnackBar('A UofM or UofMs selected is in use on a Pricing Filter Value.', '', true, 'Error', 'alert-danger');
    }
    else {
      await this.uofMsService
        .removeByIds(ids)
        .then((showSuccess: VoidFunction) => {
          this.snack.openSnackBar(
            'Record(s) deleted successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.mySelection = [];
        })
        .catch((err: HttpErrorResponse) => {
          this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        });
    }

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }
  onCreate() {
    const modalRef = this.modalService.open(UofMSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "UofMModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'dialog';
    modalRef.componentInstance.idOfUofMSchedule = this.uofMScheduleID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  onEdit(uofM) {
    const modalRef = this.modalService.open(UofMSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "UofMModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'dialog';
    modalRef.componentInstance.idOfUofM = uofM.id;
    modalRef.componentInstance.idOfUofMSchedule = this.uofMScheduleID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async isUsedOnPricingFilter(values: number[], Filter_EntityName): Promise<boolean> {
    var isUsedOnPricingFilter: boolean = false;

    const PageSize = StorageService.PageSize() ?? 50;
    const Operand_Value = 'eq';
    const Operand_EntityName = 'eq';

    for (var value of values) {
      let Filter_Value: string = value.toString();
      const params = { Filter_Value, Operand_Value, Filter_EntityName, Operand_EntityName, PageSize };
      const existingPricingFilterValues: PricingFilterValue[] = await (
        await this.pricingFilterValuesService.getList(params)
      ).body;
      if (existingPricingFilterValues.length > 0) {
        isUsedOnPricingFilter = true;
        break;
      }
      else {
        //Need to check Pricing Filter Value Collections
        const params = { Filter_Value, Operand_Value, PageSize };
        const existingPricingFilterValueCollections: PricingFilterValueCollection[] = await (
          await this.pricingFilterValueCollectionsService.getList(params)
        ).body;
        if (existingPricingFilterValueCollections.length > 0) {
          for (var pricingFilterValueCollection of existingPricingFilterValueCollections) {
            let currentPFV: PricingFilterValue = await this.pricingFilterValuesService.getById(pricingFilterValueCollection.pricingFilterValueID);
            if (currentPFV.entityID = 73) { //UofM
              isUsedOnPricingFilter = true;
              break;
            }
          }
        }
      }
    }
    return isUsedOnPricingFilter;
  }
}
