import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  model = 'RPMUtilities';
  constructor(private httpClient: HttpClient) { } 

    async callStoredProc(ProcName: string, ProcParams: string = '[]'): Promise < HttpResponse < string >> {
    const url = `${this.model}?ProcName=${ProcName}`;
    return this.httpClient
      .post(url, ProcParams, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text', observe: 'response' })
      .toPromise() as Promise<HttpResponse<string>>;
  }
}


