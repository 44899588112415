import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import {
  PriceSchedule,
  emptyPriceSchedule,
} from '../../../services/price-schedules/price-schedules.model';
import { PriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { PriceScheduleCalculationsService } from '../../../services/price-schedule-calc/price-schedule-calc.service';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { PriceBook } from '../../../services/price-book/price-book.model';
import { PriceFilter } from 'src/app/services/price-filter/price-filter.model';
import { PricingFilter } from 'src/app/services/pricing-filters/pricing-filters.model';
import { RoundingRule } from 'src/app/services/rounding-rules/rounding-rules.model';
import { PriceFilterService } from '../../../services/price-filter/price-filter.service';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { RoundingRulesService } from '../../../services/rounding-rules/rounding-rules.service';
import { PriceBookService } from '../../../services/price-book/price-book.service';
import { debounce, uniqBy } from 'lodash';
import {
  getPaginationHeader,
  mockedData,
} from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { RoundingRuleListComponent } from '../../rounding-rules/rounding-rule-list/rounding-rule-list.component';
import { RoundingRuleLookupComponent } from '../../rounding-rules/rounding-rule-lookup/rounding-rule-lookup.component';
import { PriceScheduleListComponent } from '../../price-schedules/price-schedule-list/price-schedule-list.component';
import { PriceBookListComponent } from '../../price-book/price-book-list/price-book-list.component';
import { PriceBookLookupComponent } from '../../price-book/price-book-lookup/price-book-lookup.component';
import { PriceScheduleCalculationListComponent } from '../../price-schedule-calculations/price-schedule-calculation-list/price-schedule-calculation-list.component';
import { PricingFilterListComponent } from '../../pricing-filters/pricing-filter-list/pricing-filter-list.component';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import {
  PriceScheduleCalculation,
  emptyPriceScheduleCalculation,
} from 'src/app/services/price-schedule-calc/price-schedule-calc.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { PriceScheduleLookupComponent } from '../price-schedule-lookup/price-schedule-lookup.component';
import { CopyService } from '../../../services/copy/copy.service';
import { v4 as uuidv4 } from 'uuid';

enum Filters {
  PriceFilter = 1,
  PriceBook = 2,
  PriceSource = 3,
  RoundingRule = 4,
  CalculateAfterPrice = 5,
  PricingFilter = 6,
}
@Component({
  selector: 'app-price-schedule-single',
  templateUrl: './price-schedule-single.component.html',
  styleUrls: ['./price-schedule-single.component.scss'],
})
export class PriceScheduleSingleComponent implements OnInit {
  @ViewChild('subgrid') priceScheduleCalculationsSubGrid: PriceScheduleCalculationListComponent;

  filters = Filters;
  idOfPriceSchedule: number | null = null;
  priceSchedule: PriceSchedule = emptyPriceSchedule;
  priceCalculations: PriceCalculation[] = [];
  loading: boolean = true;
  priceScheduleForm: FormGroup;
  priceBooks: PriceBook[] = [];
  @Output()
  onFormChanged = new EventEmitter<Boolean>();

  priceSourceOptions: Lookup[] = [];
  priceScheduleCalculations: PriceScheduleCalculation[] = [];
  priceFilters: PriceFilter[] = [];
  pricingFilters: PricingFilter[] = [];
  roundingRules: RoundingRule[] = [];
  calculateAfterPriceSchedules: PriceSchedule[] = [];
  priceSourceOptionID: number;
  // priceSources: PriceSource[] = [];
  //priceSources: Lookup[] = [];
  submitted = false;
  modalReference: any;
  useDynamicPricing: boolean = false;
  showPriceBook: boolean = false;
  showCalcAfterPriceSched: boolean = false;
  pagination_roundingRules: any = { roundingRules: mockedData };
  pagination_calculateAfterPriceSchedules: any = {
    calculateAfterPriceSchedules: mockedData,
  };
  pagination_priceBooks: any = { priceBooks: mockedData };
  pagination_priceFilter: any = { priceFilters: mockedData };
  pagination_pricingFilter: any = { pricingFilters: mockedData };
  pagination_priceCalculations: any = { priceCalculations: mockedData };
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  selectedPriceSourceOption: Lookup = emptyLookup;
  constructor(
    private route: ActivatedRoute,
    private priceSchedulesService: PriceSchedulesService,
    private priceFilterService: PriceFilterService,
    private pricingFilterService: PricingFiltersService,
    private priceBookService: PriceBookService,
    private roundingRuleService: RoundingRulesService,
    public fb: FormBuilder,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private router: Router,
    private priceScheduleCalcService: PriceScheduleCalculationsService,
    private sideNavService: SideNavService,
    private copyService: CopyService,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.priceSchedule = emptyPriceSchedule;
        this.idOfPriceSchedule = null;
        this.submitted = false;
        this.buildForm();
        this.updateForm();
        //this.setDefaultPriceSource();
        this.priceScheduleCalculationsSubGrid.priceScheduleID = null;
        this.priceScheduleCalculationsSubGrid.loadListData();
        this.priceSourceOptionChanged(this.priceSchedule.priceSourceOptionID);
        this.onDynamicPricingChanged();
        this.isFormChanged = false;
        return;
      }
      this.idOfPriceSchedule = params.id;
      this.loadData();
    });
    this.buildForm();

    this.roundingRuleSearch = debounce(this.roundingRuleSearch, 400, {});
    this.priceBookSearch = debounce(this.priceBookSearch, 400, {});
    //this.priceFilterSearch = debounce(this.priceFilterSearch, 400, {});
    this.pricingFilterSearch = debounce(this.pricingFilterSearch, 400, {});
    this.priceScheduleSearch = debounce(this.priceScheduleSearch, 400, {});
    // this.search = debounce(this.search, 400);

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.priceScheduleCalculationsSubGrid.gridClassName = "PriceCalculationSubgridClass"
        }
        else {
          this.priceScheduleCalculationsSubGrid.gridClassName = "PriceCalculationFullSubgridClass"
        }
      },
    );

    this.isFormChanged = false;
  }

  ngOnInit(): void {
    // if new record, default erpid field to new GUID
    if (!this.idOfPriceSchedule) {
      this.priceScheduleForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
      this.isFormChanged = false;
    }
  }

  buildForm() {
    this.priceScheduleForm = this.fb.group({
      name: new FormControl('', Validators.required),
      useInDynamicPricing: new FormControl(false),
      calculateAfterPriceScheduleID: new FormControl(null, this.calcAfterPriceScheduleValidator),
      dynamicPricingRank: new FormControl(null, this.dynamicRankValidator),
      priceBookID: new FormControl(null, this.priceBookValidator),
      pricingFilterID: new FormControl(),
      priceSourceOptionID: new FormControl('', Validators.required),
      roundingRuleID: new FormControl(),
      maxMinPrice: new FormControl(''),
      isHighestPrice: new FormControl(false),
      isActive: new FormControl(true),
      isLowestPrice: new FormControl(false),
      erpid: new FormControl({ value: '', disabled: true }, Validators.required),
    });
    this.getPriceSourceOptions();

    this.priceScheduleForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  get f() {
    return this.priceScheduleForm.controls;
  }

  async getPriceSourceOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'PriceSource';
    const params = { Filter_OptionSet, PageSize };
    this.priceSourceOptions = await this.lookupService.getList(params);
    return this.priceSourceOptions;
  }

  updateForm() {
    this.priceScheduleForm.patchValue({ ...this.priceSchedule });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.priceSchedule.id);
    await this.priceSchedulesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/price-schedules']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/price-schedule/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/price-schedule/new']);
      });
    }
    //    this.router.navigate(['/price/price-schedule/new'], { replaceUrl: true });
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPriceSchedule", this.priceSchedule.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.priceSchedule && this.priceSchedule.id) {
      return false;
    }
    else {
      return true;
    }
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async validation_CheckForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.priceScheduleForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingPriceSchedules: PriceSchedule[] = await (
      await this.priceSchedulesService.getList(params)
    ).body;
    if (existingPriceSchedules.length > 0) {
      if (this.priceSchedule.id) {
        for (const key in existingPriceSchedules) {
          const currentPriceSchedule: PriceSchedule = existingPriceSchedules[key];
          if (currentPriceSchedule.id != this.priceSchedule.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.priceScheduleForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingPriceSchedules: PriceSchedule[] = await (
      await this.priceSchedulesService.getList(params)
    ).body;
    if (existingPriceSchedules.length > 0) {
      if (this.priceSchedule.id) {
        for (const key in existingPriceSchedules) {
          const currentPriceSchedule: PriceSchedule = existingPriceSchedules[key];
          if (currentPriceSchedule.id !== this.priceSchedule.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.priceScheduleForm.markAllAsTouched();
    this.submitted = true;
    if (this.priceScheduleForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.priceSchedule,
      ...this.priceScheduleForm.getRawValue() // priceScheduleForm.value does not include disabled controls.
    };

    // Check to see if Use Dynamic and Find Dynamically are both checked
    if ((this.priceSourceOptionID == 84) && (this.useDynamicPricing == true)) { 
      this.snack.openSnackBar(
        'Price Source Option of "Find Dynamically" is incompatible with "Use in Dynamic Pricing"',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }
    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.snack.openSnackBar(
        'ERP ID already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }
    // Check to see if Price Schedule Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Price Schedule Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.priceSchedule.id) {        
        try {
          const response: any = await this.priceSchedulesService.update(
            this.priceSchedule.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.priceScheduleCalculationsSubGrid.saveAllPriceSchedules();
            if (action == "Close") {
              this.router.navigate(['/price/price-schedules']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/price-schedule/new']);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }

      } else {
        try {
          const response: any = await this.priceSchedulesService.create(
            this.priceScheduleForm.getRawValue() // priceScheduleForm.value does not include disabled controls.
          );
          const status: any = response.status;
          if (status === 201) {
            this.priceSchedule = response.body;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close") {
              this.router.navigate(['/price/price-schedules']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/price-schedule/new']);
              });
            }
            else {
              this.priceSchedule = response.body;
              this.idOfPriceSchedule = this.priceSchedule.id;
              this.router.navigate(['/price/price-schedule/', this.priceSchedule.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }
  async getPriceScheduleById() {
    if (this.idOfPriceSchedule) {
      return await this.priceSchedulesService.getById(this.idOfPriceSchedule);
    } else {
      return emptyPriceSchedule;
    }
  }

  async getPriceCalculations(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.priceScheduleCalcService.getList({ Filter_PriceScheduleID: this.idOfPriceSchedule, ...params, PageSize });
      this.priceScheduleCalculations = resp.body;
      this.priceCalculations = this.priceScheduleCalculations.map((psc) => {
        return <PriceCalculation>{
          id: psc.priceCalculation.id,
          name: psc.priceCalculation.name,
          priceScheduleCalculationID: psc.id,
          priceModeOptionID: psc.priceCalculation.priceModeOptionID,
          priceModeOption: psc.priceCalculation.priceModeOption,
          priceAmount: psc.priceCalculation.priceAmount,
          pricePercent: psc.priceCalculation.pricePercent,
          convertToUofM: psc.priceCalculation.convertToUofM,
          perUofMName: psc.priceCalculation.perUofMName,
          roundingRuleID: psc.priceCalculation.roundingRuleID,
          roundingRule: psc.priceCalculation.roundingRule,
          modifyUnitPrice: psc.priceCalculation.modifyUnitPrice,
          trackAdjustment: psc.priceCalculation.trackAdjustment,
          debitGLAccountID: psc.priceCalculation.debitGLAccountID,
          debitGLAccount: psc.priceCalculation.debitGLAccount,
          creditGLAccountID: psc.priceCalculation.creditGLAccountID,
          creditGLAccount: psc.priceCalculation.creditGLAccount,
          userCanEdit: psc.priceCalculation.userCanEdit,
          affectsMarginCalc: psc.priceCalculation.affectsMarginCalc,
          pricingFilterID: psc.priceCalculation.pricingFilterID,
          pricingFilter: psc.priceCalculation.pricingFilter,
          trackAdjustmentExtended: psc.priceCalculation.trackAdjustmentExtended,
          createdOn: psc.priceCalculation.createdOn,
          createdBy: psc.priceCalculation.createdBy,
          modifiedOn: psc.priceCalculation.modifiedOn,
          modifiedBy: psc.priceCalculation.modifiedBy,
        };
      });
      this.pagination_priceCalculations = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.priceCalculations = [];
    }

    return this.priceCalculations;
  }

  async loadData() {
    this.loading = true;
    try {
      //////////
      if (this.idOfPriceSchedule) {
        this.priceSchedule = await Promise.resolve(this.getPriceScheduleById());

        if (this.priceSchedule.calculateAfterPriceScheduleID) {
          const currentCalcAfter: PriceSchedule = await Promise.resolve(await this.priceSchedulesService.getById(this.priceSchedule.calculateAfterPriceScheduleID));
          this.calculateAfterPriceSchedules.push(currentCalcAfter);
          this.priceSchedule.calculateAfterPriceSchedule = currentCalcAfter;
        }

        if (this.priceSchedule.priceBookID) {
          const currentPriceBook: PriceBook = await Promise.resolve(await this.priceBookService.getById(this.priceSchedule.priceBookID));
          this.priceBooks.push(currentPriceBook);
          this.priceSchedule.priceBook = currentPriceBook;
        }

        if (this.priceSchedule.pricingFilterID) {
          const currentPricingFilter: PricingFilter = await Promise.resolve(await this.pricingFilterService.getById(this.priceSchedule.pricingFilterID));
          this.pricingFilters.push(currentPricingFilter);
          this.priceSchedule.pricingFilter = currentPricingFilter;
        }

        if (this.priceSchedule.roundingRuleID) {
          const currentRoundingRule: RoundingRule = await Promise.resolve(await this.roundingRuleService.getById(this.priceSchedule.roundingRuleID));
          this.roundingRules.push(currentRoundingRule);
          this.priceSchedule.roundingRule = currentRoundingRule;
        }
        this.updateForm();
      }

      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
    // Refresh field layouts.
    if (this.priceSchedule.priceSourceOptionID != null) {
      this.priceSourceOptionChanged(this.priceSchedule.priceSourceOptionID);
    }
    this.onDynamicPricingChanged();
    this.isFormChanged = false;
  }

  priceSourceOptionChanged(priceSourceOptionID) {
    this.priceSourceOptionID = priceSourceOptionID;
    switch (priceSourceOptionID) {
      case 37: //Price Book
        this.priceScheduleForm.patchValue({ ['calculateAfterPriceScheduleID']: null });
        this.priceSchedule.calculateAfterPriceScheduleID = null;
        this.priceScheduleForm.get('priceBookID').enable()
        this.priceScheduleForm.get('calculateAfterPriceScheduleID').disable()
        break;
      case 40://Another Price Schedule
        this.priceScheduleForm.patchValue({ ['priceBookID']: null });
        this.priceSchedule.priceBookID = null;
        this.priceScheduleForm.get('priceBookID').disable()
        this.priceScheduleForm.get('calculateAfterPriceScheduleID').enable()
        break;
      default:
        this.priceScheduleForm.patchValue({ ['priceBookID']: null });
        this.priceSchedule.priceBookID = null;
        this.priceScheduleForm.patchValue({ ['calculateAfterPriceScheduleID']: null });
        this.priceScheduleForm.get('priceBookID').disable()
        this.priceScheduleForm.get('calculateAfterPriceScheduleID').disable()
        break;
    }
  }

  async pricingFilterSearch(params) {
    try {
      this.pricingFilters = (await this.pricingFilterService.getList(params)).body;
    } catch (e) {
      this.pricingFilters = [];
    }
    return this.pricingFilters;
  }

  async roundingRuleSearch(params) {
    try {
      this.roundingRules = (await this.roundingRuleService.getList(params)).body;
    } catch (e) {
      this.roundingRules = [];
    }
    return this.roundingRules;
  }

  async priceBookSearch(params) {
    try {
      this.priceBooks = (await this.priceBookService.getList(params)).body;
    } catch (e) {
      this.priceBooks = [];
    }
    return this.priceBooks;
  }

  async priceScheduleSearch(params) {
    try {
      this.calculateAfterPriceSchedules = (await this.priceSchedulesService.getList(params)).body;
    } catch (e) {
      this.calculateAfterPriceSchedules = [];
    }
    return this.calculateAfterPriceSchedules;

    // const SearchTerm = event.term; // or Filter_Name


    //     try {
    //   const resp = await this.priceSchedulesService.getList({
    //     ...params,
    //   });
    //   // @ts-ignore
    //   this.calculateAfterPriceSchedules = resp.body;
    //   this.pagination_calculateAfterPriceSchedules.calculateAfterPriceSchedules = getPaginationHeader(
    //     // @ts-ignore
    //     resp.headers,
    //   );
    // } catch (e) {
    //   this.calculateAfterPriceSchedules = [];
    // }

    // return this.calculateAfterPriceSchedules;


    // return await this.getCalculationAfterPriceSchedules({ SearchTerm });
  }

  async onSelectPriceCalculation(event, arr) {
    const newPSC: PriceScheduleCalculation = emptyPriceScheduleCalculation;
    newPSC.priceScheduleID = this.idOfPriceSchedule;
    newPSC.priceCalculationID = event.id;
    newPSC.rank = this.priceScheduleCalculations.length + 1;
    // Remove the ID since this is a create.
    delete newPSC.id;
    try {
      // Create Price Schedule Calculation record.
      const response: any = await this.priceScheduleCalcService.create(newPSC);
      const status: any = response.status;
      if (status === 201) {
        // Refresh Grid
        this.getPriceCalculations(null);
      }
    } catch (e) {
      const err = e.error();
    }
  }

  onDynamicPricingChanged() {
    if (this.priceScheduleForm.get('useInDynamicPricing').value == true) {
      this.priceScheduleForm.get('dynamicPricingRank').enable()
      this.useDynamicPricing = true;
    } else {
      this.priceScheduleForm.get('dynamicPricingRank').disable()
      this.useDynamicPricing = false;
      this.priceSchedule.dynamicPricingRank = null;
      this.priceScheduleForm.controls['dynamicPricingRank'].patchValue(null);

    }
  }

  onUseInDynamicChange(value) {
    if (value) {
      this.priceScheduleForm.get('dynamicPricingRank').enable()
      this.useDynamicPricing = true;
    }
    else {
      this.priceScheduleForm.get('dynamicPricingRank').disable()
      this.useDynamicPricing = false;
      this.priceSchedule.dynamicPricingRank = null;
      this.priceScheduleForm.controls['dynamicPricingRank'].patchValue(null);
    }
  }

  onSelectBoxChanged(id: number, key: keyof PriceSchedule, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.priceSchedule[key] = item;

    // if (key == 'priceSourceOptionID') {
    //   this.priceSourceOptionChanged(id);
    // }
  }

  onMaxMinBoxChange(value, field: string) {
    if (value?.target?.checked) {
      switch (field) {
        case "isHighestPrice":
          this.priceScheduleForm.patchValue({ ['isLowestPrice']: false });
          break;
        case "isLowestPrice":
          this.priceScheduleForm.patchValue({ ['isHighestPrice']: false });
          break;
        default:
          break;
      }
    }
  }
    
  async getRoundingRules(params) {
    try {
      const resp = await this.roundingRuleService.getList({
        ...params,
      });
      // @ts-ignore
      this.roundingRules = resp.body;
      this.pagination_roundingRules.roundingRules = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.roundingRules = [];
    }
    return this.roundingRules;
  }

  async onLookup_RoundingRule() {
    const modalRef = this.modalService.open(RoundingRuleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: RoundingRule = receivedEntry;
      this.roundingRules = [];
      this.roundingRules.push(recordSelected);
      this.priceScheduleForm.patchValue({ ['roundingRuleID']: receivedEntry.id });
      this.priceSchedule.roundingRule = receivedEntry;
      modalRef.close('test');
    });
  }

  async getCalculationAfterPriceSchedules(params) {
    try {
      const resp = await this.priceSchedulesService.getList({
        ...params,
      });
      // @ts-ignore
      this.calculateAfterPriceSchedules = resp.body;
      this.pagination_calculateAfterPriceSchedules.calculateAfterPriceSchedules = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.calculateAfterPriceSchedules = [];
    }

    return this.calculateAfterPriceSchedules;
  }

  async onLookup_CalculateAfterPriceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.calculateAfterPriceSchedules = [];
      this.calculateAfterPriceSchedules.push(recordSelected);
      this.priceScheduleForm.patchValue({ ['calculateAfterPriceScheduleID']: receivedEntry.id });
      this.priceSchedule.calculateAfterPriceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async getPriceBooks(params) {
    try {
      const resp = await this.priceBookService.getList({
        ...params,
      });
      // @ts-ignore
      this.priceBooks = resp.body;
      this.pagination_priceBooks.priceBooks = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.priceBooks = [];
    }

    return this.priceBooks;
  }

  async onLookup_PriceBook() {
    const modalRef = this.modalService.open(PriceBookLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PriceBookModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PriceBookDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceBook = receivedEntry;
      this.priceBooks = [];
      this.priceBooks.push(recordSelected);
      this.priceScheduleForm.patchValue({ ['priceBookID']: receivedEntry.id });
      this.priceSchedule.priceBook = receivedEntry;
      modalRef.close('test');
    });
  }

  async getPricingFilters(params) {
    try {
      const resp = await this.pricingFilterService.getList({
        ...params,
      });
      // @ts-ignore
      this.pricingFilters = resp.body;
      this.pagination_pricingFilter.pricingFilters = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.pricingFilters = [];
    }

    return this.pricingFilters;
  }

  async onLookup_PricingFilter() {
    const modalRef = this.modalService.open(PricingFilterLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PricingFilterModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PricingFilterDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingFilter = receivedEntry;
      this.pricingFilters = [];
      this.pricingFilters.push(recordSelected);
      this.priceScheduleForm.patchValue({ ['pricingFilterID']: receivedEntry.id });
      this.priceSchedule.pricingFilter = receivedEntry;
      modalRef.close('test');
    });
  }

  onSelectSingleItem(item, itemName, arr) {
    this.priceScheduleForm.patchValue({ [itemName]: item.id });
    this.modalReference.close('test');
  }
  filterChange(event, arr) {
    this.getPriceCalculations(event);
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      // case Filters.PriceFilter:
      //   // @ts-ignore
      //   this.priceFilters = this.getPriceFilters(params);
      //   break;
      case Filters.PricingFilter:
        // @ts-ignore
        this.pricingFilters = this.getPricingFilters(params);
        break;
      case Filters.PriceBook:
        // @ts-ignore
        this.priceBooks = this.getPriceBooks(params);
        break;
      case Filters.RoundingRule:
        // @ts-ignore
        this.roundingRules = this.getRoundingRules(params);
        break;
      case Filters.CalculateAfterPrice:
        // @ts-ignore
        this.calculateAfterPriceSchedules = this.getCalculationAfterPriceSchedules(
          params,
        );
        break;
      default:
        break;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  };

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  };

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  priceBookValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('priceSourceOptionID') && formControl.parent.get('priceSourceOptionID').value == 37) {
      return Validators.required(formControl);
    }
    return null;
  }

  calcAfterPriceScheduleValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('priceSourceOptionID') && formControl.parent.get('priceSourceOptionID').value == 40) {
      return Validators.required(formControl);
    }
    return null;
  }

  dynamicRankValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('useInDynamicPricing')?.value) {
      return Validators.required(formControl);
    }
    return null;
  }
}
