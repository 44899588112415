export interface PricingDiagnostic {
    id: number;
    runDateTime: Date;
    resultData: string;
    resultSummary: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyPricingDiagnostic: PricingDiagnostic = {
    id: null,
    runDateTime: null,
    resultData: '',
    resultSummary: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
