<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Calculation.png" alt="Price Calculations" style="padding-right: 5px;">
  Price Calculations</div>
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [sort]="query.SortTerm"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate *ngIf="mode == 'view'">
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" [routerLink]="['/price/price-calculation/new']" title="Add New">Add New</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">Edit</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">Export</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()" title="Import from Excel">Import</button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>

  <kendo-grid-column field="name" title="Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'" [routerLink]="['/price/price-calculation/', dataItem.id]"> {{ dataItem.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceModeOption.name" title="Price Mode" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <price-calculation-price-mode-filter [filter]="filter" [data]="priceCalculationPriceModeOptions"
                                           textField="name" valueField="name">
      </price-calculation-price-mode-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="modifyUnitPrice" filter="boolean" title="Modify Unit Price" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.modifyUnitPrice ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="fromTheBaseStartingPrice" filter="boolean" title="From The Base Starting Price" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.fromTheBaseStartingPrice ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>fromTheBaseStartingPrice

  <kendo-grid-column field="priceAmount" format="##############.00000" title="Amount" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="pricePercent" format="##############.00000" title="Percent" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="pricingFilter.name" title="Pricing Filter" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.pricingFilter">
        {{ dataItem.pricingFilter.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="pricingCondition.name" title="Pricing Condition" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.pricingCondition"> {{ dataItem.pricingCondition.name }} </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="roundingRule.name" title="Rounding Rule" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.roundingRule">
        {{ dataItem.roundingRule.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <!--
    <kendo-grid-column field="useValueFromAttribute" filter="boolean" title="Use Value from Attribute" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.useValueFromAttribute ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="entity.name" title="Entity" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <entity-type-filter [filter]="filter" [data]="priceCalculationEntity" textField="name" valueField="name">
      </entity-type-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="customerAttribute.name" title="Customer Attribute" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.customerAttribute">
        {{ dataItem.customerAttribute.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="itemAttribute.name" title="Item Attribute" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.itemAttribute">
        {{ dataItem.itemAttribute.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="documentAttribute.name" title="Document Attribute" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.documentAttribute">
        {{ dataItem.documentAttribute.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceBook.name" title="Price Book" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.priceBook">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/price-book/', dataItem.priceBook.id]">
          {{ dataItem.priceBook.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)">
          {{ dataItem.priceBook.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="attributeMap.name" title="Attribute Map" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.attributeMap">
        <a *ngIf="mode == 'view'" [routerLink]="['/price/attribute-map/', dataItem.attributeMap.id]">
          {{ dataItem.attributeMap.name }}
        </a>
        <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)">
          {{ dataItem.attributeMap.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="convertToUofM" filter="boolean" title="Convert To UofM" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.convertToUofM ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="perUofMName" title="Per UofM Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="trackAdjustmentExtended" filter="boolean" title="Track Adjustment Extended" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.trackAdjustmentExtended ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="trackAdjustment" filter="boolean" title="Track Adjustment" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.trackAdjustment ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="debitGLAccount.name" title="Debit GL Account" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.debitGLAccount">
        {{ dataItem.debitGLAccount.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="creditGLAccount.name" title="Credit GL Account" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.creditGLAccount">
        {{ dataItem.creditGLAccount.name }}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="userCanEdit" filter="boolean" title="User Can Edit" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.userCanEdit ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="affectsMarginCalc" filter="boolean" title="Affects Margin Calc" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.affectsMarginCalc ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="abortIfValueNotFound" filter="boolean" title="Abort If Value Not Found" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.abortIfValueNotFound ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>
    -->

  <kendo-grid-excel #exportToExcel fileName="PriceCalculations.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceModeOption.name" title="PriceModeName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="modifyUnitPrice" title="ModifyUnitPrice" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="fromTheBaseStartingPrice" title="FromTheBaseStartingPrice" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceAmount" title="PriceAmount" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="pricePercent" title="PricePercent" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="pricingFilter.name" title="PricingFilterName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="pricingCondition.name" title="PricingConditionName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="roundingRule.name" title="RoundingRuleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="useValueFromAttribute" title="UseValueFromAttribute" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="entity.name" title="EntityName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="customerAttribute.name" title="CustomerAttributeName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="itemAttribute.name" title="ItemAttributeName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="documentAttribute.name" title="DocumentAttributeName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceBook.name" title="PriceBookName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="attributeMap.name" title="AttributeMapName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="convertToUofM" title="ConvertToUofM" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="perUofMName" title="PerUnitOfMeasureName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="trackAdjustmentExtended" title="TrackAdjustmentExtended" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="trackAdjustment" title="TrackAdjustment" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="debitGLAccount.number" title="DebitGLAccountNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="debitGLAccount.name" title="DebitGLAccountName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="creditGLAccount.number" title="CreditGLAccountNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="creditGLAccount.name" title="CreditGLAccountName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="userCanEdit" title="UserCanEdit" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="affectsMarginCalc" title="AffectsMarginCalculations" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="abortIfValueNotFound" title="AbortIfValueNotFound" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
