export interface QuantityBreak {
    id: number;
    quantityFromCount: number;
    priceAmount: number;
    priceSheetDetailID: number;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyQuantityBreak: QuantityBreak = {
    id: null,
    quantityFromCount: null,
    priceAmount: null,
    priceSheetDetailID: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
