import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MySnackbarComponent } from './my-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class MySnackBarService {
  constructor(private snackBar: MatSnackBar) { }
  public openSnackBar(message: string, action: string, displayClose: boolean, snackType?: string, panelClass?: string) {
    const _snackType: string =
      snackType !== '' ? snackType : 'Success';

    const _panelClass: string =
      panelClass !== '' ? panelClass : 'alert-primary';

    const _duration: number =
      displayClose === true ? 0 : 8000;

    // Display Success messages at the Bottom and all others (IE-Errors) at the Top
    if (_snackType == "Success") {
      this.snackBar.openFromComponent(MySnackbarComponent, {
        duration: _duration,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: _panelClass,
        data: { message: message, snackType: _snackType, showClose: displayClose, snackBar: this.snackBar }
      });
    }
    else {
      this.snackBar.openFromComponent(MySnackbarComponent, {
        duration: _duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: _panelClass,
        data: { message: message, snackType: _snackType, showClose: displayClose, snackBar: this.snackBar }
      });
    }
  }
}
