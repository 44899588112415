<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Customer_Attributes.png" alt="Customer Attributes" style="padding-right: 5px;">
      Create / Update Customer Attribute
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="customerAttributeForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control"
                         formControlName="name"
                         placeholder="Enter Customer Attribute Name"
                         maxlength="100" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="attributeDataTypeID" class="font-weight-bold" text="* Data Type"></kendo-label>
          <ng-select id="attributeDataType"
                     [multiple]="false"
                     [selectOnTab]="true"
                     formControlName="attributeDataTypeID"
                     [notFoundText]="'Please start typing to search'"
                     [ngClass]="{
          'is-invalid': submitted && f.attributeDataTypeID.errors,
          'w-100': true
        }"
                     placeholder="Select a Data Type"
                     [searchable]="true" appendTo="body"
                     (change)="onSelectBoxChanged($event, 'attributeDataType', attributeDataTypes)">
            <ng-option *ngFor="let item of attributeDataTypes" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-formerror>Data Type is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="description" text="Description"></kendo-label>
          <textarea class="form-control"
                    formControlName="description"
                    placeholder="Enter Description"
                    maxlength="200"
                    [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
