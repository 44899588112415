import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'document-attribute-data-type-filter',
    template: `
    <kendo-dropdownlist
      [data]="data"
      (valueChange)="onChange($event)"      
      [value]="selectedValue"
      [valuePrimitive]="true"
      [textField]="textField"
      [valueField]="valueField">
    </kendo-dropdownlist>
  `
})
export class DocumentAttributeDataTypeFilterComponent extends BaseFilterCellComponent {

    public get selectedValue(): any {
        const filter = this.filterByField(this.valueField);
        return filter ? filter.value : null;
    }

    @Input() public filter: any;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public valueField: string;

    constructor(filterService: FilterService) {
        super(filterService);
    }

    public onChange(value: any): void {
        this.applyFilter(
            this.updateFilter({ // add a filter for the field with the value
                field: "attributeDataType.name", // this.valueField,
                operator: 'eq',
                value: value
            })
        ); // update the root filter
    }
}
