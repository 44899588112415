import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttributeMapsService } from '../../../services/attribute-maps/attribute-maps.service';
import { AttributeMap } from '../../../services/attribute-maps/attribute-maps.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service'
import { StorageService } from '../../../../app/utils/StorageHelper';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-attribute-map-list',
  templateUrl: './attribute-map-list.component.html',
  styleUrls: ['./attribute-map-list.component.scss'],
})
export class AttributeMapListComponent implements OnInit {
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private attributeMapsService: AttributeMapsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService,
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() attributeMaps: AttributeMap[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: AttributeMap[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc'
    },
    {
      field: 'Description',
      dir: 'asc'
    },
  ];
  
  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.attributeMapsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMAttributeMap", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully! Please refresh the grid to see results.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  ngOnInit(): void {
    this.loadListData();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {          
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );

  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/attribute-map/' + this.clickedRowItem.id]);
      }
    }
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(inputXML, 'RPMAttributeMap');
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;

        //mhr this code ChatGPT made more universal
        const filterValue = myFilter.value || "";
        const filterField = `Filter_${myFilter.field}`;
        const operandField = `Operand_${myFilter.field}`;
        const params = { ...this.query, [filterField]: filterValue, [operandField]: myFilter.operator };
        this.query = params;

      //  if (myFilter.field == 'name') {
      //    const Filter_Name = myFilter.value;
      //    const Operand_Name = myFilter.operator;
      //    const params = { ...this.query, Filter_Name, Operand_Name };
      //    this.query = params;
      //  }
      });
    }
    this.onFilterChange();
  }
  edit(id: number) {
    this.router.navigate([`/price/attribute-map/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.attributeMapsService.getExportList(this.query);
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.attributeMapsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }
  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.attributeMapsService.getList(this.query);
      this.attributeMaps = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.attributeMaps,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
