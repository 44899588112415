<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Customer_Attributes.png" alt="Document Attribute Values" style="padding-right: 5px;">
      Create / Update Document Attribute Value
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="pricingScenarioDocumentValueForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="documentAttributeID" class="font-weight-bold" text="* Document Attribute"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="documentAttributeID" class="noarrow" [multiple]="false" [required]="true" [selectOnTab]="true"
                       formControlName="documentAttributeID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.documentAttributeID.errors,
                    'w-100': true
                  }" placeholder="Select a Document Attribute" [searchable]="true" (search)="search($event, filters.DocumentAttribute)">
              <ng-option *ngFor="let item of documentAttributes" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_DocumentAttribute()" icon="search"></button>
          </span>
          <kendo-formerror>Document Attribute is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="value" placeholder="Enter a Value">
          </kendo-textbox>
          <kendo-formerror>Value is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
