<kendo-dialog [minWidth]="850" (close)="onClick_Close('Cancel')" [width]="1050" [height] ="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Customer.png" alt="Customers in Filter" style="padding-right: 5px;">
      Customers in Filter
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
              [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" style="height:500px;"
              [resizable]="true">

    <kendo-grid-column field="name" title="Name" width="300"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="number" title="Number" width="300"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    </kendo-grid-column>
    <kendo-grid-column field="erpid" title="ERP ID" width="300"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    </kendo-grid-column>
  </kendo-grid>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-close-outline"></span>Close</button>
  </kendo-dialog-actions>
  </kendo-dialog>
