<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="1050" [height]="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Price_Schedule.png" alt="Price Schedule" style="padding-right: 5px;">
      Price Schedules Lookup
    </div>
  </kendo-dialog-titlebar>
  <kendo-grid #grid="kendoGrid"
              [data]="gridView"
              [pageSize]="query.PageSize"
              [skip]="skip"
              [reorderable]="true"
              [loading]="loading"
              [pageable]="true"
              (pageChange)="pageChange($event)"
              [filter]="filter"
              [filterable]="filterable"
              (filterChange)="gridFilterChange($event)"
              [resizable]="true"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              [selectable]="{ mode: 'multiple' }"
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              (cellClick)="onCellClick($event)"
              (dblclick)="onDblClick()"
              [class]="gridClassName">
    <ng-template kendoGridToolbarTemplate>
      <kendo-buttongroup>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      </kendo-buttongroup>
      <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
    </ng-template>
    <kendo-grid-column field="name" title="Name" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="boolean"
                       field="useInDynamicPricing"
                       title="Use In Dynamic Pricing"
                       width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <kendo-grid-messages filterIsTrue="Yes"
                           filterIsFalse="No">
      </kendo-grid-messages>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.useInDynamicPricing ? 'Yes' : 'No'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dynamicPricingRank"
                       title="Dynamic Pricing Rank"
                       width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-lt-operator></kendo-filter-lt-operator>
          <kendo-filter-lte-operator></kendo-filter-lte-operator>
          <kendo-filter-gt-operator></kendo-filter-gt-operator>
          <kendo-filter-gte-operator></kendo-filter-gte-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isHighestPrice" filter="boolean" title="Maximum Price" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <kendo-grid-messages filterIsTrue="Yes"
                           filterIsFalse="No">
      </kendo-grid-messages>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.isHighestPrice ? 'Yes' : 'No'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isLowestPrice" filter="boolean" title="Minimum Price" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <kendo-grid-messages filterIsTrue="Yes"
                           filterIsFalse="No">
      </kendo-grid-messages>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.isLowestPrice ? 'Yes' : 'No'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="priceSourceOption.name"
                       title="Price Source Option"
                       width="250"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <price-schedule-price-source-filter [filter]="filter"
                                            [data]="priceSchedulePriceSourceOptions"
                                            textField="name"
                                            valueField="name">
        </price-schedule-price-source-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="pricingFilter.name" title="Pricing Filter" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="priceBook.name" title="Price Book" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isActive" filter="boolean" title="Is Active" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <kendo-grid-messages filterIsTrue="Yes"
                           filterIsFalse="No">
      </kendo-grid-messages>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.isActive ? 'Yes' : 'No'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="roundingRule.name"
                       title="Rounding Rule"
                       width="150"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="calculateAfterPriceSchedule.name"
                       title="Calculate After Price Schedule"
                       width="225"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
          <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
          <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="erpid" title="ERP ID" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Select</button>
  </kendo-dialog-actions>
</kendo-dialog>
