<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Attribute_Map.png" alt="Attribute Map" style="padding-right: 5px;">
  Create / Update Attribute Map
</div>

<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/attribute-maps']" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">SAVE & CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">COPY</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
</kendo-buttongroup>

<form [formGroup]="attributeMapForm">
  <div class="row">
    <div class="col-6 form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Attribute Map Name">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="attributeCount" class="font-weight-bold" text="* Attribute Count"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="attributeCount" [min]="1" [max]="3"
             [decimals]="0" [format]="'n'" [autoCorrect]="true" placeholder="Enter Attribute Count"
             (valueChange)="attributeCountChange()">
        </kendo-numerictextbox>
        <kendo-formerror>Attribute Count is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="defaultMapAmount" text="Default Map Amount"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="defaultMapAmount" [autoCorrect]="true"
              [decimals]="5" [format]="'n5'" placeholder="Enter a Default Amount" [min]="-99999999999999"  [max]="99999999999999"
              title="When Attribute Map Values are not found, this will be the amount returned.">
        </kendo-numerictextbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Attribute Map Description">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col-6 form-group">
      <kendo-formfield>
        <kendo-label for="entityType1ID" class="font-weight-bold" text="* Entity Type 1"></kendo-label>
        <ng-select id="entityType1ID" [multiple]="false" [selectOnTab]="true" formControlName="entityType1ID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.entityType1ID.errors,'w-100': true}" placeholder="Select an Entity"
                   [searchable]="true" (change)="onSelectBoxChanged($event, 'entityType1ID', entityTypes1)">
          <ng-option *ngFor="let item of entityTypes1" [value]="item.id"> {{ item.name }} </ng-option>
        </ng-select>
        <kendo-formerror>Entity Type 1 is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col-6 form-group">
      <kendo-formfield *ngIf="attributeMapForm.get('entityType1ID')?.value === 75">
        <kendo-label for="customerAttribute1ID" class="font-weight-bold" text="* Customer Attribute 1"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="customerAttribute1ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="customerAttribute1ID"
                     [ngClass]="{'is-invalid': submitted && f.customerAttribute1ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.CustomerAttribute1)" placeholder="Select a Customer Attribute"
                     (change)="onSelectBoxChanged($event,'customerAttribute1ID',customerAttributes1)">
            <ng-option *ngFor="let item of customerAttributes1" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_customerAttribute1()" icon="search"></button>
        </span>
        <kendo-formerror>Customer Attribute 1 is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeMapForm.get('entityType1ID')?.value === 76">
        <kendo-label for="itemAttribute1ID" class="font-weight-bold" text="* Item Attribute 1"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="itemAttribute1ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="itemAttribute1ID"
                     [ngClass]="{'is-invalid': submitted && f.itemAttribute1ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.ItemAttribute1)" placeholder="Select an Item Attribute"
                     (change)="onSelectBoxChanged($event,'itemAttribute1ID',itemAttributes1)">
            <ng-option *ngFor="let item of itemAttributes1" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_itemAttribute1()" icon="search"></button>
        </span>
        <kendo-formerror>Item Attribute 1 is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeMapForm.get('entityType1ID')?.value === 70">
        <kendo-label for="documentAttribute1ID" class="font-weight-bold" text="* Document Attribute 1"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="documentAttribute1ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="documentAttribute1ID"
                     [ngClass]="{'is-invalid': submitted && f.documentAttribute1ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.DocumentAttribute1)" placeholder="Select a Document Attribute"
                     (change)="onSelectBoxChanged($event,'documentAttribute1ID',documentAttributes1)">
            <ng-option *ngFor="let item of documentAttributes1" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_documentAttribute1()" icon="search"></button>
        </span>
        <kendo-formerror>Document Attribute 1 is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div class="row" *ngIf="attributeMapForm.get('attributeCount')?.value > 1">
    <div class="col-6 form-group">
      <kendo-formfield>
        <kendo-label for="entityType2ID" class="font-weight-bold" text="* Entity Type 2"></kendo-label>
        <ng-select id="entityType2ID" [multiple]="false" [selectOnTab]="true" formControlName="entityType2ID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.entityType2ID.errors,'w-100': true}" placeholder="Select an Entity"
                   [searchable]="true" (change)="onSelectBoxChanged($event, 'entityType2ID', entityTypes2)">
          <ng-option *ngFor="let item of entityTypes2" [value]="item.id"> {{ item.name }} </ng-option>
        </ng-select>
        <kendo-formerror>Entity Type 2 is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col-6 form-group">
      <kendo-formfield *ngIf="attributeMapForm.get('entityType2ID')?.value === 75">
        <kendo-label for="customerAttribute2ID" class="font-weight-bold" text="* Customer Attribute 2"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="customerAttribute2ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="customerAttribute2ID"
                     [ngClass]="{'is-invalid': submitted && f.customerAttribute2ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.CustomerAttribute2)" placeholder="Select a Customer Attribute"
                     (change)="onSelectBoxChanged($event,'customerAttribute2ID',customerAttributes2)">
            <ng-option *ngFor="let item of customerAttributes2" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_customerAttribute2()" icon="search"></button>
        </span>
        <kendo-formerror>Customer Attribute 2 is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeMapForm.get('entityType2ID')?.value === 76">
        <kendo-label for="itemAttribute2ID" class="font-weight-bold" text="* Item Attribute 2"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="itemAttribute2ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="itemAttribute2ID"
                     [ngClass]="{'is-invalid': submitted && f.itemAttribute2ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.ItemAttribute2)" placeholder="Select an Item Attribute"
                     (change)="onSelectBoxChanged($event,'itemAttribute2ID',itemAttributes2)">
            <ng-option *ngFor="let item of itemAttributes2" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_itemAttribute2()" icon="search"></button>
        </span>
        <kendo-formerror>Item Attribute 2 is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeMapForm.get('entityType2ID')?.value === 70">
        <kendo-label for="documentAttribute2ID" class="font-weight-bold" text="* Document Attribute 2"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="documentAttribute2ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="documentAttribute2ID"
                     [ngClass]="{'is-invalid': submitted && f.documentAttribute2ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.DocumentAttribute2)" placeholder="Select a Document Attribute"
                     (change)="onSelectBoxChanged($event,'documentAttribute2ID',documentAttributes2)">
            <ng-option *ngFor="let item of documentAttributes2" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_documentAttribute2()" icon="search"></button>
        </span>
        <kendo-formerror>Document Attribute 2 is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div class="row" *ngIf="attributeMapForm.get('attributeCount')?.value > 2">
    <div class="col-6 form-group">
      <kendo-formfield>
        <kendo-label for="entityType3ID" class="font-weight-bold" text="* Entity Type 3"></kendo-label>
        <ng-select id="entityType3ID" [multiple]="false" [selectOnTab]="true" formControlName="entityType3ID"
                    [notFoundText]="'Please start typing to search'"
                    [ngClass]="{'is-invalid': submitted && f.entityType3ID.errors,'w-100': true}" placeholder="Select an Entity"
                    [searchable]="true" (change)="onSelectBoxChanged($event, 'entityType3ID', entityTypes3)">
          <ng-option *ngFor="let item of entityTypes3" [value]="item.id"> {{ item.name }} </ng-option>
        </ng-select>
        <kendo-formerror>Entity Type 3 is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group">
      <kendo-formfield *ngIf="attributeMapForm.get('entityType3ID')?.value === 75">
        <kendo-label for="customerAttribute3ID" class="font-weight-bold" text="* Customer Attribute 3"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="customerAttribute3ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="customerAttribute3ID"
                     [ngClass]="{'is-invalid': submitted && f.customerAttribute3ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.CustomerAttribute3)" placeholder="Select a Customer Attribute"
                     (change)="onSelectBoxChanged($event,'customerAttribute3ID',customerAttributes3)">
            <ng-option *ngFor="let item of customerAttributes3" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_customerAttribute3()" icon="search"></button>
        </span>
        <kendo-formerror>Customer Attribute 3 is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeMapForm.get('entityType3ID')?.value === 76">
        <kendo-label for="itemAttribute3ID" class="font-weight-bold" text="* Item Attribute 3"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="itemAttribute3ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="itemAttribute3ID"
                     [ngClass]="{'is-invalid': submitted && f.itemAttribute3ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.ItemAttribute3)" placeholder="Select an Item Attribute"
                     (change)="onSelectBoxChanged($event,'itemAttribute3ID',itemAttributes3)">
            <ng-option *ngFor="let item of itemAttributes3" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_itemAttribute3()" icon="search"></button>
        </span>
        <kendo-formerror>Item Attribute 3 is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeMapForm.get('entityType3ID')?.value === 70">
        <kendo-label for="documentAttribute3ID" class="font-weight-bold" text="* Document Attribute 3"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="documentAttribute3ID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="documentAttribute3ID"
                     [ngClass]="{'is-invalid': submitted && f.documentAttribute3ID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.DocumentAttribute3)" placeholder="Select a Document Attribute"
                     (change)="onSelectBoxChanged($event,'documentAttribute3ID',documentAttributes3)">
            <ng-option *ngFor="let item of documentAttributes3" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_documentAttribute3()" icon="search"></button>
        </span>
        <kendo-formerror>Document Attribute 3 is required</kendo-formerror>
      </kendo-formfield>
    </div>

  </div>
</form>

<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-attribute-map-value-list #subgrid [attributeMapID]='idOfAttributeMap' [attribute1ColumnName]='attribute1ColumnName'
                               [attribute2ColumnName]='attribute2ColumnName' [attribute3ColumnName]='attribute3ColumnName'
                               [attributeCount]='attributeCount'
                               [pagination]="paginationObject" [loading]="loading"
                               [mode]="subgridmode">
  </app-attribute-map-value-list>
</div>
