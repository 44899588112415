import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-header-search',
  templateUrl: './table-header-search.component.html',
  styleUrls: ['./table-header-search.component.scss']
})
export class TableHeaderSearchComponent implements OnInit {
  constructor() {}
  @Input() model: string;
  @Input() queryValue: string = '';
  @Output() onSearch = new EventEmitter();
  ngOnInit(): void {}

  search(value) {
    this.onSearch.emit({ [this.model]: value });
  }
}
