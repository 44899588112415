import { Company } from "../companies/companies.model";
import { User } from "../users/users.model";

export interface CompanyUser {
    id: number;
    companyID: string;
    userID: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    company: Company;
    user: User;
}

export const emptyCompanyUser: CompanyUser = {
    id: null,
    companyID: null,
    userID: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: '',
    company: null,
    user: null
};
