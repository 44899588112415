<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Item_Currency.png" alt="Item Currency" style="padding-right: 5px;">
      Create / Update Item Currency
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <form [formGroup]="itemCurrencyForm">
    <kendo-formfield>
      <kendo-textbox hidden="true" formControlName="itemID" class="form-control">
      </kendo-textbox>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label for="uofMID" class="font-weight-bold" text="* Unit of Measure"></kendo-label>
      <span class="k-textbox" style="width: 100%;">
        <ng-select id="uofMID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                   formControlName="uofMID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                  'is-invalid': submitted && f.uofMID.errors,
                  'w-100': true
                  }" [searchable]="true" (search)="search($event, filters.UofM)" placeholder="Select a Unit of Measure"
                   (change)="onSelectBoxChanged($event, 'uofM', uofMs)">
          <ng-option *ngFor="let uofM of uofMs" [value]="uofM.id">
            {{ uofM.name }}
          </ng-option>
        </ng-select>
        <kendo-textbox-separator></kendo-textbox-separator>
        <button kendoButton type="button" look="clear" (click)="onLookup_UofM()" icon="search"></button>
      </span>
      <kendo-formerror>Unit of Measure is required</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label for="currencyID" class="font-weight-bold" text="* Currency"></kendo-label>
      <span class="k-textbox" style="width: 100%;">
        <ng-select id="currencyID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                   formControlName="currencyID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.currencyID.errors,
                    'w-100': true
                  }" placeholder="Select a Currency" [searchable]="true" (search)="search($event, filters.Currency)"
                   (change)="onSelectBoxChanged($event, 'currency', currencies)">
          <ng-option *ngFor="let currency of currencies" [value]="currency.id">
            {{ currency.name }}
          </ng-option>
        </ng-select>
        <kendo-textbox-separator></kendo-textbox-separator>
        <button kendoButton type="button" look="clear" (click)="onLookup_Currency()" icon="search"></button>
      </span>
      <kendo-formerror>Currency is required</kendo-formerror>
    </kendo-formfield>
    <div *ngIf="singleCurrency">
      <div class="row">
        <div class="col form-group">
          <kendo-label class="font-weight-bold" for="currencyID" text="* Currency"></kendo-label>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <kendo-label for="currencyID" text="{{itemCurrency.currency.name}}"></kendo-label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="costAmount" class="font-weight-bold" text="* Cost Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" placeholder="Enter Cost Amount" formControlName="costAmount" [decimals]="5" [format]="'n5'"
                                [min]="0" [max]="99999999999999" [autoCorrect]="true" [ngClass]="{ 'is-invalid': submitted && f.costAmount.errors }">
          </kendo-numerictextbox>
          <kendo-formerror>Cost Amount is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="listPriceAmount" class="font-weight-bold" text="* List Price Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" placeholder="Enter List Price Amount" [decimals]="5" [format]="'n5'"
                                formControlName="listPriceAmount" [min]="0" [max]="99999999999999" [autoCorrect]="true"
                                [ngClass]="{ 'is-invalid': submitted && f.listPriceAmount.errors }">
          </kendo-numerictextbox>
          <kendo-formerror>List Price Amount is required</kendo-formerror>
        </kendo-formfield>

      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
