export interface CatalogLine {
  id: number;
  catalogSheetID: number;
  catalogSheet: {
    name: string,
    id: number
  };
  priceScheduleID: number;
  priceSchedule: {
    name: string,
    id: number
  };
  customerID: number;
  customer: {
    name: string,
    number: string,
    id: number
  };
  itemID: number;
  item: {
    description: string,
    name: string,
    number: string,
    id: number,
    uofMScheduleID: number;
  };
  externalItemNumber: string;
  uofMID: number;
  uofM: {
    name: string,
    id: number
  };
  locationID: number;
  location: {
    name: string,
    id: number
  };
  quantity: number;
  currencyID: number;
  currency: {
    name: string,
    id: number
  }
  effectiveDate: Date;
  comment: string,
  calulatedUnitPrice: number;
  benchmarkedUnitPrice: number;
  calculatedPriceScheduleID: number;
  calculatedPriceSchedule: {
    name: string,
    id: number
  };
  lastCalculatedDateTime: Date;
  lastBenchmarkedDateTime: Date;
  processStatus: string;
  errorMessage: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyCatalogLine: CatalogLine = {
  id: null,
  catalogSheetID: null,
  catalogSheet: {
    name: '',
    id: null
  },
  priceScheduleID: null,
  priceSchedule: {
    name: '',
    id: null
  },
  customerID: null,
  customer: {
    name: '',
    number: '',
    id: null
  },
  itemID: null,
  item: {
    description: '',
    name: '',
    number: '',
    id: null,
    uofMScheduleID: null
  },  
  externalItemNumber: null,
  uofMID: null,
  uofM: {
    name: '',
    id: null
  },
  locationID: null,
  location: {
    name: '',
    id: null
  },
  quantity: null,
  currencyID: null,
  currency: {
    name: '',
    id: null
  },
  effectiveDate: null,
  comment: null,
  calulatedUnitPrice: null,
  benchmarkedUnitPrice: null,
  calculatedPriceScheduleID: null,
  calculatedPriceSchedule: {
    name: '',
    id: null
  },
  lastCalculatedDateTime: null,
  lastBenchmarkedDateTime: null,
  processStatus: null,
  errorMessage: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
