
<ng-template #areYouSure let-modal>
  <div class="modal-header">
    Alert
  </div>
  <div class="modal-body">
    <p>
      Are you sure you want to delete that entity ?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="remove(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>
<ng-template #newPricingFilterValueCollection let-modal>
  <kendo-dialog [minWidth]="250" (close)="modal.close('Save click')" [width]="450">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        New Pricing Filter Value
      </div>
    </kendo-dialog-titlebar>
    <div style="margin-top:15px;" #container id="container"></div>
    <form [formGroup]="pricingFilterValueCollectionForm">
      <kendo-formfield *ngIf="attributeIsString && !showCollectionCustomerLookup && !showCollectionItemLookup && !showCollectionLocationLookup">
        <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
        <kendo-textbox class="form-control" formControlName="value" placeholder="Enter a Value">
        </kendo-textbox>
        <kendo-formerror>Value is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeIsDate && !showCollectionCustomerLookup && !showCollectionItemLookup && !showCollectionLocationLookup">
        <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
        <kendo-datepicker class="form-control" formControlName="value" placeholder="Enter a Value"
                          [(value)]="value">
        </kendo-datepicker>
        <kendo-formerror>Value is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="attributeIsNumber && !showCollectionCustomerLookup && !showCollectionItemLookup && !showCollectionLocationLookup">
        <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
        <kendo-numerictextbox class="form-control" type="number" formControlName="value" [decimals]="5"
                              placeholder="Enter a Value" [format]="'n5'" [min]="-99999999999999"  [max]="99999999999999">
        </kendo-numerictextbox>
        <kendo-formerror>Value is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="showCollectionCustomerLookup">
        <label for="collectionCustomerID" class="font-weight-bold">* Customer</label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="collectionCustomerID" class="noarrow" [multiple]="false" [selectOnTab]="true" appendTo="body"
                     formControlName="collectionCustomerID" [notFoundText]="'Please start typing to search'" [ngClass]="{
        'is-invalid': submitted && f.collectionCustomerID?.errors,
        'w-100': true
      }" placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.CollectionCustomer)"
                     (change)="
      onCollectionCustomerChanged(
        $event,
        collectionCustomers)">
            <ng-option *ngFor="let item of collectionCustomers" [value]="item.id">
              {{ item?.number }} - {{ item?.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_CollectionCustomer()" icon="search"></button>
        </span>
        <kendo-formerror>Customer is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="showCollectionItemLookup">
        <label for="collectionItemID" class="font-weight-bold">* Item</label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="collectionItemID" class="noarrow" [multiple]="false" [selectOnTab]="true" appendTo="body"
                     formControlName="collectionItemID" [notFoundText]="'Please start typing to search'" [ngClass]="{
        'is-invalid': submitted && f.collectionItemID?.errors,
        'w-100': true
      }" placeholder="Select an Item" [searchable]="true" (search)="search($event, filters.CollectionItem)" (change)="
      onCollectionItemChanged(
        $event,
        collectionItems)">
            <ng-option *ngFor="let item of collectionItems" [value]="item.id">
              {{ item?.number }} - {{ item?.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_CollectionItem()" icon="search"></button>
        </span>
        <kendo-formerror>Item is required</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield *ngIf="showCollectionLocationLookup">
        <label for="collectionLocationID" class="font-weight-bold">* Location</label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="collectionLocationID" class="noarrow" [multiple]="false" [selectOnTab]="true" appendTo="body"
                     formControlName="collectionLocationID" [notFoundText]="'Please start typing to search'" [ngClass]="{
        'is-invalid': submitted && f.collectionLocationID?.errors,
        'w-100': true
      }" placeholder="Select a Location" [searchable]="true" (search)="search($event, filters.CollectionLocation)"
                     (change)="
      onCollectionLocationChanged(
        $event,
        collectionLocations)">
            <ng-option *ngFor="let item of collectionLocations" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_CollectionLocation()" icon="search"></button>
        </span>
        <kendo-formerror>Location is required</kendo-formerror>
      </kendo-formfield>
    </form>
    <kendo-dialog-actions>
      <button kendoButton (click)="modal.close('Save click')"><span class="k-icon k-i-cancel"></span>Cancel</button>
      <button kendoButton (click)="addPricingFilterValueCollection(modal)" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</ng-template>
<!-- BODY -->
<kendo-dialog [minWidth]="450" (close)="onClose('Cancel')" [maxWidth]="650" [maxHeight]="650" [width]='650'>
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Create / Update Pricing Filter Value
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <form [formGroup]="pricingFilterValueForm">
    <input hidden="true" id="pricingFilterID" class="form-control" formControlName="pricingFilterID"
           disabled="true" />
    <div class="row">
      <div class="col form-group">
        <label for="entityID" class="font-weight-bold">* Entity</label>
        <ng-select id="entity" [multiple]="false" [selectOnTab]="true" formControlName="entityID"
                   [notFoundText]="'Please start typing to search'" appendTo="body" [ngClass]="{
                  'is-invalid': submitted && f.entityID.errors,
                  'w-100': true
                }" placeholder="Select an Entity" [searchable]="true"
                   (change)="onSelectBoxChanged($event, 'entityID', entityTypes)">
          <ng-option *ngFor="let item of entityTypes" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <div *ngIf="submitted && f.entityID.errors" class="invalid-feedback">
          <div *ngIf="f.entityID?.errors?.required">Entity is required</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="f.entityID.value == 75">
        <label for="customerAttributeID" class="font-weight-bold">* Customer Attribute</label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="customerAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="customerAttributeID"
                     placeholder="Select an Attribute" [ngClass]="{
                  'is-invalid': submitted && f.customerAttributeID.errors,
                  'w-100': true
                }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.CustomerAttribute)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'customerAttributeID',
                    customerAttributes)">
            <ng-option *ngFor="let item of customerAttributes" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_CustomerAttribute()"
                  icon="search"></button>
        </span>
        <div *ngIf="submitted && f.customerAttributeID.errors" class="invalid-feedback">
          <div *ngIf="f.customerAttributeID?.errors?.required" class="invalid-feedback">Customer Attribute is required</div>
        </div>
      </div>
      <div class="col form-group" *ngIf="f.entityID.value == 70">
        <label for="documentAttributeID" class="font-weight-bold">* Document Attribute</label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="documentAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" appendTo="body" formControlName="documentAttributeID"
                     placeholder="Select an Attribute" [ngClass]="{
                  'is-invalid': submitted && f.documentAttributeID.errors,
                  'w-100': true
                }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.DocumentAttribute)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'documentAttributeID',
                    documentAttributes)">
            <ng-option *ngFor="let item of documentAttributes" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>

          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_DocumentAttribute()"
                  icon="search"></button>
        </span>
        <div *ngIf="submitted && f.documentAttributeID.errors" class="invalid-feedback">
          <div *ngIf="f.documentAttributeID?.errors?.required" class="invalid-feedback">Document Attribute is required</div>
        </div>
      </div>
      <div class="col form-group" *ngIf="f.entityID.value == 76">
        <label for="itemAttributeID" class="font-weight-bold">* Item Attribute</label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="itemAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="itemAttributeID"
                     placeholder="Select an Attribute" [ngClass]="{
                  'is-invalid': submitted && f.itemAttributeID.errors,
                  'w-100': true
                }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.ItemAttribute)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'itemAttributeID',
                    itemAttributes)">
            <ng-option *ngFor="let item of itemAttributes" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_ItemAttribute()" icon="search"></button>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <label for="operandID" class="font-weight-bold">* Operand</label>
        <div>
          <ng-select id="operand" [multiple]="false" [selectOnTab]="true" formControlName="operandID"
                     [notFoundText]="'Please start typing to search'" appendTo="body" [ngClass]="{
                  'is-invalid': submitted && f.operandID.errors,
                  'w-100': true
                }" placeholder="Select an Operand" [searchable]="true"
                     (change)="onSelectBoxChanged($event, 'operand', operands)">
            <ng-option *ngFor="let item of operands" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <div *ngIf="submitted && f.operandID.errors" class="invalid-feedback">
            <div *ngIf="f.operandID?.errors?.required">Operand is required</div>
          </div>
        </div>
      </div>
      <div class="col form-group" *ngIf="!showCollectionGrid && operandHasValue">
        <label *ngIf="!showCustomerLookup && !showItemLookup && !showLocationLookup" for="value"
               class="font-weight-bold">* Value</label>
        <input *ngIf="attributeIsString && !attributeIsNumber && !showCustomerLookup && !showItemLookup && !showLocationLookup"
               class="form-control" formControlName="value" placeholder="Enter a Value"
               [ngClass]="{ 'is-invalid': submitted && f.value.errors }" />
        <input *ngIf="attributeIsCheckBox && !attributeIsNumber && !showCustomerLookup && !showItemLookup && !showLocationLookup"
               class="form-control" type="checkbox" placeholder="Enter a Value" formControlName="value" />
        <input *ngIf="attributeIsDate && !attributeIsNumber && !showCustomerLookup && !showItemLookup && !showLocationLookup"
               class="form-control" type="date" max="9999-01-01" placeholder="Enter a Value" formControlName="value"
               [ngClass]="{ 'is-invalid': submitted && f.value.errors }" />
        <input *ngIf="attributeIsNumber && !showCustomerLookup && !showItemLookup && !showLocationLookup"
               class="form-control" type="number" onkeydown='javascript: return event.keyCode == 69 ? false : true'
               formControlName="value" [ngClass]="{ 'is-invalid': submitted && f.value.errors }"
               placeholder="Enter a Value" />
        <div *ngIf="submitted && f.value.errors" class="invalid-feedback">
          <div *ngIf="f.value?.errors?.required">Value is required</div>
        </div>
        <kendo-formfield showErrors="always" *ngIf="showCustomerLookup && !showCollectionGrid">
          <label for="customerID" class="font-weight-bold">* Customer</label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="customerID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.customerID.errors,'w-100': true}"
                       placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.Customer)"
                       (change)="onCustomerChanged($event,customers)">
              <ng-option *ngFor="let item of customers" [value]="item.id">
                {{ item?.number }} - {{ item?.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_Customer()" icon="search"></button>
          </span>
          <kendo-formerror *ngIf="submitted && f.customerID.errors?.required">Customer is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield showErrors="always" *ngIf="showItemLookup && !showCollectionGrid">
          <label for="itemID" class="font-weight-bold">* Item</label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="itemID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="itemID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.itemID.errors,'w-100': true}"
                       placeholder="Select an Item" [searchable]="true" (search)="search($event, filters.Item)"
                       (change)="onItemChanged($event,items)">
              <ng-option *ngFor="let item of items" [value]="item.id">
                {{ item?.number }} - {{ item?.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_Item()" icon="search"></button>
          </span>
          <kendo-formerror *ngIf="submitted && f.itemID.errors?.required">Item is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield showErrors="always" *ngIf="showLocationLookup && !showCollectionGrid">
          <label for="locationID" class="font-weight-bold">* Location</label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="locationID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="locationID" [notFoundText]="'Please start typing to search'" [ngClass]="{
                    'is-invalid': submitted && f.locationID.errors,
                    'w-100': true
                  }" placeholder="Select a Location" [searchable]="true" (search)="search($event, filters.Location)"
                       (change)="
                  onLocationChanged(
                    $event,
                    locations)">
              <ng-option *ngFor="let item of locations" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_Location()" icon="search"></button>
          </span>
          <kendo-formerror *ngIf="submitted && f.locationID.errors?.required">Location is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div> 
    <div class="card p-3" *ngIf="showCollectionGrid" style="background-color:rgb(234, 234, 234)">
      <div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
        Pricing Filter Values
      </div>
      <kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [height]="350"
                  [sort]="query.SortTerm" [reorderable]="true" [loading]="loading" [pageable]="true"
                  (pageChange)="pageChange($event)" [filter]="filter" [filterable]="filterable"
                  (filterChange)="gridFilterChange($event)" [sortable]="true" 
                  (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
                  [selectedKeys]="mySelection" [resizable]="false" [autoSize]="true" (cellClick)="onCellClick($event)"
                  (dblclick)="onEdit(newPricingFilterValueCollection)">
        <ng-template kendoGridToolbarTemplate>
          <kendo-buttongroup>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
                    imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()"
                    title="Refresh">
              Refresh
            </button>
            <button kendoButton type="button" id="btnAddPricingFilterValue" class="showTooltip" style="border: none; padding-left: 10px; padding-right: 10px;"
                    [disabled]="!this.idOfPricingFilterValue" imageUrl="../../../../assets/images/Add.png"
                    (click)="onCreate(newPricingFilterValueCollection)" title="Add New">
              Add New
            </button>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
                    [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png"
                    (click)="onEdit(newPricingFilterValueCollection)" title="Edit Selected">
              Edit
            </button>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
                    [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
                    (click)="showRemovePopUp(areYouSure)" title="Delete Selected">
              Delete
            </button>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
          </kendo-buttongroup>
          <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false"
                 #hiddenfileinput />
        </ng-template>
        <kendo-grid-column field="displayValue" title="Value"
                           [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}"></kendo-grid-column>
      </kendo-grid>
    </div>

  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClose('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onSave('Open')" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
    <button kendoButton (click)="onSave('Close')" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save & Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
