import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceCalculationsService } from '../../../services/price-calculations/price-calculations.service';
import { PriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';

import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {  GridDataResult,    PageChangeEvent,    GridComponent,  ExcelComponent} from '@progress/kendo-angular-grid';
import {  SortDescriptor,  orderBy,  CompositeFilterDescriptor,  FilterDescriptor} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PriceFilter } from 'src/app/services/price-filter/price-filter.model';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';


@Component({
  selector: 'app-price-calculation-lookup',
  templateUrl: './price-calculation-lookup.component.html',
  styleUrls: ['./price-calculation-lookup.component.scss'],
})
export class PriceCalculationLookupComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private priceCalculationsService: PriceCalculationsService,
    private lookupService: LookupsService,
    public activeModal: NgbActiveModal,  
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input()  loading: boolean = true;
  @Input()  priceCalculations: PriceCalculation[] = [];
  @Input()  query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input()  pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;

  pagination_priceCalculations: any = { priceCalculations: mockedData };
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceFilter[];
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public priceCalculationPriceModeOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Amount',
      field: 'priceAmount',
      width: 150,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Percent',
      field: 'pricePercent',
      width: 150,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Convert to Unit of Measure',
      field: 'convertToUofM',
      width: 200,
      orderIndex: 3,
      hidden: false,
    },
    {
      title: 'Per Unit of Measure Name',
      field: 'perUofMName',
      width: 200,
      orderIndex: 4,
      hidden: false,
    },
    {
      title: 'Modify Unit Price',
      field: 'modifyUnitPrice',
      width: 150,
      orderIndex: 5,
      hidden: false,
    },
    {
      title: 'Track Adjustments',
      field: 'trackAdjustment',
      width: 150,
      orderIndex: 6,
      hidden: false,
    },
    {
      title: 'Track as Extended',
      field: 'trackAdjustmentExtended',
      width: 150,
      orderIndex: 7,
      hidden: false,
    },
    {
      title: 'User Can Edit',
      field: 'userCanEdit',
      width: 150,
      orderIndex: 8,
      hidden: false,
    },
    {
      title: 'Affects Margin Calculations',
      field: 'affectsMarginCalc',
      width: 200,
      orderIndex: 9,
      hidden: false,
    },
    {
      title: 'Rounding Rule',
      field: 'roundingRule.name',
      width: 150,
      orderIndex: 10,
      hidden: false,
    },
    {
      title: 'Debit GL Account',
      field: 'debitGLAccount.name',
      width: 150,
      orderIndex: 11,
      hidden: false,
    },
    {
      title: 'Credit GL Account',
      field: 'creditGLAccount.name',
      width: 150,
      orderIndex: 12,
      hidden: false,
    },
    {
      title: 'Price Mode',
      field: 'priceModeOption.name',
      width: 100,
      orderIndex: 13,
      hidden: false,
    },
    {
      title: 'Pricing Filter',
      field: 'pricingFilter.name',
      width: 150,
      orderIndex: 14,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onClick_Save() {
    this.onDblClick();
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceCalculationLookup_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceCalculationLookup_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PriceCalculationLookup_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceCalculationLookup_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData(); 
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

 
  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {     
        this.onSelectSingle.emit(this.clickedRowItem);    
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'priceAmount') {
          const Filter_PriceAmount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PriceAmount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PriceAmount,
            Operand_PriceAmount,
          };
          this.query = params;
        }
        if (myFilter.field == 'pricePercent') {
          const Filter_PricePercent =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricePercent = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricePercent,
            Operand_PricePercent,
          };
          this.query = params;
        }
        if (myFilter.field == 'convertToUofM') {
          const Filter_ConvertToUofM = myFilter.value;
          const Operand_ConvertToUofM = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConvertToUofM,
            Operand_ConvertToUofM,
          };
          this.query = params;
        }
        if (myFilter.field == 'perUofMName') {
          const Filter_PerUofMName = myFilter.value == null ? '' : myFilter.value;
          const Operand_PerUofMName = myFilter.operator;
          const params = { ...this.query, Filter_PerUofMName, Operand_PerUofMName };
          this.query = params;
        }
        if (myFilter.field == 'modifyUnitPrice') {
          const Filter_ModifyUnitPrice = myFilter.value;
          const Operand_ModifyUnitPrice = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ModifyUnitPrice,
            Operand_ModifyUnitPrice,
          };
          this.query = params;
        }
        if (myFilter.field == 'trackAdjustment') {
          const Filter_TrackAdjustment = myFilter.value;
          const Operand_TrackAdjustment = myFilter.operator;
          const params = {
            ...this.query,
            Filter_TrackAdjustment,
            Operand_TrackAdjustment,
          };
          this.query = params;
        }
        if (myFilter.field == 'trackAdjustmentExtended') {
          const Filter_TrackAdjustmentExtended = myFilter.value;
          const Operand_TrackAdjustmentExtended = myFilter.operator;
          const params = {
            ...this.query,
            Filter_TrackAdjustmentExtended,
            Operand_TrackAdjustmentExtended,
          };
          this.query = params;
        }
        if (myFilter.field == 'userCanEdit') {
          const Filter_UserCanEdit = myFilter.value;
          const Operand_UserCanEdit = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UserCanEdit,
            Operand_UserCanEdit,
          };
          this.query = params;
        }
        if (myFilter.field == 'affectsMarginCalc') {
          const Filter_AffectsMarginCalc = myFilter.value;
          const Operand_AffectsMarginCalc = myFilter.operator;
          const params = {
            ...this.query,
            Filter_AffectsMarginCalc,
            Operand_AffectsMarginCalc,
          };
          this.query = params;
        }
        if (myFilter.field == 'roundingRule.name') {
          const Filter_RoundingRule =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_RoundingRule = myFilter.operator;
          const params = {
            ...this.query,
            Filter_RoundingRule,
            Operand_RoundingRule,
          };
          this.query = params;
        }
        if (myFilter.field == 'debitGLAccount.name') {
          const Filter_DebitGLAccount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_DebitGLAccount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_DebitGLAccount,
            Operand_DebitGLAccount,
          };
          this.query = params;
        }
        if (myFilter.field == 'creditGLAccount.name') {
          const Filter_CreditGLAccount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_CreditGLAccount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_CreditGLAccount,
            Operand_CreditGLAccount,
          };
          this.query = params;
        }
        if (myFilter.field == 'priceModeOption.name') {
          if (myFilter.value != '(All)') {
            const Filter_PriceModeOption = myFilter.value;
            const Operand_PriceModeOption = myFilter.operator;
            const params = {
              ...this.query,
              Filter_PriceModeOption,
              Operand_PriceModeOption,
            };
            this.query = params;
          }
        }
        if (myFilter.field == 'pricingFilter.name') {
          const Filter_PricingFilter =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricingFilter = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricingFilter,
            Operand_PricingFilter,
          };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
 
  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceCalculationsService.getList(this.query);
      this.priceCalculations = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.priceCalculations,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
