import { PriceCalculation } from '../price-calculations/price-calculations.model';
import { PriceSchedule } from '../price-schedules/price-schedules.model';

export interface PriceScheduleCalculation {
  id: number;
  priceScheduleID: number;
  rank: number;
  priceCalculationID: number;
  createdOn?: any;
  createdBy?: any;
  modifiedOn?: any;
  modifiedBy?: any;
  priceCalculation: PriceCalculation;
  priceSchedule: PriceSchedule;
}

export const emptyPriceScheduleCalculation: PriceScheduleCalculation = {
  id: null,
  priceScheduleID: null,
  rank: null,
  priceCalculationID: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: '',
  priceCalculation: null,
  priceSchedule: null
 };
