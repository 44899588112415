import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeMapValuesService } from '../../../services/attribute-map-values/attribute-map-values.service';
import { AttributeMapValue, emptyAttributeMapValue} from '../../../services/attribute-map-values/attribute-map-values.model';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { AttributeMap } from '../../../services/attribute-maps/attribute-maps.model';
import { AttributeMapsService } from '../../../services/attribute-maps/attribute-maps.service';

@Component({
  selector: 'app-attribute-map-value-single',
  templateUrl: './attribute-map-value-single.component.html',
  styleUrls: ['./attribute-map-value-single.component.scss'],
})
export class AttributeMapValueSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfAttributeMapValue: number | null = null;
  @Input() idOfAttributeMap: number | null = null;
  @Input() attributeCount: number = 1;
  attributeMapValue: AttributeMapValue = emptyAttributeMapValue;
  loading: boolean = true;
  attributeMapValueForm: FormGroup;
  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  showAttribute2Value: boolean = false;
  showAttribute3Value: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private attributeMapValuesService: AttributeMapValuesService,
    private attributeMapsService: AttributeMapsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfAttributeMapValue) {
      this.loadData();
    }
    else {
      this.attributeMapValue = emptyAttributeMapValue;
      this.attributeMapValue.attributeMapID = this.idOfAttributeMap;
      this.idOfAttributeMapValue = null;
      this.submitted = false;
    }
    if (this.idOfAttributeMap) {
      this.attributeMapValue.attributeMapID = this.idOfAttributeMap;
    }
    this.buildForm();
    this.updateForm();
    if (this.attributeCount > 1) {
      this.showAttribute2Value = true;
      this.attributeMapValueForm.get('attribute2Value').setValidators(Validators.required);
      this.attributeMapValueForm.get('attribute2Value').updateValueAndValidity();
    }
    if (this.attributeCount > 2) {
      this.showAttribute3Value = true;
      this.attributeMapValueForm.get('attribute3Value').setValidators(Validators.required);
      this.attributeMapValueForm.get('attribute3Value').updateValueAndValidity();
    }
  }

  async buildForm() {
    this.attributeMapValueForm = new FormGroup({
      attributeMapID: new FormControl(),
      attribute1Value: new FormControl('', Validators.required),
      attribute2Value: new FormControl(''),
      attribute3Value: new FormControl(''),
      amount: new FormControl(0, Validators.required),
    });
    this.attributeMapValueForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.attributeMapValueForm.controls;
  }
  updateForm() {
    this.attributeMapValueForm.patchValue({ ...this.attributeMapValue });
    this.isFormChanged = false;
  }

  async submit(action) {
    this.attributeMapValueForm.markAllAsTouched();
    this.submitted = true;
    if (this.attributeMapValueForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.attributeMapValue,
      ...this.attributeMapValueForm.value,
    };

    if (this.attributeMapValue.id) {
      try {
        const response: any = await this.attributeMapValuesService.update(
          this.attributeMapValue.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatusOnDialog('Record updated successfully!', "success", this.container);
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.attributeMapValuesService.create(
          this.attributeMapValueForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.attributeMapValue = response.body;
          this.idOfAttributeMapValue = this.attributeMapValue.id;
          this.notificationHelper.showStatusOnDialog('Record saved successfully!', "success", this.container);
          this.onClick_Close('test');
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
    if (action = "Close") {
      this.onClick_Close("");
    }
  }

  onClick_Save() {
    this.submit("Close");
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  async getAttributeMapValueById() {
    if (this.idOfAttributeMapValue) {
      return await this.attributeMapValuesService.getById(
        this.idOfAttributeMapValue,
      );
    } else {
      return emptyAttributeMapValue;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [
        this.attributeMapValue,
      ] = await Promise.all([
        this.getAttributeMapValueById(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.onFormChanged.emit(false);
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
