export interface AttributeMapValue {
  id: number;
  attributeMapID: number;
  attributeMap: {
    name: string;
    id: number;
    attributeCount: number;
  };
  attribute1Value: string;
  attribute2Value: string;
  attribute3Value: string;
  amount: number;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyAttributeMapValue: AttributeMapValue = {
  id: null,
  attributeMapID: null,
  attributeMap: {
    name: '',
    id: null,
    attributeCount: 1,
  },
  attribute1Value: '',
  attribute2Value: '',
  attribute3Value: '',
  amount: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};

