export interface PricingFilterValueCollection {
    id: number;
    pricingFilterValueID: number;
    value: string;
    displayValue: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyPricingFilterValueCollection: PricingFilterValueCollection = {
    id: null,
    pricingFilterValueID: null,
    value: '',
    displayValue: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
