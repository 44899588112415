export interface Company {
    id: string;
    name: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyCompany: Company = {
    id: '',
    name: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
