import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ImportsService {
  model = 'RPMImports';
  constructor(private httpClient: HttpClient) {}

  async performImport(data, entityType): Promise<HttpResponse<string>> {
     const headerValues = new HttpHeaders({ 'Content-Type': 'application/*+json' });     
     headerValues.append('Content-Type', 'application/*+json');
      
    return this.httpClient
    .post(`${this.model}?EntityType=${entityType}`, data, {headers: headerValues, responseType: 'text', observe: 'response' })
    .toPromise() as Promise<HttpResponse<string>>;
  }  
}
