<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Pricing_Condition.png" alt="Pricing Conditions" style="padding-right: 5px;">
  Pricing Conditions
</div>
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [sort]="query.SortTerm"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate *ngIf="mode == 'view'">
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" [routerLink]="['/price/pricing-condition/new']" title="Add New">Add New</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">Edit</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">Export</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()" title="Import from Excel">Import</button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>
  <kendo-grid-column field="name" title="Name" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'"
         [routerLink]="['/price/pricing-condition/', dataItem.id]">
        {{ dataItem.name }}
      </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.name }} </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="conditionType.name" title="Condition Type" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="conditionalPricingFilter.name" title="Condition Filter" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.conditionalPricingFilter"> {{ dataItem.conditionalPricingFilter.name }} </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="conditionOperand.name" title="Condition Operand" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="conditionQuantity" format="##############" title="Condition Quantity" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="conditionAmount" format="##############.00000" title="Condition Amount" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="maximumAffectedQuantity" format="##############" title="Max Affect Qty" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="historyDateOption.name" title="History Date Option" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="historyDateValue" title="History Date Value" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="historyDate" title="History Date" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="convertToUofM" filter="boolean" title="Convert To UofM" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.convertToUofM ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="convertToUofMName" title="Convert To UofM Name" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="PricingConditions.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="conditionType.name" [headerCellOptions]="{bold:'true'}" title="ConditionTypeName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="conditionalPricingFilter.name" title="ConditionalPricingFilterName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="conditionOperand.name" [headerCellOptions]="{bold:'true'}" title="ConditionOperandName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="conditionQuantity" title="ConditionQuantity" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="conditionAmount" title="ConditionAmount" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="maximumAffectedQuantity" [headerCellOptions]="{bold:'true'}" title="MaximumAffectedQuantity" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="historyDateOption.name" title="HistoryDateOptionName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="historyDateValue" title="HistoryDateValue" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="historyDate" title="HistoryDate" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="convertToUofM" title="ConvertToUofM" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="convertToUofMName" title="ConvertToUofMName" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
