import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UofMSchedulesService } from '../../../services/uofm-schedules/uofm-schedules.service';
import { UofMSchedule, emptyUofMSchedule } from '../../../services/uofm-schedules/uofm-schedules.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofM } from 'src/app/services/uofms/uofms.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UofMScheduleListComponent } from '../uofm-schedule-list/uofm-schedule-list.component';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { v4 as uuidv4 } from 'uuid';
import { UofMSubListComponent } from '../../uofms/uofm-sublist/uofm-sublist.component';

// enum Filters {
//   BaseUofM = 1,
// }
@Component({
  selector: 'app-uofm-schedule-single',
  templateUrl: './uofm-schedule-single.component.html',
  styleUrls: ['./uofm-schedule-single.component.scss'],
})
export class UofMScheduleSingleComponent implements OnInit {
  @ViewChild('subgrid') uofMSchedulesSubGrid: UofMSubListComponent;

  idOfUofMSchedule: number | null = null;
  uofMSchedule: UofMSchedule = emptyUofMSchedule;
  uOfMs: UofM[] = [];
  loading: boolean = true;
  uofMScheduleForm: FormGroup;
  submitted = false;
  paginationObject: any = { uOfMs: mockedData };
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  public isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private uofMSchedulesService: UofMSchedulesService,
    private uofMsService: UofMsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private sideNavService: SideNavService,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.uofMSchedule = emptyUofMSchedule;
        this.idOfUofMSchedule = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfUofMSchedule = params.id;
      this.loadData();
    });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.uofMSchedulesSubGrid.gridClassName = "UofMSubgridClass"
        }
        else {
          this.uofMSchedulesSubGrid.gridClassName = "UofMFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.uofMScheduleForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfUofMSchedule) {
        this.uofMScheduleForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }
  buildForm() {
    console.log('uofms', this.idOfUofMSchedule)
    this.uofMScheduleForm = this.fb.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      erpid: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
    this.uofMScheduleForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.uofMScheduleForm.controls;
  }
  updateForm() {
    this.uofMScheduleForm.patchValue({ ...this.uofMSchedule });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.uofMSchedule.id);
    await this.uofMSchedulesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/uofm-schedules']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.uofMSchedule && this.uofMSchedule.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/uofm-schedule/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/uofm-schedule/new']);
      });
    }
  }

  onClick_Save() {
    this.submit(null);
  }

  onClick_SaveClose() {
    this.submit("Close");
  }

  onClick_SaveNew() {
    this.submit("New");
  }

  async submit(action) {
    this.uofMScheduleForm.markAllAsTouched();
    this.submitted = true;
    if (this.uofMScheduleForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.uofMSchedule,
      ...this.uofMScheduleForm.getRawValue() // uofmScheduleForm.value does not include disabled controls.
    };

    // Check to see if UofM Schedule Name already exists.
    if ((await this.validation_CheckForExistingName()) === true) {
      this.snack.openSnackBar(
        'Unit of Measure Schedule Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }
    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.snack.openSnackBar(
        'ERP ID already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }

    if (this.uofMSchedule.id) {      
      try {
        const response: any = await this.uofMSchedulesService.update(
          this.uofMSchedule.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Record updated successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/uofm-schedules']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/uofm-schedule/new']);
            });
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    } else {
      try {
        const response: any = await this.uofMSchedulesService.create(
          this.uofMScheduleForm.getRawValue(), // uofmScheduleForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.uofMSchedule = response.body;
          this.idOfUofMSchedule = this.uofMSchedule.id;
          this.snack.openSnackBar(
            'Record saved successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/uofm-schedules']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/uofm-schedule/new']);
            });
          }
          else {
            this.uofMSchedule = response.body;
            this.router.navigate(['/price/uofm-schedule/', this.uofMSchedule.id]);
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
    this.isFormChanged = false;
  }
  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.uofMScheduleForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingUofMSchedules: UofMSchedule[] = await (
      await this.uofMSchedulesService.getList(params)
    ).body;
    if (existingUofMSchedules.length > 0) {
      if (this.uofMSchedule.id) {
        for (const key in existingUofMSchedules) {
          const currentUofMSchedule: UofMSchedule = existingUofMSchedules[key];
          if (currentUofMSchedule.id !== this.uofMSchedule.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }
  async validation_CheckForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.uofMScheduleForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingUofMSchedules: UofMSchedule[] = await (
      await this.uofMSchedulesService.getList(params)
    ).body;
    if (existingUofMSchedules.length > 0) {
      if (this.uofMSchedule.id) {
        for (const key in existingUofMSchedules) {
          const currentUofMSchedule: UofMSchedule = existingUofMSchedules[key];
          if (currentUofMSchedule.id != this.uofMSchedule.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }
  async getUofMScheduleById() {
    if (this.idOfUofMSchedule) {
      return await this.uofMSchedulesService.getById(this.idOfUofMSchedule);
    } else {
      return emptyUofMSchedule;
    }
  }
  async loadData() {
    this.loading = true;
    try {
      [
        this.uofMSchedule,
        this.uOfMs
      ] = await Promise.all([
        this.getUofMScheduleById(),
        this.idOfUofMSchedule ? this.getUofMs(null) : [],
      ]);
      this.prepareData();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  async getUofMs(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.uofMsService.getList({ Filter_UofMScheduleID: this.idOfUofMSchedule, ...params, PageSize });
      this.uOfMs = resp.body;
      this.paginationObject.uOfMs = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.uOfMs = [];
    }
    return this.uOfMs;
  }

  prepareData() {
    this.updateForm();
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
