import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { formatDate, HashLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogSheetsService } from '../../../services/catalog-sheets/catalog-sheets.service';
import { CatalogSheet, emptyCatalogSheet } from '../../../services/catalog-sheets/catalog-sheets.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { CustomersService } from '../../../services/customers/customers.service';
import { ItemsService } from '../../../services/items/items.service';
import { LocationsService } from '../../../services/locations/locations.service';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { PriceScheduleLookupComponent } from '../../price-schedules/price-schedule-lookup/price-schedule-lookup.component';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { Currency } from 'src/app/services/currencies/currencies.model';
import { CatalogsService } from '../../../services/catalogs/catalog.service';
import { emptyCatalog, Catalog } from 'src/app/services/catalogs/catalog.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { CatalogListComponent } from '../../catalogs/catalog-list/catalog-list.component';
import { CatalogLookupComponent } from '../../catalogs/catalog-lookup/catalog-lookup.component';
import { CatalogLineListComponent } from '../../catalog-lines/catalog-line-list/catalog-line-list.component';
import { CurrencyListComponent } from '../../currencies/currency-list/currency-list.component';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { CatalogLine } from 'src/app/services/catalog-lines/catalog-lines.model';
import { CatalogLinesService } from 'src/app/services/catalog-lines/catalog-lines.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../../../services/settings/settings.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { Setting } from 'src/app/services/settings/settings.model';
import { Location as AngularLocation} from '@angular/common'; 
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { CopyService } from '../../../services/copy/copy.service';
import { Customer } from '../../../services/customers/customers.model';
import { Item } from '../../../services/items/items.model';
import { Location } from '../../../services/locations/locations.model'; 
import { UtilitiesService } from '../../../services/utilities/utilities.service';


enum Filters {
  Name = 1,
  Catalog,
  Currency,
  PriceSchedule,
  Customer,
  Item,
  Location
}
@Component({
  selector: 'app-catalog-sheet-single',
  templateUrl: './catalog-sheet-single.component.html',
  styleUrls: ['./catalog-sheet-single.component.scss'],
})
export class CatalogSheetSingleComponent implements OnInit {
  @ViewChild('subgrid') catalogLinesSubGrid: CatalogLineListComponent;

  filters = Filters;
  idOfCatalogSheet: number | null = null;
  @Input() idOfCatalog: number | null = null;
  @Input() createFromBook: boolean = false;
  catalogSheet: CatalogSheet = emptyCatalogSheet;
  loading: boolean = true;
  catalogSheetForm: FormGroup;
  currencies: Currency[] = [];
  catalogs: Catalog[] = [];
  currencyID: number;
  singleCurrency: boolean;
  priceSchedules: PriceSchedule[] = [];
  customers: Customer[] = [];
  items: Item[] = [];
  locations: Location[] = [];
  catalogLines: CatalogLine[] = [];
  effectiveDate: Date = new Date();
  public min: Date = new Date(2000, 0);

  paginationObject_Catalogs: any = { catalogs: mockedData };
  paginationObject_Currencies: any = { currencies: mockedData };
  paginationObject_CatalogLines: any = { catalogLines: mockedData };
  submitted = false;
  date: { year: number; month: number };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();

  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  catalogLookupHasFocus = false;

  constructor(
    private route: ActivatedRoute,
    private catalogSheetsService: CatalogSheetsService,
    private catalogLinesService: CatalogLinesService,
    private catalogsService: CatalogsService,
    private currenciesService: CurrenciesService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private location : AngularLocation,
    private router: Router,
    private notificationHelper: NotificationHelper,
    private pricingFiltersService: PricingFiltersService,
    private priceSchedulesService: PriceSchedulesService,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private locationsService: LocationsService,
    private copyService: CopyService,
    private utilitiesService: UtilitiesService,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.catalogSheet = emptyCatalogSheet;
        this.idOfCatalogSheet = null;
        this.submitted = false;

        this.idOfCatalog = Number(this.route.snapshot.paramMap.get('catalogID'));
        return;
      }
      else{
        this.idOfCatalogSheet = params.id;
      }
    });
    this.catalogSearch = debounce(this.catalogSearch, 400, {});
    this.currencySearch = debounce(this.currencySearch, 400, {});

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {          
          this.catalogLinesSubGrid.gridClassName = "CatalogLineSubgridClass"
        }
        else {
          this.catalogLinesSubGrid.gridClassName = "CatalogLineFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.idOfCatalog) {      
      this.catalogSheet.catalogID = this.idOfCatalog;
    }
    this.loadData();
  }

  buildForm() {
    this.catalogSheetForm = this.fb.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      catalogID: new FormControl(null, Validators.required),
      effectiveDate: new FormControl(''),
      useToday: new FormControl(false),
      pricingFilterID: new FormControl(''),
      currencyID: new FormControl(''),
      currencyLabelName: new FormControl(),
      priceScheduleID: new FormControl(''),
      customerID: new FormControl(''),
      itemID: new FormControl(''),          
      locationID: new FormControl(''),      
      useBaseUofM: new FormControl(false),
    });
    this.catalogSheetForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getUseSingleCurrencySettings();
    
  }
   

  get f() {
    return this.catalogSheetForm.controls;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMCatalogSheet", this.catalogSheet.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  updateForm() {
    this.catalogSheetForm.patchValue({ ...this.catalogSheet });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.catalogSheet.id);
    await this.catalogSheetsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/catalog-sheets']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableButtons() {
    if (this.catalogSheet && this.catalogSheet.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/catalog-sheet/new'));
    if (canContinue) {
      if (this.idOfCatalog) {
        const currentIDOfCatalog: number = this.idOfCatalog;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/price/catalog-sheet/new', { catalogID: currentIDOfCatalog }],);
        });
      }
      else if (this.catalogSheet.catalogID) {
        const currentIDOfCatalog: number = this.catalogSheet.catalogID;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/price/catalog-sheet/new', { catalogID: currentIDOfCatalog }],);
        });
      }
    }
  }

  onClick_Back(): void {
    if (this.idOfCatalog) {
      this.router.navigate(['/price/catalog/' + this.idOfCatalog]);
    } else {
      this.location.back();
    }
  }
  
  onClick_Save() {
    this.submit(null);
  }

  onClick_SaveClose() {
    this.submit("Close");
  }

  onClick_SaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.catalogSheetForm.controls['name'].value;
    const Operand_Name = 'eq';
    const Filter_CatalogID = this.catalogSheet.catalogID;
    const Operand_CatalogID = 'eq';

    const params = { Filter_Name, Operand_Name, Filter_CatalogID, Operand_CatalogID, PageSize };
    const existingCatalogSheets: CatalogSheet[] = await (
      await this.catalogSheetsService.getList(params)
    ).body;
    if (existingCatalogSheets.length > 0) {
      if (this.catalogSheet.id) {
        for (const key in existingCatalogSheets) {
          const currentCatalogSheet: CatalogSheet = existingCatalogSheets[key];
          if (currentCatalogSheet.id !== this.catalogSheet.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.catalogSheetForm.markAllAsTouched();
    this.submitted = true;
    if (this.catalogSheetForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.catalogSheet,
      ...this.catalogSheetForm.value,
    };
    // Check to see if Catalog Sheet Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Catalog Sheet Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.catalogSheet.id) {
        try {
          const response: any = await this.catalogSheetsService.update(
            this.catalogSheet.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/catalogs']);
            }
            else if (action == "New") {
              const currentIDOfCatalog: number = this.catalogSheet.catalogID;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/catalog-sheet/new', { catalogID: currentIDOfCatalog }],);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.catalogSheetsService.create(
            this.catalogSheetForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.catalogSheet = response.body;
            this.idOfCatalogSheet = this.catalogSheet.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/catalogs']);
            }
            else if (action == "New") {
              const currentIDOfCatalog: number = this.idOfCatalog;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/catalog-sheet/new', { catalogID: currentIDOfCatalog }],);
              });
            }
            else {
              this.catalogSheet = response.body;
              this.router.navigate(['/price/catalog-sheet/', this.catalogSheet.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      this.onFormSaved.emit(false);
      this.onFormChanged.emit(false);
      console.log('Form Changed', this.isFormChanged)

      if (this.createFromBook) {
        this.router.navigate([`/price/catalog-sheet/${this.idOfCatalogSheet}`]);
      }
    }
  }

  async loadData() {
    this.loading = true;
    try {
      //
      if (this.idOfCatalogSheet) {
        // If this is an existing record we populate the values.
        this.catalogSheet = await Promise.resolve(
          await this.catalogSheetsService.getById(this.idOfCatalogSheet));

        if (this.catalogSheet.catalogID){
          const currentCatalog: Catalog = await Promise.resolve(
            await this.catalogsService.getById(this.catalogSheet.catalogID));
          this.catalogs.push(currentCatalog);  
          this.catalogSheet.catalog = currentCatalog;
        }

        if (this.catalogSheet.itemID) {
          const currentItemFilter: Item = await Promise.resolve(this.getItemById(this.catalogSheet.itemID));
          this.items.push(currentItemFilter);
        }

        if (this.catalogSheet.customerID) {
          const currentCustomerFilter: Customer = await Promise.resolve(this.getCustomerById(this.catalogSheet.customerID));
          this.customers.push(currentCustomerFilter);
        }

        if (this.catalogSheet.locationID) {
          const currentLocationFilter: Location = await Promise.resolve(this.getLocationById(this.catalogSheet.locationID));
          this.locations.push(currentLocationFilter);
        }

        if (this.catalogSheet.priceScheduleID) {
          const currentPriceScheduleFilter: PriceSchedule = await Promise.resolve(this.getPriceScheduleById(this.catalogSheet.priceScheduleID));
          this.priceSchedules.push(currentPriceScheduleFilter);
        }

        if (this.catalogSheet.currencyID){
          const currentCurrency: Currency = await Promise.resolve(
            await this.currenciesService.getById(this.catalogSheet.currencyID));
          this.currencies.push(currentCurrency);  
          this.catalogSheet.currency = currentCurrency;
        }

        if (this.catalogSheet.effectiveDate) {
          this.catalogSheet.effectiveDate = new Date(this.catalogSheet.effectiveDate)
        }

        this.updateForm();
      }
      else {
        if (this.idOfCatalog) {
          const defaultCatalog: Catalog = await Promise.resolve(
            await this.catalogsService.getById(this.idOfCatalog));
          this.catalogs.push(defaultCatalog);
          this.catalogSheet.catalog = defaultCatalog;
          this.catalogSheet.catalogID = defaultCatalog.id;
          this.catalogSheetForm.patchValue({ ['catalogID']: defaultCatalog.id });
        }
      }
      this.isFormChanged = false;

    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async getItemById(id) {
    return await this.itemsService.getById(id);
  }

  async getCustomerById(id) {
    return await this.customersService.getById(id);
  }

  async getLocationById(id) {
    return await this.locationsService.getById(id);
  }

  async getPriceScheduleById(id) {
    return await this.priceSchedulesService.getById(id);
  }

  async catalogSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCatalogs({ SearchTerm });
  }

  async currencySearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCurrencies({ SearchTerm });
  }

  async getCatalogs(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.catalogsService.getList({
        ...params, PageSize
      });
      // @ts-ignore
      this.catalogs = resp.body;
      this.paginationObject_Catalogs.catalogs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.catalogs = [];
    }
    return this.catalogs;
  }

  async getCurrencies(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.currenciesService.getList({ ...params, PageSize });
      this.currencies = resp.body;
      this.paginationObject_Currencies.currencies = getPaginationHeader( resp.headers );
    } catch (e) {
      this.currencies = [];
    }
    return this.currencies;
  }

  onSelectBoxChanged(id: number, key: keyof CatalogSheet, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.catalogSheet[key] = item;
    if (key === 'catalogID') {  
      this.idOfCatalog = id;
      this.catalogSheet.catalogID = id;  
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.Catalog:
        this.catalogs = [];
        this.catalogs = await (await this.catalogsService.getList(params))
          .body;
        break;
      case Filters.PriceSchedule:
        this.priceSchedules = await (
          await this.priceSchedulesService.getList(params)
        ).body;
        break;
      case Filters.Customer:
        this.customers = await (
          await this.customersService.getList(params)
        ).body;
        break;
      case Filters.Item:
        this.items = await (
          await this.itemsService.getList(params)
        ).body;
        break;
      case Filters.Location:
        this.locations = await (
          await this.locationsService.getList(params)
        ).body;
        break;
      case Filters.Currency:
        this.currencies = await (await this.currenciesService.getList(params))
          .body;
        break;
      default:
        break;
    }
  }

  onBlur(event) {
    if (event) {
      this.catalogLookupHasFocus = false;
    }
  }

  onFocus(event) {
    if (event) {
      this.catalogLookupHasFocus = true;
    }
  }

  async onLookup_catalog() {

    // If the user hits the enter key but is not on this button then ignore it.
    if (!this.catalogLookupHasFocus) {
      return;
    }

    const modalRef = this.modalService.open(CatalogLookupComponent, { });

    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Catalog = receivedEntry;
      this.catalogs = [];
      this.catalogs.push(recordSelected);
      this.catalogSheetForm.patchValue({ ['catalogID']: receivedEntry.id });
      this.catalogSheet.catalog = receivedEntry;
      this.catalogSheet.catalogID = receivedEntry.id; 
      this.idOfCatalog = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_currency() {
    const modalRef = this.modalService.open(CurrencyLookupComponent, { });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.catalogSheetForm.patchValue({ ['currencyID']: receivedEntry.id });
      this.catalogSheet.currency = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_priceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.priceSchedules = [];
      this.priceSchedules.push(recordSelected);
      this.catalogSheetForm.patchValue({ ['priceScheduleID']: receivedEntry.id });
      this.catalogSheet.priceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.catalogSheetForm.patchValue({ ['customerID']: receivedEntry.id });
      this.catalogSheet.customer = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.catalogSheetForm.patchValue({ ['itemID']: receivedEntry.id });
      this.catalogSheet.item = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_location() {
    const modalRef = this.modalService.open(LocationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.catalogSheetForm.patchValue({ ['locationID']: receivedEntry.id });
      this.catalogSheet.location = receivedEntry;
      modalRef.close('test');
    });
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {    
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async getUseSingleCurrencySettings() {
    const Filter_Name = 'UseSingleCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    this.singleCurrency = setting[0].value == 'true';
    //if (this.singleCurrency && this.idOfCatalogSheet) {
    if (this.singleCurrency) {
        this.getDefaultCurrencySettings();
    }
  }

  async getDefaultCurrencySettings() { //global default
    const Filter_Name = 'DefaultCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    if (setting && setting[0]) {
      this.currencyID = +setting[0].value;
      const resp = await this.currenciesService.getById(this.currencyID);
      let tempDefaultCurrency = resp;
      this.currencies = [];
      this.currencies.push(tempDefaultCurrency);
      this.catalogSheetForm.patchValue({ ['currencyID']: tempDefaultCurrency.id });
      this.catalogSheetForm.patchValue({ ['currencyLabelName']: tempDefaultCurrency.name });
      this.catalogSheet.currency = tempDefaultCurrency;
      this.isFormChanged = false;
    }
    else {
      this.snack.openSnackBar("Default Currency is missing from Settings", '', true, 'Error', 'alert-danger');
    }
  }

  async onClickCalculate(): Promise<void> {
    try {
      const params = [
        {
          Name: "@CatalogSheetID",
          Value: this.idOfCatalogSheet
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMCalculateCatalogSheet', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records calculated successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
    this.catalogLinesSubGrid.onRefresh();
  }

  async onClickBenchmark(): Promise<void> {
    try {
      const params = [
        {
          Name: "@CatalogSheetID",
          Value: this.idOfCatalogSheet
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMBenchmarkCatalogSheet', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records benchmarked successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
    this.catalogLinesSubGrid.onRefresh();
  }

  async onClickCreateTests(): Promise<void> {
    try {
      const params = [
        {
          Name: "@CatalogSheetID",
          Value: this.idOfCatalogSheet
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMCreateScenariosFromCatalogSheet', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Pricing Test Scenario records created successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

}
