import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef, SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogLinesService } from '../../../services/catalog-lines/catalog-lines.service';
import { CatalogLine } from '../../../services/catalog-lines/catalog-lines.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { CatalogLineSingleComponent } from '../catalog-line-single/catalog-line-single.component';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, ExcelModule, GridComponent, ExcelComponent, ColumnBase } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from "../../../../app/utils/column-settings.interface";
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service'
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-catalog-line-list',
  templateUrl: './catalog-line-list.component.html',
  styleUrls: ['./catalog-line-list.component.scss'],
})
export class CatalogLineListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private catalogLinesService: CatalogLinesService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }
  @Input() loading: boolean = true;
  @Input() catalogSheetID: number;
  @Input() catalogLines: CatalogLine[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: CatalogLine[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public globalRecordCount: number = 1;
  public clickedRowItem;
  public gridClassName: string = "CatalogLineSubgridClass";
  public filterable: boolean;
  public catalogLineQuantityRuleOptions: Lookup[];
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Price Schedule',
      field: 'priceSchedule.name',
      width: 200,
      orderIndex: 0,
      hidden: false
    },
    {
      title: 'Customer Name',
      field: 'customer.name',
      width: 200,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Customer Number',
      field: 'customer.number',
      width: 200,
      orderIndex: 2,
      hidden: true
    },  
    {
      title: 'Item Name',
      field: 'item.name',
      width: 200,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Item Number',
      field: 'item.number',
      width: 200,
      orderIndex: 4,
      hidden: true
    },   
    {
      title: 'Unit of Measure',
      field: 'uofM.name',
      width: 150,
      orderIndex: 5,
      hidden: true
    },
    {
      title: 'External Item Number',
      field: 'externalItemNumber',
      width: 200,
      orderIndex: 6,
      hidden: true
    },
    {
      title: 'Quantity',
      field: 'quantity',
      width: 75,
      orderIndex: 7,
      hidden: false
    },
    {
      title: 'Location',
      field: 'location.name',
      width: 150,
      orderIndex: 8,
      hidden: true
    },
    {
      title: 'Currency',
      field: 'currency.name',
      width: 150,
      orderIndex: 9,
      hidden: true
    },
    {
      title: 'Effective Date',
      field: 'effectiveDate',
      width: 100,
      orderIndex: 10,
      hidden: true
    },
    {
      title: 'Benchmarked Price',
      field: 'benchmarkedUnitPrice',
      width: 120,
      orderIndex: 11,
      hidden: true
    },
    {
      title: 'Last Benchmarked',
      field: 'lastBenchmarkedDateTime',
      width: 150,
      orderIndex: 12,
      hidden: true
    },
    {
      title: 'Comment',
      field: 'comment',
      width: 150,
      orderIndex: 13,
      hidden: true
    },
    {
      title: 'Calculated Price',
      field: 'calculatedUnitPrice',
      width: 120,
      orderIndex: 14,
      hidden: false
    },
    {
      title: 'Calculated Price Schedule',
      field: 'calculatedPriceSchedule.name',
      width: 175,
      orderIndex: 15,
      hidden: true
    },
    {
      title: 'Last Calculated',
      field: 'lastCalculatedDateTime',
      width: 150,
      orderIndex: 16,
      hidden: true
    },
    {
      title: 'Process Status',
      field: 'processStatus',
      width: 150,
      orderIndex: 17,
      hidden: false
    },
    {
      title: 'Error Message',
      field: 'errorMessage',
      width: 150,
      orderIndex: 18,
      hidden: false
    },
  //  {
  //    title: 'Import Warning',
  //    field: 'importWarning',
  //    width: 150,
  //    orderIndex: 18,
  //    hidden: true
  //  },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.catalogSheetID && !changes.catalogSheetID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAdd').setAttribute('title', 'Add New');
    }
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.catalogLinesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = this.globalRecordCount;
    modalRef.componentInstance.hasDescColumns = true;
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        if (options.optSiblingRecords) {
          excelComponent.fetchData = this.allGlobalSiblingData;
        }
        else {
          excelComponent.fetchData = this.allData;
        }
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  public allGlobalSiblingData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.catalogLinesService.getExportList({
      ...this.query
    });
  }

  async getGlobalSiblingDataCount() {  
    this.loading = true;
    try {
      const response: any = await this.catalogLinesService.getList({ PageSize: 1, PageNumber: 1 });
      this.loading = false;
      const pagination = getPaginationHeader(response.headers);
      this.globalRecordCount = pagination.TotalCount;
    } catch (error) {
      this.loading = false;
      console.error("Error fetching total count: ", error);
    }
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('CatalogLineList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('CatalogLineList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('CatalogLineList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('CatalogLineList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(inputXML, 'RPMCatalogLine');
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onEdit(this.clickedRowItem);
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'item.name') {
          const Filter_Item = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_Item = myFilter.operator;
          const params = { ...this.query, Filter_Item, Operand_Item };
          this.query = params;
        }
        if (myFilter.field == 'item.number') {
          const Filter_ItemNumber = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_ItemNumber = myFilter.operator;
          const params = { ...this.query, Filter_ItemNumber, Operand_ItemNumber };
          this.query = params;
        }
        if (myFilter.field == 'uofM.name') {
          const Filter_UnitOfMeasure = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_UnitOfMeasure = myFilter.operator;
          const params = { ...this.query, Filter_UnitOfMeasure, Operand_UnitOfMeasure };
          this.query = params;
        }
        if (myFilter.field == 'location.name') {
          const Filter_Location = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_Location = myFilter.operator;
          const params = { ...this.query, Filter_Location, Operand_Location };
          this.query = params;
        }
        if (myFilter.field == 'customer.name') {
          const Filter_Customer = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_Customer = myFilter.operator;
          const params = { ...this.query, Filter_Customer, Operand_Customer };
          this.query = params;
        }
        if (myFilter.field == 'customer.number') {
          const Filter_CustomerNumber = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_CustomerNumber = myFilter.operator;
          const params = { ...this.query, Filter_CustomerNumber, Operand_CustomerNumber };
          this.query = params;
        }
        if (myFilter.field == 'useFilter') {
          const Filter_UseFilter = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_UseFilter = myFilter.operator;
          const params = { ...this.query, Filter_UseFilter };
          this.query = params;
        }
        if (myFilter.field == 'priceSchedule.name') {
          const Filter_PriceSchedule = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_PriceSchedule = myFilter.operator;
          const params = { ...this.query, Filter_PriceSchedule, Operand_PriceSchedule };
          this.query = params;
        }
        if (myFilter.field == 'calculatedPrice') {
          const Filter_CalculatedPrice = myFilter.value;
          const Operand_CalculatedPrice = myFilter.operator;
          const params = { ...this.query, Filter_CalculatedPrice, Operand_CalculatedPrice };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  edit(id: number) {
    this.router.navigate([`/price/catalog-line/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.catalogLinesService.getExportList({
      Filter_CatalogSheetID: this.catalogSheetID, ...this.query
    });
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.catalogLinesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.catalogSheetID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.catalogLinesService.getList({
          Filter_CatalogSheetID: this.catalogSheetID, ...this.query
        });
        this.catalogLines = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.catalogLines,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount()
  }

  onCreate() {
    const modalRef = this.modalService.open(
      CatalogLineSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "CatalogLineModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.idOfCatalogSheet = this.catalogSheetID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      //modalRef.close();
    });
  }

  onEdit(catalogLine) {
    const modalRef = this.modalService.open(
      CatalogLineSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "CatalogLineModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.idOfCatalogLine = catalogLine.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      //modalRef.close();
    });
  }
}
