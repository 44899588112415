export function removeUnSelectedIds(obj) {
  const saved = { ...obj };
  for (const key in saved) {
    if (Object.prototype.hasOwnProperty.call(saved, key)) {
      const element = saved[key];
      if (!element) delete saved[key];
    }
  }
  return saved;
}
