<kendo-dialog [minWidth]="450" (close)="onClick_Close('Cancel')" [width]="900" [height]="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/UofM.png" alt="Unit of Measure" style="padding-right: 5px;">
      Unit of Measures Lookup
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" 
              [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
              [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
              (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
              [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
              [class]="gridClassName">
    <ng-template kendoGridToolbarTemplate>
      <kendo-buttongroup>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
                imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
          Refresh
        </button>
      </kendo-buttongroup>
      <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
    </ng-template>
    <kendo-grid-column field="name" width="250" title="Name"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>    
    </kendo-grid-column>
    <kendo-grid-column width="250" field="uofMSchedule.name"
                       title="Unit of Measure Schedule"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>    
    </kendo-grid-column>
    <kendo-grid-column field="isBase" width="100" filter="boolean" title="Is Base"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    </kendo-grid-column>
    <kendo-grid-column field="quantityInBase" width="200" title="Quantity in Base"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-lt-operator></kendo-filter-lt-operator>
          <kendo-filter-lte-operator></kendo-filter-lte-operator>
          <kendo-filter-gt-operator></kendo-filter-gt-operator>
          <kendo-filter-gte-operator></kendo-filter-gte-operator>
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column width="250" field="erpid" title="ERP ID"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>   
  </kendo-grid>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Select</button>
  </kendo-dialog-actions>
</kendo-dialog>
