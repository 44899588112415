import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { RoundingRulesService } from '../../../services/rounding-rules/rounding-rules.service';
import { RoundingRule, emptyRoundingRule } from '../../../services/rounding-rules/rounding-rules.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  RoundingDirectionOption = 1,
  RoundingToOption,
}
@Component({
  selector: 'app-rounding-rule-single',
  templateUrl: './rounding-rule-single.component.html',
  styleUrls: ['./rounding-rule-single.component.scss'],
})
export class RoundingRuleSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfRoundingRule: number | null = null;
  @Input() roundingRule: RoundingRule = emptyRoundingRule;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  filters = Filters;
  roundingRuleForm: FormGroup;
  roundingDirectionOptions: Lookup[] = [];
  roundingToOptions: Lookup[] = [];
  submitted = false;
  modalReference: any;
  isFormChanged = false;

  constructor(
    private roundingRulesService: RoundingRulesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfRoundingRule) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  buildForm() {
    this.roundingRuleForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      roundingDirectionOptionID: new FormControl(null, Validators.required),
      roundingToOptionID: new FormControl(null, Validators.required),
      roundingAmount: new FormControl(null, [Validators.required]),
    });
    this.getRoundingDirectionOptions();
    this.getRoundingToOptions();
    this.roundingRuleForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.roundingRuleForm.controls;
  }
  updateForm() {
    this.roundingRuleForm.patchValue({ ...this.roundingRule });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.roundingRuleForm.markAllAsTouched();
    this.submitted = true;
    if (this.roundingRuleForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.roundingRule,
      ...this.roundingRuleForm.value,
    };
    // Check to see if Rounding Rule Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.notificationHelper.showStatusOnDialog('Rounding Rule Name already exists!', 'error', this.container);
    } else {
      if (this.roundingRule.id) {        
        try {
          const response: any = await this.roundingRulesService.update(
            this.roundingRule.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {
          const response: any = await this.roundingRulesService.create(
            this.roundingRuleForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }    
  }

  async getRoundingDirectionOptions() {
    const Filter_OptionSet = 'RoundingDirection';
    const params = { Filter_OptionSet };
    this.roundingDirectionOptions = await this.lookupService.getList(params);
    return this.roundingDirectionOptions;
  }
  async getRoundingToOptions() {
    const Filter_OptionSet = 'RoundingTo';
    const params = { Filter_OptionSet };
    this.roundingToOptions = await this.lookupService.getList(params);
    return this.roundingToOptions;
  }
  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.roundingRuleForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingRoundingRules: RoundingRule[] = await (
      await this.roundingRulesService.getList(params)
    ).body;
    if (existingRoundingRules.length > 0) {
      if (this.roundingRule.id) {
        for (const key in existingRoundingRules) {
          const currentRoundingRule: RoundingRule = existingRoundingRules[key];
          if (currentRoundingRule.id !== this.roundingRule.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }
  async getRoundingRuleById() {
    if (this.idOfRoundingRule) {
      return await this.roundingRulesService.getById(this.idOfRoundingRule);
    } else {
      return emptyRoundingRule;
    }
  }
  async loadData() {
    try {
      [
        this.roundingRule,
        this.roundingDirectionOptions,
        this.roundingToOptions,
      ] = await Promise.all([
        this.getRoundingRuleById(),
        this.getRoundingDirectionOptions(),
        this.getRoundingToOptions(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  onSelectBoxChanged(id: number, key: keyof RoundingRule, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.roundingRule[key] = item;
  }
  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    switch (type) {
      case Filters.RoundingDirectionOption:
        {
          const Filter_OptionSet = 'RoundingDirection';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.roundingDirectionOptions = await this.lookupService.getList(
            params,
          );
        }
        break;
      case Filters.RoundingToOption:
        {
          const Filter_OptionSet = 'RoundingTo';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.roundingToOptions = await this.lookupService.getList(params);
        }
        break;
      default:
        break;
    }
  }
  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

}
