<div class="wrapper">
  <app-header></app-header>
  <main>
    <div class="d-flex">
      <rockton-entry id="sideNav"></rockton-entry>
      <div class="pl-3 flex-grow-1"><router-outlet></router-outlet></div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
