import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AttributeMapListComponent } from './attribute-maps/attribute-map-list/attribute-map-list.component';
import { AttributeMapSingleComponent } from './attribute-maps/attribute-map-single/attribute-map-single.component';
import { CatalogListComponent } from './catalogs/catalog-list/catalog-list.component';
import { CatalogSingleComponent } from './catalogs/catalog-single/catalog-single.component';
import { CatalogSheetListComponent } from './catalog-sheets/catalog-sheet-list/catalog-sheet-list.component';
import { CatalogSheetSingleComponent } from './catalog-sheets/catalog-sheet-single/catalog-sheet-single.component';
import { CustomerAttributeListComponent } from './customer-attributes/customer-attribute-list/customer-attribute-list.component';
import { CustomerAttributeSingleComponent } from './customer-attributes/customer-attribute-single/customer-attribute-single.component';
import { CustomerAttributeValueListComponent } from './customer-attribute-values/customer-attribute-value-list/customer-attribute-value-list.component';
import { CustomerAttributeValueSingleComponent } from './customer-attribute-values/customer-attribute-value-single/customer-attribute-value-single.component';
import { CustomerListComponent } from './customers/customer-list/customer-list.component';
import { CustomerSingleComponent } from './customers/customer-single/customer-single.component';
import { CurrencyListComponent } from './currencies/currency-list/currency-list.component';
import { CurrencySingleComponent } from './currencies/currency-single/currency-single.component';
import { DocumentAttributeListComponent } from './document-attributes/document-attribute-list/document-attribute-list.component';
import { DocumentAttributeSingleComponent } from './document-attributes/document-attribute-single/document-attribute-single.component';
import { GetPriceComponent } from './get-price/get-price.component';
import { GLAccountListComponent } from './gl-accounts/gl-account-list/gl-account-list.component';
import { GLAccountSingleComponent } from './gl-accounts/gl-account-single/gl-account-single.component';
import { HistoricalSaleListComponent } from './historical-sales/historical-sale-list/historical-sale-list.component';
import { HistoricalSaleSingleComponent } from './historical-sales/historical-sale-single/historical-sale-single.component';
import { ItemAttributeListComponent } from './item-attributes/item-attribute-list/item-attribute-list.component';
import { ItemAttributeSingleComponent } from './item-attributes/item-attribute-single/item-attribute-single.component';
import { ItemAttributeValueListComponent } from './item-attribute-values/item-attribute-value-list/item-attribute-value-list.component';
import { ItemAttributeValueSingleComponent } from './item-attribute-values/item-attribute-value-single/item-attribute-value-single.component';
import { ItemCurrencyListComponent } from './item-currencies/item-currency-list/item-currency-list.component';
import { ItemCurrencySingleComponent } from './item-currencies/item-currency-single/item-currency-single.component';
import { ItemListComponent } from './items/item-list/item-list.component';
import { ItemSingleComponent } from './items/item-single/item-single.component';
import { LandingComponent } from './landing/landing.component';
import { LocationListComponent } from './locations/location-list/location-list.component';
import { LocationSingleComponent } from './locations/location-single/location-single.component';
import { PriceScheduleListComponent } from './price-schedules/price-schedule-list/price-schedule-list.component';
import { PriceScheduleSingleComponent } from './price-schedules/price-schedule-single/price-schedule-single.component';
import { PriceBookListComponent } from './price-book/price-book-list/price-book-list.component';
import { PriceBookSingleComponent } from './price-book/price-book-single/price-book-single.component';
import { PriceCalculationListComponent } from './price-calculations/price-calculation-list/price-calculation-list.component';
import { PriceCalculationSingleComponent } from './price-calculations/price-calculation-single/price-calculation-single.component';
import { PriceSheetListComponent } from './price-sheets/price-sheet-list/price-sheet-list.component';
import { PriceSheetSingleComponent } from './price-sheets/price-sheet-single/price-sheet-single.component';
import { PricingConditionListComponent } from './pricing-conditions/pricing-condition-list/pricing-condition-list.component';
import { PricingConditionSingleComponent } from './pricing-conditions/pricing-condition-single/pricing-condition-single.component';
import { PricingDiagnosticListComponent } from './pricing-diagnostics/pricing-diagnostic-list/pricing-diagnostic-list.component';
import { PricingDiagnosticSingleComponent } from './pricing-diagnostics/pricing-diagnostic-single/pricing-diagnostic-single.component';
import { PricingScenarioSingleComponent } from './pricing-scenarios/pricing-scenario-single/pricing-scenario-single.component';
import { PricingScenarioListComponent } from './pricing-scenarios/pricing-scenario-list/pricing-scenario-list.component';
import { PricingScenarioDocumentValueSingleComponent } from './pricing-scenario-document-values/pricing-scenario-document-value-single/pricing-scenario-document-value-single.component';
import { PricingScenarioDocumentValueListComponent } from './pricing-scenario-document-values/pricing-scenario-document-value-list/pricing-scenario-document-value-list.component';
import { PricingFilterListComponent } from './pricing-filters/pricing-filter-list/pricing-filter-list.component';
import { PricingFilterSingleComponent } from './pricing-filters/pricing-filter-single/pricing-filter-single.component';
import { PricingFilterValueListComponent } from './pricing-filter-values/pricing-filter-value-list/pricing-filter-value-list.component';
import { PricingFilterValueSingleComponent } from './pricing-filter-values/pricing-filter-value-single/pricing-filter-value-single.component';
import { QuantityBreakListComponent } from './quantity-breaks/quantity-break-list/quantity-break-list.component';
import { QuantityBreakSingleComponent } from './quantity-breaks/quantity-break-single/quantity-break-single.component';
import { RelatedRecordListComponent } from './related-records/related-record-list/related-record-list.component';
import { RoundingRuleListComponent } from './rounding-rules/rounding-rule-list/rounding-rule-list.component';
import { RoundingRuleSingleComponent } from './rounding-rules/rounding-rule-single/rounding-rule-single.component';
import { SettingSingleComponent } from './settings/setting-single/setting-single.component';
import { UofMListComponent } from './uofms/uofm-list/uofm-list.component';
import { UofMSingleComponent } from './uofms/uofm-single/uofm-single.component';
import { UofMScheduleListComponent } from './uofm-schedules/uofm-schedule-list/uofm-schedule-list.component';
import { UofMScheduleSingleComponent } from './uofm-schedules/uofm-schedule-single/uofm-schedule-single.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserSingleComponent } from './users/user-single/user-single.component';
import { DirtyCheckGuard } from '../guards/dirty-check.guard';

export const items = {
  parents: [
    {
      text: 'FAVORITES',
      icon: 'k-i-information',
      expanded: false,
      selected: false
    },
    {
      text: 'PRICING',
      icon: 'k-i-zoom',
      expanded: false,
      selected: false
    },
    {
      text: 'FILTERS',
      icon: 'k-i-star',
      expanded: false,
      selected: false
    },
    {
      text: 'ERP DATA',
      icon: 'k-i-star',
      expanded: false,
      selected: false
    },
    {
      text: 'TOOLS',
      icon: 'k-i-star',
      expanded: false,
      selected: false
    }

  ],

  FAVORITES: [
    {
      path: 'pricing-scenarios',
      component: PricingScenarioListComponent,
      text: 'Pricing Test Scenarios',
      selected: false,
      level: 1
    },
  ],

  pricing: [
    {
      path: 'price-schedules',
      component: PriceScheduleListComponent,
      text: 'Price Schedules',
      selected: false,
      icon: 'k-i-palette',
      level: 1
    },
    {
      text: 'Supported Themes',
      
      level: 1
    }
  ]
};

const routes = [
  { path: 'attribute-maps', component: AttributeMapListComponent },
  { path: 'attribute-map/:id', component: AttributeMapSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'catalogs', component: CatalogListComponent },
  { path: 'catalog/:id', component: CatalogSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'catalog-sheets', component: CatalogSheetListComponent },
  { path: 'catalog-sheet/:id', component: CatalogSheetSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'currencies', component: CurrencyListComponent, pathMatch: 'full', text: 'Currencies', icon: '../../../assets/images/Currency.png' },
  { path: 'currency/:id', component: CurrencySingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'customers', component: CustomerListComponent },
  { path: 'customer/:id', component: CustomerSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'customer-attributes', component: CustomerAttributeListComponent },
  { path: 'customer-attribute/:id', component: CustomerAttributeSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'customer-attribute-values', component: CustomerAttributeValueListComponent },
  { path: 'customer-attribute-value/:id', component: CustomerAttributeValueSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'document-attributes', component: DocumentAttributeListComponent },
  { path: 'document-attribute/:id', component: DocumentAttributeSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'get-price', component: GetPriceComponent },
  { path: 'gl-accounts', component: GLAccountListComponent },
  { path: 'gl-account/:id', component: GLAccountSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'historical-sales', component: HistoricalSaleListComponent },
  { path: 'historical-sale/:id', component: HistoricalSaleSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'item-attributes', component: ItemAttributeListComponent },
  { path: 'item-attribute/:id', component: ItemAttributeSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'item-attribute-values', component: ItemAttributeValueListComponent },
  { path: 'item-attribute-value/:id', component: ItemAttributeValueSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'item-currencies', component: ItemCurrencyListComponent },
  { path: 'item-currency/:id', component: ItemCurrencySingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'items', component: ItemListComponent },
  { path: 'item/:id', component: ItemSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: '', component: LandingComponent },
  { path: 'locations', component: LocationListComponent },
  { path: 'location/:id', component: LocationSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'price-books', component: PriceBookListComponent },
  { path: 'price-book/:id', component: PriceBookSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'price-calculations', component: PriceCalculationListComponent },
  { path: 'price-calculation/:id', component: PriceCalculationSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'price-schedules', component: PriceScheduleListComponent },
  { path: 'price-schedule/:id', component: PriceScheduleSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'price-sheets', component: PriceSheetListComponent },
  { path: 'price-sheet/:id', component: PriceSheetSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'pricing-conditions', component: PricingConditionListComponent },
  { path: 'pricing-condition/:id', component: PricingConditionSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'pricing-diagnostics', component: PricingDiagnosticListComponent },
  { path: 'pricing-diagnostic/:id', component: PricingDiagnosticSingleComponent },
  { path: 'pricing-filters', component: PricingFilterListComponent },
  { path: 'pricing-filter/:id', component: PricingFilterSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'pricing-filter-values', component: PricingFilterValueListComponent },
  { path: 'pricing-filter-value/:id', component: PricingFilterValueSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'pricing-scenarios', component: PricingScenarioListComponent },
  { path: 'pricing-scenario/:id', component: PricingScenarioSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'pricing-scenario-document-values', component: PricingScenarioDocumentValueListComponent },
  { path: 'pricing-scenario-document-value/:id', component: PricingScenarioDocumentValueSingleComponent },
  { path: 'quantity-breaks', component: QuantityBreakListComponent },
  { path: 'quantity-break/:id', component: QuantityBreakSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'related-records', component: RelatedRecordListComponent }, 
  { path: 'rounding-rules', component: RoundingRuleListComponent },
  { path: 'rounding-rule/:id', component: RoundingRuleSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'setting', component: SettingSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'uofms', component: UofMListComponent },
  { path: 'uofm/:id', component: UofMSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'uofm-schedules', component: UofMScheduleListComponent },
  { path: 'uofm-schedule/:id', component: UofMScheduleSingleComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'user/:id', component: UserSingleComponent, canDeactivate: [DirtyCheckGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PriceRoutingModule { }
