export interface UofMSchedule {
    id: number;
    erpid: string;
    name: string;
    baseUofMID: number;
    baseUofM: {
        name: string,
        id: number,
        erpid: string
    };
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyUofMSchedule: UofMSchedule = {
    id: null,
    erpid: null,
    name: '',
    baseUofMID: null,
    baseUofM: {
        name: null,
        id: null,
        erpid: ''
    },
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
