<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="600" [height]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Attribute_Map.png" alt="Attribute Maps" style="padding-right: 5px;">
      Create / Update Attribute Map Value
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="attributeMapValueForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="attribute1Value" class="font-weight-bold" text="* Attribute 1 Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attribute1Value" placeholder="Enter Attribute 1 Value">
          </kendo-textbox>
          <kendo-formerror>Attribute 1 Value is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group"  *ngIf="showAttribute2Value">
        <kendo-formfield>
          <kendo-label for="attribute2Value" class="font-weight-bold" text="* Attribute 2 Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attribute2Value" placeholder="Enter Attribute 2 Value">
          </kendo-textbox>
          <kendo-formerror>Attribute 2 Value is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="showAttribute3Value">
        <kendo-formfield>
          <kendo-label for="attribute3Value" class="font-weight-bold" text="* Attribute 3 Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attribute3Value" placeholder="Enter Attribute 3 Value">
          </kendo-textbox>
          <kendo-formerror>Attribute 3 Value is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="amount" class="font-weight-bold" text="* Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="amount" [autoCorrect]="true" [min]="-99999999999999"  [max]="99999999999999"
                                [decimals]="5" [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                                placeholder="Enter an Amount">
          </kendo-numerictextbox>
          <kendo-formerror>Amount is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
