export interface CatalogSheet {
  id: number;
  name: string;
  description: string;
  catalogID: number;
  catalog: {
    name: string,
    id: number
  };
  priceScheduleID: number;
  priceSchedule: {
    name: string,
    id: number
  };
  customerID: number;
  customer: {
    name: string,
    id: number
  };
  itemID: number;
  item: {
    name: string,
    id: number
  };
  useBaseUofM: boolean;
  locationID: number;
  location: {
    name: string,
    id: number
  };
  currencyID: number;
  currency: {
    name: string,
    id: number,
    erpid: string
  };
  effectiveDate: Date;
  useToday: boolean;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyCatalogSheet: CatalogSheet = {
  id: null,
  name: '',
  description: null,
  catalogID: null,
  catalog: {
    name: '',
    id: null
  },
  priceScheduleID: null,
  priceSchedule: {
    name: '',
    id: null
  },
  customerID: null,
  customer: {
    name: '',
    id: null
  },
  itemID: null,
  item: {
    name: '',
    id: null
  },
  useBaseUofM: false,
  locationID: null,
  location: {
    name: '',
    id: null
  },
  currencyID: null,
  currency: {
    name: '',
    id: null,
    erpid: null
  },
  effectiveDate: null,
  useToday: false,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
