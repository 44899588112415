<kendo-dialog title="Export Confirmation"
              (close)="close('cancel')"
              [minWidth]="250"
              [width]="500">
  <form>
    <div style="margin-left: 20px; margin-right: 20px;">
      <div style="display: flex; align-items: center;">
        <input type="radio" id="reportStyle" name="exportOption" value="reportStyle" [(ngModel)]="selectedExportOption" (change)="onExportOptionChange()" style="margin-right: 10px;">
        <label for="reportStyle" style="margin-top: 9px;"><strong>Report Style:</strong>  Export the grid view as displayed, including visible columns and applied filters.</label>
      </div>

      <div style="display: flex; align-items: center;">
        <input type="radio" id="technicalStyle" name="exportOption" value="technicalStyle" [(ngModel)]="selectedExportOption" (change)="onExportOptionChange()" style="margin-right: 10px;">
        <label for="technicalStyle" style="margin-top: 9px;"><strong>Technical Style:</strong>  Export information for updating, transferring, and/or re-importing.</label>
      </div>

      <div style="margin-left: 40px;">
        <div *ngIf="hasDescColumns" style="display: flex; align-items: center;">
          <input type="checkbox" id="optDescColumns" name="optDescColumns" [(ngModel)]="optDescColumns" [disabled]="selectedExportOption !== 'technicalStyle'" style="margin-right: 10px;">
          <label for="optDescColumns" style="margin-top: 9px;"><strong>Include descriptive columns</strong>, like Item Name, Customer Name, and Currency Name. These will be ignored if re-imported, but are helpful for reporting. These fields will be designated with an asterisk*.</label>
        </div>

        <div style="display: flex; align-items: center;">
          <input type="checkbox" id="optRowID" name="optRowID" [(ngModel)]="optRowID" [disabled]="selectedExportOption !== 'technicalStyle'" style="margin-right: 10px;">
          <label for="optRowID" style="margin-top: 9px;"><strong>Include the ID</strong> of each row, for updating these records in this system. This is effective for updating the primary name of records in this list, or overwriting data. Uncheck this option if importing these records into another system.</label>
        </div>

        <div *ngIf="siblingRecordCount !== 0" style="display: flex; align-items: center;">
          <input type="checkbox" id="optSiblingRecords" name="optSiblingRecords" [(ngModel)]="optSiblingRecords" [disabled]="selectedExportOption !== 'technicalStyle'" style="margin-right: 10px;">
          <label for="optSiblingRecords" style="margin-top: 9px;"><strong>Include all records</strong> of this type, even those not in this specific grid view. (Maximum records exported is 200,000)</label>
        </div>
      </div>
      <ul>
        <li *ngIf="siblingRecordCount === 0" style="color: blue; text-align: center;">
          Records in grid: {{ gridRecordCount | number }}
        </li>
        <li *ngIf="siblingRecordCount !== 0" style="color: blue; text-align: center;">
          Records in grid: {{ gridRecordCount | number }}   Records globally: {{ siblingRecordCount | number }}
        </li>
        <li *ngIf="!optSiblingRecords && gridRecordCount > 200000" style="color: red; font-weight: bold;">
          Warning: Only 200,000 of {{ gridRecordCount | number }} records will export. You may want to divide the records into smaller sets.
        </li>
        <li *ngIf="optSiblingRecords && siblingRecordCount > 200000" style="color: red; font-weight: bold;">
          Warning: Only 200,000 of {{ siblingRecordCount | number }} records will export. You may want to divide the records into smaller sets.
        </li>
      </ul>
    </div>
  </form>
  <kendo-dialog-actions style="justify-content: center;">
    <button kendoButton (click)="close('cancel')" class="btn btn-secondary">
      <span class="k-icon k-i-cancel"></span>Cancel
    </button>
    <button kendoButton (click)="close('export')" primary="true" class="btn btn-primary">
      <span class="k-icon k-i-check-outline"></span>Export
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
