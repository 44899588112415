import { Component, OnInit, Output, EventEmitter, HostListener, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilter, emptyPricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { PricingFilterValue } from 'src/app/services/pricing-filter-values/pricing-filter-values.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { StorageService } from 'src/app/utils/StorageHelper';
import { CustomersInFilterListComponent } from '../../customers-in-filter/customers-in-filter-list/customers-in-filter-list.component';
import { ItemsInFilterListComponent } from '../../items-in-filter/items-in-filter-list/items-in-filter-list.component';
import { CopyService } from '../../../services/copy/copy.service';

enum Filters {
  AttributeDataType = 1,
}
@Component({
  selector: 'app-pricing-filter-single',
  templateUrl: './pricing-filter-single.component.html',
  styleUrls: ['./pricing-filter-single.component.scss'],
})
export class PricingFilterSingleComponent implements OnInit {
  filters = Filters;
  idOfPricingFilter: number | null = null;
  pricingFilter: PricingFilter = emptyPricingFilter;
  pricingFilterValues: PricingFilterValue[] = [];
  loading: boolean = true;
  pricingFilterForm: FormGroup;
  attributeDataTypes: Lookup[] = [];
  submitted = false; 
  modalReference: any;
  paginationObject: any = { pricingFilterValues: mockedData };
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';

  constructor(
    private route: ActivatedRoute,
    private pricingFiltersService: PricingFiltersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private router: Router,
    private copyService: CopyService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') { 
        this.pricingFilter = emptyPricingFilter;
        this.idOfPricingFilter = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return; }
      this.idOfPricingFilter = params.id;
      this.loadData();
    });
    this.buildForm();
  }

  ngOnInit(): void {
  }
  async buildForm() {
    this.pricingFilterForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('')
    });
    this.pricingFilterForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  get f() {
    return this.pricingFilterForm.controls;
  }
  updateForm() {
    this.pricingFilterForm.patchValue({ ...this.pricingFilter });
    this.isFormChanged = false;
  }
  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.pricingFilterForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingPricingFilters: PricingFilter[] = await (
      await this.pricingFiltersService.getList(params)
    ).body;
    if (existingPricingFilters.length > 0) {
      if (this.pricingFilter.id) {
        for (const key in existingPricingFilters) {
          const currentPricingFilter: PricingFilter = existingPricingFilters[key];
          if (currentPricingFilter.id != this.pricingFilter.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  public showCustomersInFilter() {
    if (this.idOfPricingFilter) {
      const modalRef = this.modalService.open(CustomersInFilterListComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.idOfPricingFilter = this.idOfPricingFilter;
    }    
  }

  public showItemsInFilter() {
    if (this.idOfPricingFilter) {
      const modalRef = this.modalService.open(ItemsInFilterListComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.idOfPricingFilter = this.idOfPricingFilter;
    }    
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPricingFilter", this.pricingFilter.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.pricingFilter.id);
    await this.pricingFiltersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/pricing-filters']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.pricingFilter && this.pricingFilter.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/pricing-filter/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/pricing-filter/new']);
      });
    }
  }

  onClick_Save() {
    this.submit(null);
  }

  onClick_SaveClose() {
    this.submit("Close");
  }

  onClick_SaveNew() {
    this.submit("New");
  }
  
  async submit(action) {
    this.pricingFilterForm.markAllAsTouched();
    this.submitted = true;
    if (this.pricingFilterForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = { ...this.pricingFilter, ...this.pricingFilterForm.value };
    if (await this.validation_CheckForExistingName() === true) {
      this.snack.openSnackBar(
        'Pricing Filter Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.pricingFilter.id) {        
        try {
          const response: any = await this.pricingFiltersService.update(
            this.pricingFilter.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/pricing-filters']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/pricing-filter/new']);
            });
          }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.pricingFiltersService.create(
            this.pricingFilterForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.pricingFilter = response.body;
            this.idOfPricingFilter = this.pricingFilter.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/pricing-filters']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/pricing-filter/new']);
              });
            }
            else {
              this.pricingFilter = response.body;
              this.router.navigate(['/price/pricing-filter/', this.pricingFilter.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getPricingFilterById() {
    if (this.idOfPricingFilter) {
      return await this.pricingFiltersService.getById(this.idOfPricingFilter);
    } else {
      return emptyPricingFilter;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [this.pricingFilter] = await Promise.all([
        this.getPricingFilterById()
      ]);
      this.prepareData();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  prepareData() {
    this.updateForm();
  }
  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
